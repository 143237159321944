<template>
  <v-card
    v-if="
      (relatorio.Clinico &&
        ((relatorio.ListaAnimaisTratados != null &&
          relatorio.ListaAnimaisTratados?.length != 0) ||
          (relatorio.Tratamento != null && relatorio.Tratamento?.length != 0) ||
          relatorio.OutrasRecomendacoesAtendimentoClinico)) ||
      relatorio.ClinicoObs
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left">Atendimento clínico</v-card-title>
    <v-card-text>
      <v-row v-if="relatorio.Clinico" class="pa-3">
        <v-col
          v-if="relatorio.ListaAnimaisTratados?.length != 0"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Animais tratados </span> <br />
          <v-chip
            v-for="(animal, index) in relatorio.ListaAnimaisTratados"
            :key="index"
            class="ma-1"
          >
            <span v-if="relatorio.ListaAnimaisTratados">{{
              animal.acAnimaisTratadosNomeOuBrinco
            }}</span>
            <span v-else> {{ animal }} </span>
          </v-chip>
        </v-col>
        <v-col v-if="relatorio.Tratamento" cols="12" class="col-bordered">
          <span class="key-title">Tratamento </span> <br />
          <v-chip
            v-for="(medicamento, index) in relatorio.Tratamento"
            :key="index"
            class="ma-1"
          >
            <span v-if="relatorio.Tratamento?.length != 0"
              ><strong>Medicação: </strong
              >{{ medicamento.acTratamentoMedicacao + ' ' }}<br /><strong
                >Dosagem: </strong
              >{{ medicamento.acTratamentoDosagem + ' ' }}<br /><strong
                >Via de aplicação: </strong
              >{{ medicamento.acTratamentoViaAplicacao + ' ' }}<br /><strong
                >Duração do tratamento: </strong
              >{{ medicamento.acTratamentoDuracao + ' ' }}</span
            >
            <span v-else> {{ medicamento }} </span>
          </v-chip>
        </v-col>
        <v-col
          v-if="relatorio.OutrasRecomendacoesAtendimentoClinico"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Outras recomendações: </span> <br />
          <span class="value-title">{{
            relatorio.OutrasRecomendacoesAtendimentoClinico || '-'
          }}</span>
        </v-col>
      </v-row>
      <v-row v-else justify="center">
        <v-col>
          <span class="key-title">Atividade não realizada </span> <br />
          <span class="value-title">{{ relatorio.ClinicoObs || '-' }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ClinicoEvolui',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    maskIdentify(raw, noneText) {
      if (raw) {
        raw = raw.split('|')
        for (const index in raw) {
          raw[index] = raw[index].split('/')
        }
      } else {
        raw = [noneText]
      }
      return raw
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
