<template>
  <v-card
    v-if="
      (relatorio.Sanitario &&
        ((relatorio.Vacinas != null && relatorio.Vacinas.length != 0) ||
          (relatorio.Pediluvio != null && relatorio.Pediluvio.length != 0) ||
          (relatorio.Vermifucacao != null &&
            relatorio.Vermifucacao.length != 0) ||
          (relatorio.ControleCarrapatos != null &&
            relatorio.ControleCarrapatos.length != 0) ||
          relatorio.OutrasRecomendacoesManejoSanitario != '' ||
          relatorio.ManejoSanitarioAtividade1 ||
          relatorio.ManejoSanitarioAtividade2 ||
          relatorio.ManejoSanitarioAtividade3 ||
          relatorio.ManejoSanitarioAtividade4 ||
          relatorio.ManejoSanitarioOutrasAtividades ||
          relatorio.ManejoSanitarioOutrasAtividadesText)) ||
      relatorio.SanitarioObs
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left">Manejo sanitário</v-card-title>
    <v-card-text>
      <v-row v-if="relatorio.Sanitario" class="pa-3">
        <v-col
          v-if="relatorio.Vacinas != null && relatorio.Vacinas.length != 0"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Vacinas </span> <br />
          <v-chip
            v-for="(vacina, index) in relatorio.Vacinas"
            :key="index"
            class="ma-1"
          >
            <span v-if="relatorio.Vacinas"
              ><strong>Nome: </strong>{{ vacina.msMomeVacina + ' '
              }}<br /><strong>Dosagem: </strong>{{ vacina.msDosagemVacina + ' '
              }}<br /><strong>Categoria: </strong
              >{{ vacina.msCategoriaVacina + ' ' }}</span
            >
            <span v-else> {{ vacina }} </span>
          </v-chip>
        </v-col>
        <v-col
          v-if="relatorio.Pediluvio != null && relatorio.Pediluvio.length != 0"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Manter utilização do pedilúvio </span> <br />
          <v-chip
            v-for="(pediluvio, index) in relatorio.Pediluvio"
            :key="index"
            class="ma-1"
          >
            <span v-if="relatorio.Pediluvio"
              ><strong>Nome do produto: </strong
              >{{ pediluvio.msNomeProdutoPediluvio + ' ' }}<br /><strong
                >Frequência: </strong
              >{{ pediluvio.msFrequenciaPediluvio + ' ' }}<br /><strong
                >Concentração: </strong
              >{{ pediluvio.msConcentracaoPediluvio + ' ' }}</span
            >
            <span v-else> {{ pediluvio }} </span>
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.Vermifucacao != null && relatorio.Vermifucacao.length != 0
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Vermifugação </span> <br />
          <v-chip
            v-for="(vermifugo, index) in relatorio.Vermifucacao"
            :key="index"
            class="ma-1"
          >
            <span v-if="relatorio.Vermifucacao"
              ><strong>Nome do produto: </strong
              >{{ vermifugo.msNomeProdutoVermifugacao + ' ' }}<br /><strong
                >Dosagem: </strong
              >{{ vermifugo.msDosagemVermifugacao + ' ' }}<br /><strong
                >Via de aplicação: </strong
              >{{ vermifugo.msViaAplicacaoVermifugacao + ' ' }}</span
            >
            <span v-else> {{ vermifugo }} </span>
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ControleCarrapatos != null &&
            relatorio.ControleCarrapatos.length != 0
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Carrapaticidas </span> <br />
          <v-chip
            v-for="(carrapaticida, index) in relatorio.ControleCarrapatos"
            :key="index"
            class="ma-1"
          >
            <span v-if="relatorio.ControleCarrapatos"
              ><strong>Nome do produto: </strong
              >{{ carrapaticida.msNomeProdutoCarrapaticida + ' ' }}<br /><strong
                >Dosagem: </strong
              >{{ carrapaticida.msDosagemCarrapaticida + ' ' }}<br /><strong
                >Via de aplicação: </strong
              >{{ carrapaticida.msViaAplicacaoCarrapaticida + ' ' }}</span
            >
            <span v-else> {{ carrapaticida }} </span>
          </v-chip>
        </v-col>

        <v-col
          v-if="
            relatorio.ManejoSanitarioAtividade1 ||
            relatorio.ManejoSanitarioAtividade2 ||
            relatorio.ManejoSanitarioAtividade3 ||
            relatorio.ManejoSanitarioAtividade4 ||
            relatorio.ManejoSanitarioOutrasAtividades
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Atividades realizadas</span>
          <v-col
            v-if="
              relatorio.ManejoSanitarioAtividade1 == '1' &&
              relatorio.ManejoSanitarioAtividade1Text != ''
            "
            cols="12"
          >
            <span class="value-title"
              >Implantação do calendário sanitário:
            </span>
            <br />
            <span class="value-title">{{
              relatorio.ManejoSanitarioAtividade1Text || '-'
            }}</span>
          </v-col>
          <v-col
            v-if="
              relatorio.ManejoSanitarioAtividade2 == '1' &&
              relatorio.ManejoSanitarioAtividade2Text != ''
            "
            cols="12"
          >
            <span class="value-title"
              >Acompanhamento do calendário sanitário:
            </span>
            <br />
            <span class="value-title">{{
              relatorio.ManejoSanitarioAtividade2Text || '-'
            }}</span>
          </v-col>
          <v-col
            v-if="
              relatorio.ManejoSanitarioAtividade3 == '1' &&
              relatorio.ManejoSanitarioAtividade3Text != ''
            "
            cols="12"
          >
            <span class="value-title">Realização da vacinação:</span>
            <br />
            <span class="value-title">{{
              relatorio.ManejoSanitarioAtividade3Text || '-'
            }}</span>
          </v-col>
          <v-col
            v-if="
              relatorio.ManejoSanitarioAtividade4 == '1' &&
              relatorio.ManejoSanitarioAtividade4Text != ''
            "
            cols="12"
          >
            <span class="value-title"
              >Planos de ação para controle de doenças:
            </span>
            <br />
            <span class="value-title">{{
              relatorio.ManejoSanitarioAtividade4Text || '-'
            }}</span>
          </v-col>
          <v-col v-if="relatorio.ManejoSanitarioOutrasAtividades" cols="12">
            <span class="key-title">Outras Atividades: </span> <br />
            <span class="value-title">{{
              relatorio.ManejoSanitarioOutrasAtividadesText || '-'
            }}</span>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.OutrasRecomendacoesManejoSanitario != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Outras recomendações: </span> <br />
          <span class="value-title">{{
            relatorio.OutrasRecomendacoesManejoSanitario || '-'
          }}</span>
        </v-col>
      </v-row>
      <v-row v-else justify="center">
        <v-col>
          <span class="key-title">Atividade não realizada </span> <br />
          <span class="value-title">{{ relatorio.SanitarioObs || '-' }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'SanitarioEvolui',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {},
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
  line-height: 40px;
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
