<template>
  <v-card
    v-if="
      relatorio.CotacaoInsumoVolumoso ||
      relatorio.ColetaDoSolo ||
      relatorio.AmostrasAnalise ||
      relatorio.LaudoAnalise ||
      relatorio.RecomendacaoVolumoso ||
      relatorio.PlanejamentoVolumosoObs ||
      relatorio.VolumosoAtividade1 ||
      relatorio.VolumosoAtividade2 ||
      relatorio.VolumosoAtividade3 ||
      relatorio.VolumosoAtividade4 ||
      relatorio.VolumosoAtividade5 ||
      relatorio.VolumosoOutrasAtividades
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left"
      >Planejamento de volumoso</v-card-title
    >
    <v-card-text>
      <v-row v-if="relatorio.PlanejamentoVolumoso" class="pa-3">
        <v-col
          v-if="
            relatorio.VolumosoAtividade1 ||
            relatorio.VolumosoAtividade2 ||
            relatorio.VolumosoAtividade3 ||
            relatorio.VolumosoAtividade4 ||
            relatorio.VolumosoAtividade5 ||
            relatorio.VolumosoOutrasAtividades
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Atividades realizadas</span>
          <v-col
            v-if="
              relatorio.VolumosoAtividade1 == '1' &&
              relatorio.VolumosoAtividade1Text != ''
            "
            cols="12"
          >
            <span class="value-title">Evolução do rebanho: </span>
            <br />
            <span class="value-title">{{
              relatorio.VolumosoAtividade1Text || '-'
            }}</span>
          </v-col>

          <v-col
            v-if="
              relatorio.VolumosoAtividade2 == '1' &&
              relatorio.VolumosoAtividade2Text != ''
            "
            cols="12"
          >
            <span class="value-title"
              >Levantamento do estoque atual de volumoso:
            </span>
            <br />
            <span class="value-title">{{
              relatorio.VolumosoAtividade2Text || '-'
            }}</span>
          </v-col>

          <v-col
            v-if="
              relatorio.VolumosoAtividade3 == '1' &&
              relatorio.VolumosoAtividade3Text != ''
            "
            cols="12"
          >
            <span class="value-title"
              >Levantamento do tamanho das áreas para produção de volumoso:
            </span>
            <br />
            <span class="value-title">{{
              relatorio.VolumosoAtividade3Text || '-'
            }}</span>
          </v-col>

          <v-col
            v-if="
              relatorio.VolumosoAtividade4 == '1' &&
              relatorio.VolumosoAtividade4Text != ''
            "
            cols="12"
          >
            <span class="value-title"
              >Coleta de solo das áreas de plantio para análise:
            </span>
            <br />
            <span class="value-title">{{
              relatorio.VolumosoAtividade4Text || '-'
            }}</span>
          </v-col>

          <v-col
            v-if="
              relatorio.VolumosoAtividade5 == '1' &&
              relatorio.VolumosoAtividade5Text != ''
            "
            cols="12"
          >
            <span class="value-title">Plano alimentar: </span>
            <br />
            <span class="value-title">{{
              relatorio.VolumosoAtividade5Text || '-'
            }}</span>
          </v-col>
          <v-col v-if="relatorio.VolumosoOutrasAtividades" cols="12">
            <span class="key-title">Outras Atividades: </span> <br />
            <span class="value-title">{{
              relatorio.VolumosoOutrasAtividadesText || '-'
            }}</span>
          </v-col>
        </v-col>

        <v-col
          v-if="
            relatorio.CotacaoInsumoVolumoso ||
            relatorio.PesagemLeiteApp ||
            relatorio.PesagemLeiteCaderno ||
            relatorio.RemanejamentoLotes ||
            relatorio.SeguirArracoamento ||
            relatorio.RecomendacaoVolumoso
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Recomendações</span>
          <v-col v-if="relatorio.ColetaDoSolo" cols="12">
            <span class="value-title"
              >Realizar coleta de solo das áreas de plantio para análise
            </span>
          </v-col>
          <v-col v-if="relatorio.AmostrasAnalise" cols="12">
            <span class="value-title">Enviar amostras para análise </span>
          </v-col>
          <v-col v-if="relatorio.LaudoAnalise" cols="12">
            <span class="value-title"
              >Enviar o laudo da análise assim que estiver pronto
            </span>
          </v-col>
          <v-col v-if="relatorio.CotacaoInsumoVolumoso" cols="12">
            <span class="value-title"
              >Fazer cotação de insumos para plantio
            </span>
          </v-col>
          <v-col v-if="relatorio.RecomendacaoVolumoso" cols="12">
            <span class="key-title">Outras recomendações: </span> <br />
            <span class="value-title">{{
              relatorio.RecomendacaoVolumoso || '-'
            }}</span>
          </v-col>
        </v-col>
      </v-row>
      <v-row v-else justify="center">
        <v-col>
          <span class="key-title">Atividade não realizada </span> <br />
          <span class="value-title">{{
            relatorio.PlanejamentoVolumosoObs || '-'
          }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'PlanejamentoVolumoso',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    maskIdentify(raw) {
      return raw ? raw.split('|') : ['Nenhum insumo identificado']
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
  line-height: 40px;
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
