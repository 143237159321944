<template>
  <v-card
    v-if="
      relatorio.CriaRecria &&
      ((relatorio.NovilhasComPeso != null &&
        relatorio.NovilhasComPeso.length) ||
        (relatorio.BezerrasDesmamar != null &&
          relatorio.BezerrasDesmamar.length) ||
        (relatorio.PesagemRecria != null && relatorio.PesagemRecria != '') ||
        (relatorio.OutrasRecomendacoesCriaRecria &&
          relatorio.OutrasRecomendacoesCriaRecria != '') ||
        relatorio.CriaRecriaObs)
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left"
      >Acompanhamento da cria/recria</v-card-title
    >
    <v-card-text>
      <v-row
        v-if="relatorio.CriaRecria != null && relatorio.CriaRecria == '1'"
        class="pa-3"
      >
        <v-col
          v-if="
            relatorio.NovilhasComPeso != null &&
            relatorio.NovilhasComPeso.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Novilhas aptas à inseminação </span> <br />
          <v-chip
            v-for="(animal, index) in relatorio.NovilhasComPeso"
            :key="index"
            class="ma-1"
          >
            {{ animal.acrNomeOuBrinco }}
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.BezerrasDesmamar != null &&
            relatorio.BezerrasDesmamar.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Bezerras a desmamar </span> <br />
          <v-chip
            v-for="(animal, index) in relatorio.BezerrasDesmamar"
            :key="index"
            class="ma-1"
          >
            {{ animal.acrBezerrasDesmamarNomeOuBrinco }}
          </v-chip>
        </v-col>

        <v-col
          v-if="
            relatorio.CriaRecriaAtividade1 ||
            relatorio.CriaRecriaAtividade2 ||
            relatorio.CriaRecriaAtividade3 ||
            relatorio.CriaRecriaAtividade4 ||
            relatorio.CriaRecriaAtividade5 ||
            relatorio.CriaRecriaOutrasAtividades
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Atividades realizadas</span>

          <v-col
            v-if="
              relatorio.CriaRecriaAtividade1 == '1' &&
              relatorio.CriaRecriaAtividade1Text != ''
            "
            cols="12"
          >
            <span class="value-title"
              >Acompanhamento dos procedimentos de cria das bezerras (rotina do
              bezerreiro):</span
            >
            <br />
            <span class="value-title">{{
              relatorio.CriaRecriaAtividade1Text || '-'
            }}</span>
          </v-col>

          <v-col
            v-if="
              relatorio.CriaRecriaAtividade2 == '1' &&
              relatorio.CriaRecriaAtividade2Text != ''
            "
            cols="12"
          >
            <span class="value-title">Vistoria dos piquetes de recria:</span>
            <br />
            <span class="value-title">{{
              relatorio.CriaRecriaAtividade2Text || '-'
            }}</span>
          </v-col>

          <v-col
            v-if="
              relatorio.CriaRecriaAtividade3 == '1' &&
              relatorio.CriaRecriaAtividade3Text != ''
            "
            cols="12"
          >
            <span class="value-title">Pesagem dos animais:</span>
            <br />
            <span class="value-title">{{
              relatorio.CriaRecriaAtividade3Text || '-'
            }}</span>
          </v-col>

          <v-col
            v-if="
              relatorio.CriaRecriaAtividade4 == '1' &&
              relatorio.CriaRecriaAtividade4Text != ''
            "
            cols="12"
          >
            <span class="value-title"
              >Discussão dos indicadores de cria e recria de bezerras:</span
            >
            <br />
            <span class="value-title">{{
              relatorio.CriaRecriaAtividade4Text || '-'
            }}</span>
          </v-col>

          <v-col
            v-if="
              relatorio.CriaRecriaAtividade5 == '1' &&
              relatorio.CriaRecriaAtividade5Text != ''
            "
            cols="12"
          >
            <span class="value-title"
              >Planos de ação para controle de doenças específicas de
              bezerras:</span
            >
            <br />
            <span class="value-title">{{
              relatorio.CriaRecriaAtividade5Text || '-'
            }}</span>
          </v-col>
          <v-col v-if="relatorio.CriaRecriaOutrasAtividades" cols="12">
            <span class="key-title">Outras Atividades: </span> <br />
            <span class="value-title">{{
              relatorio.CriaRecriaOutrasAtividadesText || '-'
            }}</span>
          </v-col>
        </v-col>

        <v-col
          v-if="
            relatorio.PesagemRecria != '' ||
            relatorio.OutrasRecomendacoesCriaRecria
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Recomendações</span>
          <v-col
            v-if="relatorio.PesagemRecria && relatorio.PesagemRecria != ''"
            cols="12"
          >
            <span class="value-title"
              >Realizar pesagem mensal dos animais em recria para acompanhar
              ganho de peso
            </span>
          </v-col>
          <v-col v-if="relatorio.OutrasRecomendacoesCriaRecria != ''" cols="12">
            <span class="key-title">Outras recomendações: </span> <br />
            <span class="value-title">{{
              relatorio.OutrasRecomendacoesCriaRecria || '-'
            }}</span>
          </v-col>
        </v-col>
      </v-row>
      <v-row v-else justify="center">
        <v-col>
          <span class="key-title">Atividade não realizada </span> <br />
          <span class="value-title">{{ relatorio.CriaRecriaObs || '-' }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'CriaRecriaEvolui',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    maskIdentify(raw) {
      return raw ? raw.split('|') : ['Nenhum animal identificado']
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
  line-height: 40px;
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
