var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.relatorio.CotacaoInsumoVolumoso ||
    _vm.relatorio.ColetaDoSolo ||
    _vm.relatorio.AmostrasAnalise ||
    _vm.relatorio.LaudoAnalise ||
    _vm.relatorio.RecomendacaoVolumoso ||
    _vm.relatorio.PlanejamentoVolumosoObs ||
    _vm.relatorio.VolumosoAtividade1 ||
    _vm.relatorio.VolumosoAtividade2 ||
    _vm.relatorio.VolumosoAtividade3 ||
    _vm.relatorio.VolumosoAtividade4 ||
    _vm.relatorio.VolumosoAtividade5 ||
    _vm.relatorio.VolumosoOutrasAtividades
  )?_c('v-card',{staticClass:"ma-4"},[_c('v-card-title',{staticStyle:{"text-align":"left"}},[_vm._v("Planejamento de volumoso")]),_c('v-card-text',[(_vm.relatorio.PlanejamentoVolumoso)?_c('v-row',{staticClass:"pa-3"},[(
          _vm.relatorio.VolumosoAtividade1 ||
          _vm.relatorio.VolumosoAtividade2 ||
          _vm.relatorio.VolumosoAtividade3 ||
          _vm.relatorio.VolumosoAtividade4 ||
          _vm.relatorio.VolumosoAtividade5 ||
          _vm.relatorio.VolumosoOutrasAtividades
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Atividades realizadas")]),(
            _vm.relatorio.VolumosoAtividade1 == '1' &&
            _vm.relatorio.VolumosoAtividade1Text != ''
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Evolução do rebanho: ")]),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.VolumosoAtividade1Text || '-'))])]):_vm._e(),(
            _vm.relatorio.VolumosoAtividade2 == '1' &&
            _vm.relatorio.VolumosoAtividade2Text != ''
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Levantamento do estoque atual de volumoso: ")]),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.VolumosoAtividade2Text || '-'))])]):_vm._e(),(
            _vm.relatorio.VolumosoAtividade3 == '1' &&
            _vm.relatorio.VolumosoAtividade3Text != ''
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Levantamento do tamanho das áreas para produção de volumoso: ")]),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.VolumosoAtividade3Text || '-'))])]):_vm._e(),(
            _vm.relatorio.VolumosoAtividade4 == '1' &&
            _vm.relatorio.VolumosoAtividade4Text != ''
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Coleta de solo das áreas de plantio para análise: ")]),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.VolumosoAtividade4Text || '-'))])]):_vm._e(),(
            _vm.relatorio.VolumosoAtividade5 == '1' &&
            _vm.relatorio.VolumosoAtividade5Text != ''
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Plano alimentar: ")]),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.VolumosoAtividade5Text || '-'))])]):_vm._e(),(_vm.relatorio.VolumosoOutrasAtividades)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Outras Atividades: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.VolumosoOutrasAtividadesText || '-'))])]):_vm._e()],1):_vm._e(),(
          _vm.relatorio.CotacaoInsumoVolumoso ||
          _vm.relatorio.PesagemLeiteApp ||
          _vm.relatorio.PesagemLeiteCaderno ||
          _vm.relatorio.RemanejamentoLotes ||
          _vm.relatorio.SeguirArracoamento ||
          _vm.relatorio.RecomendacaoVolumoso
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Recomendações")]),(_vm.relatorio.ColetaDoSolo)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Realizar coleta de solo das áreas de plantio para análise ")])]):_vm._e(),(_vm.relatorio.AmostrasAnalise)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Enviar amostras para análise ")])]):_vm._e(),(_vm.relatorio.LaudoAnalise)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Enviar o laudo da análise assim que estiver pronto ")])]):_vm._e(),(_vm.relatorio.CotacaoInsumoVolumoso)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Fazer cotação de insumos para plantio ")])]):_vm._e(),(_vm.relatorio.RecomendacaoVolumoso)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Outras recomendações: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.RecomendacaoVolumoso || '-'))])]):_vm._e()],1):_vm._e()],1):_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('span',{staticClass:"key-title"},[_vm._v("Atividade não realizada ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.PlanejamentoVolumosoObs || '-'))])])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }