<template>
  <v-card
    v-if="
      (relatorio.ColetaFinanceiro &&
        (relatorio.ManterAttZootecnico ||
          relatorio.ManterAppSync ||
          relatorio.ColocarBrincoRecria ||
          relatorio.ColocarBrincoLactacao ||
          relatorio.ColocarBrincoSecas ||
          relatorio.RecomendacaoZootecnico ||
          relatorio.ColetaFinanceiroAtividade1 ||
          relatorio.ColetaFinanceiroAtividade2 ||
          relatorio.ColetaFinanceiroAtividade3 ||
          relatorio.ColetaFinanceiroAtividade4 ||
          relatorio.ColetaFinanceiroAtividade5 ||
          relatorio.ColetaFinanceiroAtividade6 ||
          relatorio.ColetaFinanceiroOutrasAtividades)) ||
      relatorio.ColetaFinanceiroObs
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left"
      >Coleta e atualização de dados financeiros</v-card-title
    >
    <v-card-text>
      <v-row v-if="relatorio.ColetaFinanceiro" class="pa-3">
        <v-col
          v-if="
            relatorio.ColetaFinanceiroAtividade1 ||
            relatorio.ColetaFinanceiroAtividade2 ||
            relatorio.ColetaFinanceiroAtividade3 ||
            relatorio.ColetaFinanceiroAtividade4 ||
            relatorio.ColetaFinanceiroAtividade5 ||
            relatorio.ColetaFinanceiroAtividade6 ||
            relatorio.ColetaFinanceiroOutrasAtividades
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Atividades realizadas</span>
          <v-col v-if="relatorio.ColetaFinanceiroAtividade1" cols="12">
            <span class="value-title"
              >Lançamento de RD (receitas e despesas) no sistema</span
            >
          </v-col>
          <v-col v-if="relatorio.ColetaFinanceiroAtividade2" cols="12">
            <span class="value-title"
              >Lançamento do IR (inventário de recursos) no sistema
            </span>
          </v-col>
          <v-col v-if="relatorio.ColetaFinanceiroAtividade3" cols="12">
            <span class="value-title"
              >Orientação de como fazer as anotações no caderno financeiro</span
            >
          </v-col>
          <v-col v-if="relatorio.ColetaFinanceiroAtividade4" cols="12">
            <span class="value-title"
              >Orientação de como fazer lançamento no sistema</span
            >
          </v-col>
          <v-col v-if="relatorio.ColetaFinanceiroAtividade5" cols="12">
            <span class="value-title"
              >Orientação de como visualizar os relatórios no sistema</span
            >
          </v-col>
          <v-col v-if="relatorio.ColetaFinanceiroAtividade6" cols="12">
            <span class="value-title"
              >Discussão dos indicadores econômicos</span
            >
          </v-col>
          <v-col v-if="relatorio.ColetaFinanceiroOutrasAtividades" cols="12">
            <span class="key-title">Outras Atividades: </span> <br />
            <span class="value-title">{{
              relatorio.ColetaFinanceiroOutrasAtividadesText || '-'
            }}</span>
          </v-col>
        </v-col>

        <v-col
          v-if="
            relatorio.ManterAttFinanceiro ||
            relatorio.FinanciamentoAVenver ||
            relatorio.ImplementosAVencer ||
            relatorio.EmprestimosAVencer ||
            relatorio.ArmazenarNotasDespesas
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Recomendações</span>
          <v-col v-if="relatorio.ManterAttFinanceiro" cols="12">
            <span class="value-title"
              >Manter atualizações dos lançamentos financeiros em dia
            </span>
          </v-col>
          <v-col v-if="relatorio.FinanciamentoAVenver" cols="12">
            <span class="value-title"
              >Fazer o levantamento dos financiamentos a vencer para serem
              lançados no aplicativo
            </span>
          </v-col>
          <v-col v-if="relatorio.ImplementosAVencer" cols="12">
            <span class="value-title"
              >Fazer o levantamento das parcelas dos implementos a vencer para
              serem lançados no aplicativo
            </span>
          </v-col>
          <v-col v-if="relatorio.EmprestimosAVencer" cols="12">
            <span class="value-title"
              >Fazer o levantamento das parcelas dos empréstimos a vencer para
              serem lançados no aplicativo
            </span>
          </v-col>
          <v-col v-if="relatorio.ArmazenarNotasDespesas" cols="12">
            <span class="value-title"
              >Armazenar todas as notas de despesas até que sejam lançadas no
              aplicativo
            </span>
          </v-col>
          <v-col v-if="relatorio.RecomendacaoFinanceiro" cols="12">
            <span class="key-title">Outras recomendações: </span> <br />
            <span class="value-title">{{
              relatorio.RecomendacaoFinanceiro || '-'
            }}</span>
          </v-col>
        </v-col>
      </v-row>
      <v-row v-else justify="center">
        <v-col>
          <span class="key-title">Atividade não realizada </span> <br />
          <span class="value-title">{{
            relatorio.ColetaFinanceiroObs || '-'
          }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ColetaFinanceiro',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
  line-height: 40px;
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
