<template>
  <v-card
    v-if="
      relatorio.VacasHiperqueratose ||
      relatorio.CasosSobreordenha ||
      relatorio.VacasEscoreLimpeza ||
      relatorio.VacasSujas ||
      relatorio.TempoMedioPreparo ||
      relatorio.TempoMedioOrdenha
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left">Informações de ordenha</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="
            relatorio.VacasHiperqueratose || relatorio.VacasHiperqueratose == ''
          "
          cols="12"
          sm="6"
          lg="6"
          xl="6"
          class="col-bordered"
        >
          <span class="key-title">
            Número de vacas com hiperqueratose de teto:
          </span>
          <span class="value-title">
            {{ relatorio.VacasHiperqueratose || '-' }}
          </span>
        </v-col>
        <v-col
          v-if="
            relatorio.CasosSobreordenha || relatorio.CasosSobreordenha == ''
          "
          cols="12"
          sm="6"
          lg="6"
          xl="6"
          class="col-bordered"
        >
          <span class="key-title">Número de casos de sobreordenha: </span>
          <span class="value-title">
            {{ relatorio.CasosSobreordenha || '-' }}
          </span>
        </v-col>
        <v-col
          v-if="
            relatorio.VacasEscoreLimpeza || relatorio.VacasEscoreLimpeza == ''
          "
          cols="12"
          sm="6"
          lg="6"
          xl="6"
          class="col-bordered"
        >
          <span class="key-title">
            Número de vacas escore de limpeza da ponta do teto 3 e 4:
          </span>
          <span class="value-title">{{
            relatorio.VacasEscoreLimpeza || '-'
          }}</span>
        </v-col>
        <v-col
          v-if="relatorio.VacasSujas || relatorio.VacasSujas == ''"
          cols="12"
          sm="6"
          lg="6"
          xl="6"
          class="col-bordered"
        >
          <span class="key-title">
            Número de vacas sujas (escore de sujidade 3 e 4):
          </span>
          <span class="value-title">{{ relatorio.VacasSujas || '-' }}</span>
        </v-col>
        <v-col
          v-if="
            relatorio.TempoMedioPreparo || relatorio.TempoMedioPreparo == ''
          "
          cols="12"
          sm="6"
          lg="6"
          xl="6"
          class="col-bordered"
        >
          <span class="key-title">Tempo médio de preparo: </span>
          <span class="value-title">{{
            relatorio.TempoMedioPreparo || '-'
          }}</span>
        </v-col>
        <v-col
          v-if="
            relatorio.TempoMedioOrdenha || relatorio.TempoMedioOrdenha == ''
          "
          cols="12"
          sm="6"
          lg="6"
          xl="6"
          class="col-bordered"
        >
          <span class="key-title">Tempo médio de ordenha: </span>
          <span class="value-title">{{
            relatorio.TempoMedioOrdenha || '-'
          }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'AcompanhamentoOrdenha',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
