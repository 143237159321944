/* eslint-disable prettier/prettier */
<template>
  <v-card class="ma-4">
    <v-card-title style="text-align: left">Declaração</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col cols="12" class="col-bordered">
          <span class="value-title"
            >Valor da visita técnica:
            <strong>R$ {{ relatorio.CustoFixo }}</strong>
            <br />
            O valor destinado ao produtor ({{
              showPercentage(relatorio.PorcentagemcustoProdutor)
            }}%)
            <strong>R$ {{ showMoney(relatorio.CustoVisitaProdutor) }}</strong>
            (descontado em convênio Vigor na NF do leite).
            <br />
            O valor destinado a Vigor ({{
              showPercentage(relatorio.PorcentagemcustoVisitaVigor)
            }}%)
            <strong>R$ {{ showMoney(relatorio.CustoVisitaVigor) }}</strong>
            (pago pela vigor ao consultor).
            <br />

            Outros valores:
            <strong>R$ {{ showMoney(relatorio.outrosValores) }}</strong
            >, Descrição dos demais pagamentos realizados:
            <strong>{{ relatorio.descricaoOutrosValoes }}</strong>

            <br />
            Assinatura produtor(a):
            <img
              :src="
                relatorio.AssinaturaProdutorOuResponsavel === '1'
                  ? relatorio.AssinaturaProdutor
                  : relatorio.AssinaturaResponsavel
              "
              alt="Não foi assinado!!!"
              width="auto"
              height="300px"
              class="assinatura"
            />
            Declaro para os devidos fins ter recebido a visita técnica do
            Projeto Evolui Leite, executado pelo técnico responsável:
            <strong>{{ relatorio.consultor.pessoafisica.Nome }}</strong> , na
            data de <strong>{{ relatorio.DataAtendimento }}</strong
            >, e autorizo a DanVigor a realizar o pagamento da fatura em meu
            nome e efetuar o desconto do valor na nota de pagamento do leite
            fornecido no mês da visita, sendo o valor citado de
            <strong>R$ {{ showMoney(relatorio.CustoVisitaProdutor) }}.</strong>
          </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { currencyFormatter, showMoney } from '../../../../utils/formatter'
export default {
  name: 'DeclaracaoEvolui',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  methods: {
    currencyFormatter,
    showMoney,
    showPercentage(value) {
      if (typeof value === 'string') return value.replace('.', ',')

      return String(Number(value).toFixed(2) ?? '').replace('.', ',')
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}

.assinatura {
  display: block;
  transform: rotate(90deg);
  margin-left: auto;
  margin-right: auto;
  border: solid 2px #333;
  border-radius: 10px;
}
</style>
