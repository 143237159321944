var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.relatorio.ManterAttZootecnico ||
    _vm.relatorio.ManterAppSync ||
    _vm.relatorio.ColocarBrincoRecria ||
    _vm.relatorio.ColocarBrincoLactacao ||
    _vm.relatorio.ColocarBrincoSecas ||
    _vm.relatorio.RecomendacaoZootecnico ||
    _vm.relatorio.ColetaZootecnicoObs ||
    _vm.relatorio.ColetaZootecnicoAtividade1 ||
    _vm.relatorio.ColetaZootecnicoAtividade2 ||
    _vm.relatorio.ColetaZootecnicoAtividade3 ||
    _vm.relatorio.ColetaZootecnicoAtividade4 ||
    _vm.relatorio.ColetaZootecnicoOutrasAtividades
  )?_c('v-card',{staticClass:"ma-4"},[_c('v-card-title',{staticStyle:{"text-align":"left"}},[_vm._v("Coleta e atualização de dados zootécnicos")]),_c('v-card-text',[(_vm.relatorio.ColetaZootecnico)?_c('v-row',{staticClass:"pa-3"},[(
          _vm.relatorio.ColetaZootecnicoAtividade1 ||
          _vm.relatorio.ColetaZootecnicoAtividade2 ||
          _vm.relatorio.ColetaZootecnicoAtividade3 ||
          _vm.relatorio.ColetaZootecnicoAtividade4 ||
          _vm.relatorio.ColetaZootecnicoOutrasAtividades
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Atividades realizadas")]),(_vm.relatorio.ColetaZootecnicoAtividade1)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Lançamento dos dados zootécnicos (parto, cobertura/IA, secagem, etc.) no sistema")])]):_vm._e(),(_vm.relatorio.ColetaZootecnicoAtividade2)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Orientação de como fazer as anotações no caderno zootécnico ")])]):_vm._e(),(_vm.relatorio.ColetaZootecnicoAtividade3)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Orientação de como fazer lançamento no sistema")])]):_vm._e(),(_vm.relatorio.ColetaZootecnicoAtividade4)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Orientação de como visualizar os relatórios no sistema")])]):_vm._e(),(_vm.relatorio.ColetaZootecnicoOutrasAtividades)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Outras Atividades: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.ColetaZootecnicoOutrasAtividadesText || '-'))])]):_vm._e()],1):_vm._e(),(
          _vm.relatorio.ManterAttZootecnico ||
          _vm.relatorio.ManterAppSync ||
          _vm.relatorio.ColocarBrincoRecria ||
          _vm.relatorio.ColocarBrincoLactacao ||
          _vm.relatorio.ColocarBrincoSecas
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Recomendações")]),(_vm.relatorio.ManterAttZootecnico)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Manter atualizações dos lançamentos zootécnicos em dia ")])]):_vm._e(),(_vm.relatorio.ManterAppSync)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Manter o aplicativo atualizado semanalmente ")])]):_vm._e(),(_vm.relatorio.ColocarBrincoRecria)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Colocar brinco de identificação nos animais em recria ")])]):_vm._e(),(_vm.relatorio.ColocarBrincoLactacao)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Colocar brinco de identificação nas vacas em lactação ")])]):_vm._e(),(_vm.relatorio.ColocarBrincoSecas)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Colocar brinco de identificação nas vacas secas ")])]):_vm._e(),(_vm.relatorio.RecomendacaoZootecnico)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Outras recomendações: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.RecomendacaoZootecnico || '-'))])]):_vm._e()],1):_vm._e()],1):_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('span',{staticClass:"key-title"},[_vm._v("Atividade não realizada ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.ColetaZootecnicoObs || '-'))])])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }