<template>
  <v-card class="ma-3">
    <base-loading v-if="loading" />
    <div v-else>
      <v-card-title style="justify-content: center; font-size: 24px">
        <v-row justify="center">
          <v-col cols="12">
            <v-row justify="center"> Relatório Evolui Leite </v-row>
          </v-col>
          <v-col cols="12">
            <v-row justify="center">{{ relatorio.NumeroVisita }}ª visita</v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <InformacoesGeraisEvolui :relatorio="relatorio" />
      <AtividadesRealizadasEvolui :relatorio="relatorio" />
      <IndicadoresQualidadeEvolui :relatorio="relatorio" :draft="draft" />
      <ComposicaoRebanhoEvolui :relatorio="relatorio" />
      <ColetaZootecnicoEvolui :relatorio="relatorio" />
      <ColetaFinanceiroEvolui :relatorio="relatorio" />
      <ManejoReprodutivoEvolui :relatorio="relatorio" />
      <AjustesNutricionaisEvolui :relatorio="relatorio" />
      <PlanejamentoVolumosoEvolui :relatorio="relatorio" />
      <RecomendacaoOrdenhaEvolui :relatorio="relatorio" />
      <AcompanhamentoOrdenhaEvolui :relatorio="relatorio" />
      <SanitarioEvolui :relatorio="relatorio" />
      <CriaRecriaEvolui :relatorio="relatorio" />
      <ClinicoEvolui :relatorio="relatorio" />
      <DiscussaoZootecnicasEvolui :relatorio="relatorio" />
      <DiscussaoEconomicosEvolui :relatorio="relatorio" />
      <DiscussaoMercadoEvolui :relatorio="relatorio" />
      <PlanejamentoEstrategicoEvolui :relatorio="relatorio" />
      <DeclaracaoEvolui :relatorio="relatorio" />
      <ImagensAnexoEvolui :relatorio="relatorio" />
      <ProximaVisitaEvolui :relatorio="relatorio" />
      <v-col cols="12">
        <v-row justify="center">
          <v-btn
            color="primary"
            @click="
              () => {
                exportRel(relatorio, true)
              }
            "
          >
            <v-icon style="margin-right: 6px"> mdi-file-document </v-icon>
            <span style="font-weight: bold; font-size: 16px">Exportar PDF</span>
          </v-btn>
        </v-row>
      </v-col>
      <v-row justify="center">
        <v-dialog v-model="dialogProgressPDF" persistent max-width="430">
          <v-card>
            <v-card-title class="text-h3"> Aguarde! </v-card-title>
            <v-card-title class="text-h3">
              Estamos preparando seu relatório.
            </v-card-title>
            <v-card-text>
              <div class="text-center">
                <v-progress-circular :size="40" indeterminate color="teal">
                </v-progress-circular>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import exportPDF from '../../../../services/reports/RelatorioPDFEvolui'
import { exportReport } from '../../../../utils/report'
import AcompanhamentoOrdenhaEvolui from './AcompanhamentoOrdenhaEvolui'
import AjustesNutricionaisEvolui from './AjustesNutricionaisEvolui'
import AtividadesRealizadasEvolui from './AtividadesRealizadasEvolui'
import ClinicoEvolui from './ClinicoEvolui'
import ColetaFinanceiroEvolui from './ColetaFinanceiroEvolui'
import ColetaZootecnicoEvolui from './ColetaZootecnicoEvolui'
import ComposicaoRebanhoEvolui from './ComposicaoRebanhoEvolui'
import CriaRecriaEvolui from './CriaRecriaEvolui'
import DeclaracaoEvolui from './DeclaracaoEvolui'
import DiscussaoEconomicosEvolui from './DiscussaoEconomicosEvolui'
import DiscussaoMercadoEvolui from './DiscussaoMercadoEvolui'
import DiscussaoZootecnicasEvolui from './DiscussaoZootecnicasEvolui'
import ImagensAnexoEvolui from './ImagensAnexoEvolui'
import IndicadoresQualidadeEvolui from './IndicadoresQualidadeEvolui'
import InformacoesGeraisEvolui from './InformacoesGeraisEvolui'
import ManejoReprodutivoEvolui from './ManejoReprodutivoEvolui'
import PlanejamentoEstrategicoEvolui from './PlanejamentoEstrategicoEvolui'
import PlanejamentoVolumosoEvolui from './PlanejamentoVolumosoEvolui'
import ProximaVisitaEvolui from './ProximaVisitaEvolui'
import RecomendacaoOrdenhaEvolui from './RecomendacaoOrdenhaEvolui'
import SanitarioEvolui from './SanitarioEvolui'

export default {
  components: {
    AcompanhamentoOrdenhaEvolui,
    AtividadesRealizadasEvolui,
    ColetaFinanceiroEvolui,
    ManejoReprodutivoEvolui,
    PlanejamentoVolumosoEvolui,
    PlanejamentoEstrategicoEvolui,
    AjustesNutricionaisEvolui,
    SanitarioEvolui,
    CriaRecriaEvolui,
    ClinicoEvolui,
    DiscussaoZootecnicasEvolui,
    DiscussaoMercadoEvolui,
    DiscussaoEconomicosEvolui,
    ColetaZootecnicoEvolui,
    RecomendacaoOrdenhaEvolui,
    DeclaracaoEvolui,
    ComposicaoRebanhoEvolui,
    IndicadoresQualidadeEvolui,
    InformacoesGeraisEvolui,
    ProximaVisitaEvolui,
    ImagensAnexoEvolui,
  },

  props: {
    codRelatorio: {
      type: String,
      default: () => '',
    },
    draft: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dialogProgressPDF: false,
      loading: true,
      relatorio: null,
    }
  },
  mounted() {
    if (!this.codRelatorio) {
      this.$router.push({
        path: `/${this.$user.get().role}/relatorios/evoluileite`,
      })
    } else {
      this.getRelatorio()
    }
  },
  methods: {
    exportPDF,
    getRelatorio() {
      this.api.get.relatorioccs(this.codRelatorio, this.draft).then(data => {
        this.relatorio = data
        this.loading = false
      })
    },
    async exportRel(relatorio, local) {
      if (!relatorio) relatorio = this.relatorio
      this.dialogProgressPDF = local ? true : false
      await exportReport({
        report: relatorio,
        exportReport: this.exportPDF,
      })
      this.dialogProgressPDF = false
    },
  },
}
</script>
