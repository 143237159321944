<template>
  <v-card
    v-if="
      relatorio.ManterAttZootecnico ||
      relatorio.ManterAppSync ||
      relatorio.ColocarBrincoRecria ||
      relatorio.ColocarBrincoLactacao ||
      relatorio.ColocarBrincoSecas ||
      relatorio.RecomendacaoZootecnico ||
      relatorio.ColetaZootecnicoObs ||
      relatorio.ColetaZootecnicoAtividade1 ||
      relatorio.ColetaZootecnicoAtividade2 ||
      relatorio.ColetaZootecnicoAtividade3 ||
      relatorio.ColetaZootecnicoAtividade4 ||
      relatorio.ColetaZootecnicoOutrasAtividades
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left"
      >Coleta e atualização de dados zootécnicos</v-card-title
    >
    <v-card-text>
      <v-row v-if="relatorio.ColetaZootecnico" class="pa-3">
        <v-col
          v-if="
            relatorio.ColetaZootecnicoAtividade1 ||
            relatorio.ColetaZootecnicoAtividade2 ||
            relatorio.ColetaZootecnicoAtividade3 ||
            relatorio.ColetaZootecnicoAtividade4 ||
            relatorio.ColetaZootecnicoOutrasAtividades
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Atividades realizadas</span>
          <v-col v-if="relatorio.ColetaZootecnicoAtividade1" cols="12">
            <span class="value-title"
              >Lançamento dos dados zootécnicos (parto, cobertura/IA, secagem,
              etc.) no sistema</span
            >
          </v-col>
          <v-col v-if="relatorio.ColetaZootecnicoAtividade2" cols="12">
            <span class="value-title"
              >Orientação de como fazer as anotações no caderno zootécnico
            </span>
          </v-col>
          <v-col v-if="relatorio.ColetaZootecnicoAtividade3" cols="12">
            <span class="value-title"
              >Orientação de como fazer lançamento no sistema</span
            >
          </v-col>
          <v-col v-if="relatorio.ColetaZootecnicoAtividade4" cols="12">
            <span class="value-title"
              >Orientação de como visualizar os relatórios no sistema</span
            >
          </v-col>
          <v-col v-if="relatorio.ColetaZootecnicoOutrasAtividades" cols="12">
            <span class="key-title">Outras Atividades: </span> <br />
            <span class="value-title">{{
              relatorio.ColetaZootecnicoOutrasAtividadesText || '-'
            }}</span>
          </v-col>
        </v-col>

        <v-col
          v-if="
            relatorio.ManterAttZootecnico ||
            relatorio.ManterAppSync ||
            relatorio.ColocarBrincoRecria ||
            relatorio.ColocarBrincoLactacao ||
            relatorio.ColocarBrincoSecas
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Recomendações</span>
          <v-col v-if="relatorio.ManterAttZootecnico" cols="12">
            <span class="value-title"
              >Manter atualizações dos lançamentos zootécnicos em dia
            </span>
          </v-col>
          <v-col v-if="relatorio.ManterAppSync" cols="12">
            <span class="value-title"
              >Manter o aplicativo atualizado semanalmente
            </span>
          </v-col>
          <v-col v-if="relatorio.ColocarBrincoRecria" cols="12">
            <span class="value-title"
              >Colocar brinco de identificação nos animais em recria
            </span>
          </v-col>
          <v-col v-if="relatorio.ColocarBrincoLactacao" cols="12">
            <span class="value-title"
              >Colocar brinco de identificação nas vacas em lactação
            </span>
          </v-col>
          <v-col v-if="relatorio.ColocarBrincoSecas" cols="12">
            <span class="value-title"
              >Colocar brinco de identificação nas vacas secas
            </span>
          </v-col>
          <v-col v-if="relatorio.RecomendacaoZootecnico" cols="12">
            <span class="key-title">Outras recomendações: </span> <br />
            <span class="value-title">{{
              relatorio.RecomendacaoZootecnico || '-'
            }}</span>
          </v-col>
        </v-col>
      </v-row>
      <v-row v-else justify="center">
        <v-col>
          <span class="key-title">Atividade não realizada </span> <br />
          <span class="value-title">{{
            relatorio.ColetaZootecnicoObs || '-'
          }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ColetaZootecnico',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
  line-height: 40px;
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
