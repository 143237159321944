import HtmlToCanvas from 'html2canvas'
import JsPDF from 'jspdf'
import moment from 'moment'
import { ENV_APP } from '../../../env'
import { showMoney } from '../../utils/formatter'
import { convertNumberBR } from '../../utils/masks'

function showPercentage(value) {
  if (typeof value === 'string') return value.replace('.', ',')

  return String(Number(value).toFixed(2) ?? '').replace('.', ',')
}

const utilsRelatorio = {
  getDataAtual() {
    var monName = [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro',
    ]
    var now = new Date()

    var str =
      'Impresso em ' +
      now.getDate() +
      ' de ' +
      monName[now.getMonth()] +
      ' de ' +
      now.getFullYear() +
      ' às ' +
      formatDate(now, 'HH:mm:ss')
    return str
  },
  calcLacVacas(relatorio) {
    if (relatorio.VacasLactacao && relatorio.VacasSecas) {
      return `${convertNumberBR(
        100 *
          (parseInt(relatorio.VacasLactacao) /
            (parseInt(relatorio.VacasLactacao) +
              parseInt(relatorio.VacasSecas))),
      )} %`
    } else {
      return '-'
    }
  },
  calcLacRebanho(relatorio) {
    var totalRebanho = 0
    if (relatorio.VacasLactacao) {
      totalRebanho += parseInt(relatorio.VacasLactacao)
    }
    if (relatorio.VacasSecas) {
      totalRebanho += parseInt(relatorio.VacasSecas)
    }
    if (relatorio.Novilhas) {
      totalRebanho += parseInt(relatorio.Novilhas)
    }
    if (relatorio.Recria) {
      totalRebanho += parseInt(relatorio.Recria)
    }
    if (relatorio.Aleitamento) {
      totalRebanho += parseInt(relatorio.Aleitamento)
    }
    if (relatorio.Machos) {
      totalRebanho += parseInt(relatorio.Machos)
    }
    if (totalRebanho > 0) {
      return `${convertNumberBR(
        100 * (parseInt(relatorio.VacasLactacao) / totalRebanho),
      )} %`
    } else {
      return '-'
    }
  },
  calcProdutividade(relatorio) {
    if (relatorio.VacasLactacao && relatorio.ProducaoMedia) {
      return `${convertNumberBR(
        parseInt(relatorio.ProducaoMedia) / parseInt(relatorio.VacasLactacao),
      )} L / dia`
    } else {
      return '-'
    }
  },
  calcProdutividadeTotal(relatorio) {
    var totalVacas = 0
    if (relatorio.VacasLactacao) {
      totalVacas += parseInt(relatorio.VacasLactacao)
    }
    if (relatorio.VacasSecas) {
      totalVacas += parseInt(relatorio.VacasSecas)
    }
    if (relatorio.ProducaoMedia && totalVacas > 0) {
      return `${convertNumberBR(
        parseInt(relatorio.ProducaoMedia) / totalVacas,
      )} L / dia`
    } else {
      return '-'
    }
  },
  calcProdutividadeDH(relatorio) {
    if (relatorio.NumeroMDO && relatorio.ProducaoMedia) {
      return `${convertNumberBR(
        parseInt(relatorio.ProducaoMedia) / parseInt(relatorio.NumeroMDO),
      )}`
    } else {
      return '-'
    }
  },
}

function formatDate(date, format = 'DD/MM/YYYY') {
  return moment(date).format(format)
}

function renderDiscussao(doc, data, cursor, config) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    startX,
    tableLimit,
  } = config
  data = data || '-'
  const width = doc.getTextWidth(data)
  const numberLines = parseInt(width / (tableLimit - smallGutterX * 2) + 1)

  cursor = checkAddPage(doc, cursor, smallBoxHeight * numberLines, config)

  doc.rect(startX, cursor, line, smallBoxHeight * numberLines, 'F')

  doc.text(data, startX + smallGutterX, cursor + smallGutterY, {
    maxWidth: tableLimit - smallGutterX * 2,
  })

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight * numberLines, 'F')

  doc.rect(startX, cursor + smallBoxHeight * numberLines, tableLimit, line, 'F')

  return cursor + smallBoxHeight * numberLines
}

function renderCheckboxList(doc, text, cursor, config) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    startX,
    tableLimit,
  } = config

  doc.rect(startX, cursor, line, smallBoxHeight, 'F')

  doc.text(text, startX + smallGutterX, cursor + smallGutterY, {
    maxWidth: tableLimit - smallGutterX * 2,
  })

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight, 'F')
}

// eslint-disable-next-line no-unused-vars
function renderIdentificacaoSanitario(
  doc,
  text,
  noneText,
  data,
  cursor,
  config,
) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    gutterY,
    startX,
    tableLimit,
  } = config

  if (!data) return cursor

  if (data) {
    data = data.split('|')
    for (const index in data) {
      data[index] = data[index].replace(/\//g, ' | ')
    }
  } else {
    data = [noneText]
  }

  const numberLines = data.length + 1

  cursor = checkAddPage(doc, cursor, smallBoxHeight * numberLines, config)

  doc.rect(startX, cursor, line, smallBoxHeight * numberLines, 'F')

  doc.text(text, startX + smallGutterX, cursor + smallGutterY)

  for (const index in data) {
    doc.text(
      data[index],
      startX + smallGutterX,
      cursor + gutterY + smallBoxHeight * index,
    )
  }

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight * numberLines, 'F')

  doc.rect(startX, cursor + smallBoxHeight * numberLines, tableLimit, line, 'F')

  return cursor + smallBoxHeight * numberLines
}

// eslint-disable-next-line no-unused-vars
function renderIdentificacao(doc, text, noneText, data, cursor, config) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    gutterY,
    startX,
    tableLimit,
  } = config

  if (!data) return cursor

  data = data ? data.replace(/\|/g, ', ') : noneText
  const width = doc.getTextWidth(data)
  const numberLines = parseInt(width / (tableLimit - smallGutterX * 2) + 2)

  cursor = checkAddPage(doc, cursor, smallBoxHeight * numberLines, config)

  doc.rect(startX, cursor, line, smallBoxHeight * numberLines, 'F')

  doc.text(text, startX + smallGutterX, cursor + smallGutterY)

  doc.text(data, startX + smallGutterX, cursor + gutterY, {
    maxWidth: tableLimit - smallGutterX * 2,
  })

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight * numberLines, 'F')

  doc.rect(startX, cursor + smallBoxHeight * numberLines, tableLimit, line, 'F')

  return cursor + smallBoxHeight * numberLines
}

function renderCheckbox(doc, text, cursor, config) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    startX,
    tableLimit,
  } = config

  doc.rect(startX, cursor, line, smallBoxHeight, 'F')

  doc.text(text, startX + smallGutterX, cursor + smallGutterY)

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight, 'F')

  doc.rect(startX, cursor + smallBoxHeight, tableLimit, line, 'F')
}

function renderRecomendacoes(doc, text, data, cursor, config) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    gutterY,
    startX,
    tableLimit,
  } = config

  if (!data) return cursor

  data = data || '-'
  const width = doc.getTextWidth(data)
  const numberLines = parseInt(width / (tableLimit - smallGutterX * 2) + 2)

  cursor = checkAddPage(doc, cursor, smallBoxHeight * numberLines, config)

  doc.rect(startX, cursor, line, smallBoxHeight * numberLines, 'F')

  doc.setFont('helvetica', 'normal')
  doc.text(text, startX + smallGutterX, cursor + smallGutterY)

  doc.text(data, startX + smallGutterX, cursor + gutterY, {
    maxWidth: tableLimit - smallGutterX * 2,
  })

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight * numberLines, 'F')

  doc.rect(startX, cursor + smallBoxHeight * numberLines, tableLimit, line, 'F')

  cursor = checkAddPage(doc, cursor, smallBoxHeight * numberLines, config)

  return cursor + smallBoxHeight * numberLines
}

function checkAddPage(doc, cursor, area, config) {
  const { heightLimit, margin, tableLimit, line, startX } = config

  if (cursor + area > heightLimit) {
    doc.rect(startX, cursor, tableLimit, line, 'F')
    doc.addPage()
    cursor = margin + 5
    doc.rect(startX, cursor, tableLimit, line, 'F')
  }

  return cursor
}

function header(doc, config, relatorio) {
  const logo1 = new Image()
  const logo2 = new Image()

  logo1.src = config.logo

  doc.addImage(logo1, 'PNG', config.margin, config.margin, 70, 55)
  doc.setFontSize(config.largefontSize).setFont('helvetica', 'bold')

  const widthOfString = doc.getTextWidth('Relatório Evolui Leite')

  doc.text(
    'Relatório Evolui Leite',
    config.tableLimit / 2 - widthOfString / 2 + config.margin,
    config.margin + 30,
  )

  if (relatorio.logoagroindustria) {
    logo2.src = relatorio.logoagroindustria
    doc.addImage(logo2, 'PNG', config.tableLimit - 50, config.margin, 73, 50)
  }

  doc.setFontSize(config.fontSize).setFont('helvetica', 'normal')
}

function subHeader(doc, _ref, _ref2) {
  const {
    fazenda: {
      produtor: { Nome: produtor },
      pessoajuridica: { NomeFantasia: fazenda },
      producer: { Matricula: matricula },
    },
    consultor: {
      pessoafisica: { Nome: consultor },
    },
    cidadefazenda,
    HoraInicio,
    HoraFim,
    NumeroVisita,
  } = _ref
  const {
    boxHeight,
    gutterY,
    line,
    lineColor,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
    mediumFontSize,
    fontSize,
  } = _ref2

  doc.setFillColor(lineColor)

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Informações básicas', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, startY + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight, line, boxHeight, 'F')

  doc.text('Fazenda:', startX + smallGutterX, startY + smallGutterY + boxHeight)

  doc
    .setFont('helvetica', 'bold')
    .text(fazenda, startX + smallGutterX, startY + gutterY + boxHeight)
    .setFont('helvetica', 'normal')

  doc.rect(startX, startY + boxHeight * 2, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX + tableLimit, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Produtor(a):',
    startX + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    produtor.substring(0, 25),
    startX + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 3, tableLimit, line, 'F')

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Matrícula:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    matricula ? matricula : '-',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.text(
    'Consultor(a):',
    startX + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(consultor, startX + smallGutterX, startY + boxHeight * 3 + gutterY)

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Cidade:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    cidadefazenda ? cidadefazenda.Nome : '-',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 4, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.text(
    'Data:',
    startX + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    _ref.DataAtendimento,
    startX + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 4,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Horário do início:',
    startX + tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    HoraInicio,
    startX + tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Horário do término:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    HoraFim,
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 4,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Número da visita:',
    startX + tableLimit - tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    `${NumeroVisita}ª visita`,
    startX + tableLimit - tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 5, tableLimit, line, 'F')
}

function indicadoresQualidade(doc, relatorio, _ref2) {
  const {
    boxHeight,
    fontSize,
    line,
    gutterY,
    mediumFontSize,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
  } = _ref2

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Principais Indicadores', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, startY + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Produção média do último mês:',
    startX + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.ProducaoMedia
      ? `${relatorio.ProducaoMedia} L / Dia`
      : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit / 3, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'CPP média do último mês:',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.CppMedia ? `${relatorio.CppMedia} UFC/ml * 10³` : 'Não informado',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'CCS média do último mês:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.CcsMedia
      ? `${relatorio.CcsMedia} céls/ml * 10³`
      : 'Não informado',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 2, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.text(
    'Número de pessoas envolvidas no manejo de rebanho:',
    startX + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    relatorio.NumeroMDO ? `${relatorio.NumeroMDO}` : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Área para produção de leite:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    relatorio.AreaProducao ? `${relatorio.AreaProducao} ha` : 'Não informado',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 3, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.text(
    'Produtividade/DH:',
    startX + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    `${utilsRelatorio.calcProdutividadeDH(relatorio)}`,
    startX + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Produtividade / Área:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    relatorio.ProducaoMedia && relatorio.AreaProducao
      ? `${convertNumberBR(
          (parseInt(relatorio.ProducaoMedia) * 365) /
            parseInt(relatorio.AreaProducao),
        )} L / ha / ano`
      : '-',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 4, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 4, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 4, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.rect(startX + tableLimit, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.text(
    'Proteína média:',
    startX + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    relatorio.ProteinaMediaUltimoMes
      ? `${relatorio.ProteinaMediaUltimoMes} %`
      : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 2,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Gordura média:',
    startX + tableLimit - tableLimit / 2 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    relatorio.GorduraMediaUltimoMes
      ? relatorio.GorduraMediaUltimoMes + ' %'
      : 'Não informado',
    startX + tableLimit - tableLimit / 2 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 5, tableLimit, line, 'F')
}

function percentualEfetivacao(doc, relatorio, _ref2) {
  const {
    boxHeight,
    fontSize,
    line,
    gutterY,
    mediumFontSize,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
  } = _ref2

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text(
      'Atividades realizadas/Atividades recomendadas na última visita',
      startX,
      startY + 10,
    )
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, startY + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Percentual de efetivação:',
    startX + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.ProducaoMedia
      ? `${relatorio.PercentualEfetivacao} %`
      : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 2, tableLimit, line, 'F')
}

function composicaoRebanho(doc, relatorio, _ref2) {
  const {
    boxHeight,
    fontSize,
    line,
    gutterY,
    mediumFontSize,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
  } = _ref2

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Composição do rebanho', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  const cursor = startY + boxHeight + 170

  doc.rect(startX, cursor + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, cursor + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Vacas em lactação:',
    startX + smallGutterX,
    cursor + boxHeight + smallGutterY,
  )

  doc.text(
    `${relatorio.VacasLactacao}`,
    startX + smallGutterX,
    cursor + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit / 3, cursor + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Vacas secas:',
    startX + tableLimit / 3 + smallGutterX,
    cursor + boxHeight + smallGutterY,
  )

  doc.text(
    `${relatorio.VacasSecas}`,
    startX + tableLimit / 3 + smallGutterX,
    cursor + boxHeight + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    cursor + boxHeight,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Novilhas:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    cursor + boxHeight + smallGutterY,
  )

  doc.text(
    `${relatorio.Novilhas}`,
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    cursor + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit, cursor + boxHeight, line, boxHeight, 'F')

  doc.rect(startX, cursor + boxHeight * 2, tableLimit, line, 'F')

  doc.rect(startX, cursor + boxHeight * 2, line, boxHeight, 'F')

  doc.text(
    'Machos:',
    startX + smallGutterX,
    cursor + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    `${relatorio.Machos}`,
    startX + smallGutterX,
    cursor + boxHeight * 2 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 3,
    cursor + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Animais em recria:',
    startX + tableLimit / 3 + smallGutterX,
    cursor + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    `${relatorio.Recria}`,
    startX + tableLimit / 3 + smallGutterX,
    cursor + boxHeight * 2 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    cursor + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Animais em aleitamento:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    cursor + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    `${relatorio.Aleitamento}`,
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    cursor + boxHeight * 2 + gutterY,
  )

  doc.rect(startX + tableLimit, cursor + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX, cursor + boxHeight * 3, tableLimit, line, 'F')

  doc.rect(startX, cursor + boxHeight * 3, line, boxHeight, 'F')

  doc.text(
    'Vacas em lactação / total de vacas:',
    startX + smallGutterX,
    cursor + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    `${utilsRelatorio.calcLacVacas(relatorio)}`,
    startX + smallGutterX,
    cursor + boxHeight * 3 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    cursor + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Vacas em lactação / total do rebanho:',
    startX + tableLimit / 2 + smallGutterX,
    cursor + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    `${utilsRelatorio.calcLacRebanho(relatorio)}`,
    startX + tableLimit / 2 + smallGutterX,
    cursor + boxHeight * 3 + gutterY,
  )

  doc.rect(startX + tableLimit, cursor + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, cursor + boxHeight * 4, tableLimit, line, 'F')

  doc.rect(startX, cursor + boxHeight * 4, line, boxHeight, 'F')

  doc.text(
    'Produtividade / vacas em lactação:',
    startX + smallGutterX,
    cursor + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    `${utilsRelatorio.calcProdutividade(relatorio)}`,
    startX + smallGutterX,
    cursor + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    cursor + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Produtividade / total de vacas:',
    startX + tableLimit / 2 + smallGutterX,
    cursor + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    `${utilsRelatorio.calcProdutividadeTotal(relatorio)}`,
    startX + tableLimit / 2 + smallGutterX,
    cursor + boxHeight * 4 + gutterY,
  )

  doc.rect(startX + tableLimit, cursor + boxHeight * 4, line, boxHeight, 'F')

  doc.rect(startX, cursor + boxHeight * 5, tableLimit, line, 'F')
  return cursor + boxHeight * 5
}

function coletaZootecnico(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config

  if (
    relatorio.ManterAttZootecnico ||
    relatorio.ManterAppSync ||
    relatorio.ColocarBrincoRecria ||
    relatorio.ColocarBrincoLactacao ||
    relatorio.ColocarBrincoSecas ||
    relatorio.RecomendacaoZootecnico ||
    relatorio.ColetaZootecnicoObs ||
    relatorio.ColetaZootecnicoAtividade1 ||
    relatorio.ColetaZootecnicoAtividade2 ||
    relatorio.ColetaZootecnicoAtividade3 ||
    relatorio.ColetaZootecnicoAtividade4 ||
    relatorio.ColetaZootecnicoOutrasAtividades
  ) {
    cursor += config.boxHeight
    if (cursor + boxHeight * 2 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Coleta e atualização de dados zootécnicos', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 5

    doc.rect(startX, cursor, tableLimit, line, 'F')

    if (relatorio.ColetaZootecnico) {
      if (
        relatorio.ColetaZootecnicoAtividade1 ||
        relatorio.ColetaZootecnicoAtividade2 ||
        relatorio.ColetaZootecnicoAtividade3 ||
        relatorio.ColetaZootecnicoAtividade4 ||
        relatorio.ColetaZootecnicoOutrasAtividades
      ) {
        doc.setFont('helvetica', 'bold')
        renderCheckboxList(doc, ' • Atividades realizadas:', cursor, config)
        doc.setFont('helvetica', 'normal')
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        if (relatorio.ColetaZootecnicoAtividade1) {
          renderCheckboxList(
            doc,
            'Lançamento dos dados zootécnicos (parto, cobertura/IA,secagem,etc.) no sistema',
            cursor,
            config,
          )
          cursor += smallBoxHeight
        }
        if (relatorio.ColetaZootecnicoAtividade2) {
          renderCheckboxList(
            doc,
            'Orientação de como fazer as anotações no caderno zootécnico',
            cursor,
            config,
          )
          cursor += smallBoxHeight
        }
        if (relatorio.ColetaZootecnicoAtividade3) {
          renderCheckboxList(
            doc,
            'Orientação de como fazer lançamento no sistema',
            cursor,
            config,
          )
          cursor += smallBoxHeight
        }
        if (relatorio.ColetaZootecnicoAtividade4) {
          renderCheckboxList(
            doc,
            'Orientação de como visualizar os relatórios no sistema',
            cursor,
            config,
          )
          cursor += smallBoxHeight
        }
        if (relatorio.ColetaZootecnicoOutrasAtividades) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight * 3, config)
          doc.setFont('helvetica', 'bold')
          renderCheckboxList(doc, ' • Outras Atividades:', cursor, config)
          doc.setFont('helvetica', 'normal')
          cursor = renderRecomendacoes(
            doc,
            '',
            relatorio.ColetaZootecnicoOutrasAtividadesText,
            cursor,
            config,
          )
        }
        doc.rect(startX, cursor, tableLimit, line, 'F')
      }

      if (
        relatorio.ManterAttZootecnico ||
        relatorio.ManterAppSync ||
        relatorio.ColocarBrincoRecria ||
        relatorio.ColocarBrincoLactacao ||
        relatorio.ColocarBrincoSecas
      ) {
        doc.setFont('helvetica', 'bold')
        renderCheckboxList(doc, ' • Recomendações:', cursor, config)
        doc.setFont('helvetica', 'normal')
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        if (relatorio.ManterAttZootecnico) {
          renderCheckboxList(
            doc,
            'Manter atualizações dos lançamentos zootécnicos em dia',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.ManterAppSync) {
          renderCheckboxList(
            doc,
            'Manter o aplicativo atualizado semanalmente',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.ColocarBrincoRecria) {
          renderCheckboxList(
            doc,
            'Colocar brinco de identificação nos animais em recria',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.ColocarBrincoLactacao) {
          renderCheckboxList(
            doc,
            'Colocar brinco de identificação nas vacas em lactação',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.ColocarBrincoSecas) {
          renderCheckboxList(
            doc,
            'Colocar brinco de identificação nas vacas secas',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.RecomendacaoZootecnico) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight * 3, config)
          doc.setFont('helvetica', 'bold')
          renderCheckboxList(doc, ' • Outras recomendações:', cursor, config)
          doc.setFont('helvetica', 'normal')
          cursor = renderRecomendacoes(
            doc,
            '',
            relatorio.RecomendacaoZootecnico,
            cursor,
            config,
          )
        }
        doc.rect(startX, cursor, tableLimit, line, 'F')
      }
    } else {
      cursor = renderRecomendacoes(
        doc,
        'Atividade não realizada',
        relatorio.ColetaZootecnicoObs,
        cursor,
        config,
      )
    }
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
  }

  return cursor
}

function coletaFinanceiro(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config

  if (
    (relatorio.ColetaFinanceiro &&
      (relatorio.ManterAttFinanceiro ||
        relatorio.FinanciamentoAVenver ||
        relatorio.ImplementosAVencer ||
        relatorio.EmprestimosAVencer ||
        relatorio.ArmazenarNotasDespesas ||
        relatorio.RecomendacaoFinanceiro ||
        relatorio.ColetaFinanceiroAtividade1 ||
        relatorio.ColetaFinanceiroAtividade2 ||
        relatorio.ColetaFinanceiroAtividade3 ||
        relatorio.ColetaFinanceiroAtividade4 ||
        relatorio.ColetaFinanceiroAtividade5 ||
        relatorio.ColetaFinanceiroAtividade6 ||
        relatorio.ColetaFinanceiroOutrasAtividades)) ||
    relatorio.ColetaFinanceiroObs
  ) {
    cursor += config.boxHeight
    if (cursor + boxHeight * 2 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Coleta e atualização de dados financeiros', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 5

    doc.rect(startX, cursor, tableLimit, line, 'F')

    if (relatorio.ColetaFinanceiro) {
      if (
        relatorio.ColetaFinanceiroAtividade1 ||
        relatorio.ColetaFinanceiroAtividade2 ||
        relatorio.ColetaFinanceiroAtividade3 ||
        relatorio.ColetaFinanceiroAtividade4 ||
        relatorio.ColetaFinanceiroAtividade5 ||
        relatorio.ColetaFinanceiroAtividade6 ||
        relatorio.ColetaFinanceiroOutrasAtividades
      ) {
        doc.setFont('helvetica', 'bold')
        renderCheckboxList(doc, ' • Atividades realizadas:', cursor, config)
        doc.setFont('helvetica', 'normal')
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        if (relatorio.ColetaFinanceiroAtividade1) {
          renderCheckboxList(
            doc,
            'Lançamento de RD (receitas e despesas) no sistema',
            cursor,
            config,
          )
          cursor += smallBoxHeight
        }
        if (relatorio.ColetaFinanceiroAtividade2) {
          renderCheckboxList(
            doc,
            'Lançamento do IR (inventário de recursos) no sistema',
            cursor,
            config,
          )
          cursor += smallBoxHeight
        }
        if (relatorio.ColetaFinanceiroAtividade3) {
          renderCheckboxList(
            doc,
            'Orientação de como fazer as anotações no caderno financeiro',
            cursor,
            config,
          )
          cursor += smallBoxHeight
        }
        if (relatorio.ColetaFinanceiroAtividade4) {
          renderCheckboxList(
            doc,
            'Orientação de como fazer lançamento no sistema',
            cursor,
            config,
          )
          cursor += smallBoxHeight
        }
        if (relatorio.ColetaFinanceiroAtividade5) {
          renderCheckboxList(
            doc,
            'Orientação de como visualizar os relatórios no sistema',
            cursor,
            config,
          )
          cursor += smallBoxHeight
        }
        if (relatorio.ColetaFinanceiroAtividade6) {
          renderCheckboxList(
            doc,
            'Discussão dos indicadores econômicos',
            cursor,
            config,
          )
          cursor += smallBoxHeight
        }
        if (relatorio.ColetaFinanceiroOutrasAtividades) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight * 3, config)
          doc.setFont('helvetica', 'bold')
          renderCheckboxList(doc, ' • Outras Atividades:', cursor, config)
          doc.setFont('helvetica', 'normal')
          cursor = renderRecomendacoes(
            doc,
            '',
            relatorio.ColetaFinanceiroOutrasAtividadesText,
            cursor,
            config,
          )
        }
        doc.rect(startX, cursor, tableLimit, line, 'F')
      }

      if (
        relatorio.ManterAttFinanceiro ||
        relatorio.FinanciamentoAVenver ||
        relatorio.ImplementosAVencer ||
        relatorio.EmprestimosAVencer ||
        relatorio.ArmazenarNotasDespesas ||
        relatorio.RecomendacaoFinanceiro
      ) {
        doc.setFont('helvetica', 'bold')
        renderCheckboxList(doc, ' • Recomendações:', cursor, config)
        doc.setFont('helvetica', 'normal')
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

        if (relatorio.ManterAttFinanceiro) {
          renderCheckboxList(
            doc,
            'Manter atualizações dos lançamentos financeiros em dia',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.FinanciamentoAVenver) {
          renderCheckboxList(
            doc,
            'Fazer o levantamento dos financiamentos a vencer para serem lançados no aplicativo',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.ImplementosAVencer) {
          renderCheckboxList(
            doc,
            'Fazer o levantamento das parcelas dos implementos a vencer para serem lançados no aplicativo',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.EmprestimosAVencer) {
          renderCheckboxList(
            doc,
            'Fazer o levantamento das parcelas dos empréstimos a vencer para serem lançados no aplicativo',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.ArmazenarNotasDespesas) {
          renderCheckboxList(
            doc,
            'Armazenar todas as notas de despesas até que sejam lançadas no aplicativo',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        if (relatorio.RecomendacaoFinanceiro) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight * 3, config)
          doc.setFont('helvetica', 'bold')
          renderCheckboxList(doc, ' • Outras recomendações:', cursor, config)
          doc.setFont('helvetica', 'normal')
          cursor = renderRecomendacoes(
            doc,
            '',
            relatorio.RecomendacaoFinanceiro,
            cursor,
            config,
          )
        }
        doc.rect(startX, cursor, tableLimit, line, 'F')
      }
    } else {
      cursor = renderRecomendacoes(
        doc,
        'Atividade não realizada',
        relatorio.ColetaFinanceiroObs,
        cursor,
        config,
      )
    }
    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
  }

  return cursor
}

function manejoReprodutivo(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
    smallGutterX,
    smallGutterY,
    gutterY,
  } = config

  if (
    (relatorio.ListaVacasReavaliar != null &&
      relatorio.ListaVacasReavaliar.length != 0) ||
    (relatorio.ListaVacasVaziasIATF != null &&
      relatorio.ListaVacasVaziasIATF.length != 0) ||
    (relatorio.ListaVacasVaziasPGF != null &&
      relatorio.ListaVacasVaziasPGF.length != 0) ||
    (relatorio.ListaVacasVaziasRecuperarCondicaoCorporal != null &&
      relatorio.ListaVacasVaziasRecuperarCondicaoCorporal.length != 0) ||
    (relatorio.ListaVacasVaziasTratamentoClinico != null &&
      relatorio.ListaVacasVaziasTratamentoClinico.length != 0) ||
    relatorio.OutrasRecomendacoesReprodutivoVacas != '' ||
    (relatorio.ListaNovilhasGestantes != null &&
      relatorio.ListaNovilhasGestantes.length != 0) ||
    (relatorio.ListaNovilhasReavaliar != null &&
      relatorio.ListaNovilhasReavaliar.length != 0) ||
    (relatorio.ListaNovilhasVaziasIATF != null &&
      relatorio.ListaNovilhasVaziasIATF.length != 0) ||
    (relatorio.ListaNovilhasVaziasPGF != null &&
      relatorio.ListaNovilhasVaziasPGF.length != 0) ||
    (relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal != null &&
      relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal.length != 0) ||
    (relatorio.ListaNovilhasVaziasTratamentoClinico != null &&
      relatorio.ListaNovilhasVaziasTratamentoClinico.length != 0) ||
    relatorio.OutrasRecomendacoesReprodutivoNovilhas != '' ||
    relatorio.ReprodutivoVacasObs != '' ||
    relatorio.ReprodutivoNovilhasObs != '' ||
    (relatorio.QtdeVacasAvaliadas != '' &&
      relatorio.QtdeVacasAvaliadas != '0') ||
    (relatorio.QtdeVacasGestantes != '' &&
      relatorio.QtdeVacasGestantes != '0') ||
    (relatorio.QtdeVacasReavaliar != '' &&
      relatorio.QtdeVacasReavaliar != '0') ||
    (relatorio.QtdeVacasVazias != '' && relatorio.QtdeVacasVazias != '0') ||
    (relatorio.QtdeNovilhasAvaliadas != '' &&
      relatorio.QtdeNovilhasAvaliadas != '0') ||
    (relatorio.QtdeNovilhasGestantes != '' &&
      relatorio.QtdeNovilhasGestantes != '0') ||
    (relatorio.QtdeNovilhasReavaliar != '' &&
      relatorio.QtdeNovilhasReavaliar != '0') ||
    (relatorio.QtdeNovilhasVazias != '' &&
      relatorio.QtdeNovilhasVazias != '0') ||
    relatorio.ReprodutivoVacasAtividade1 ||
    relatorio.ReprodutivoVacasAtividade2 ||
    relatorio.ReprodutivoVacasAtividade3 ||
    relatorio.ReprodutivoVacasAtividade4 ||
    relatorio.ReprodutivoVacasAtividade5 ||
    relatorio.ReprodutivoVacasOutrasAtividades ||
    relatorio.ReprodutivoVacasOutrasAtividadesText ||
    relatorio.ReprodutivoNovilhasAtividade1 ||
    relatorio.ReprodutivoNovilhasAtividade2 ||
    relatorio.ReprodutivoNovilhasAtividade3 ||
    relatorio.ReprodutivoNovilhasAtividade4 ||
    relatorio.ReprodutivoNovilhasAtividade5 ||
    relatorio.ReprodutivoNovilhasOutrasAtividades
  ) {
    //cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, boxHeight, config)
    cursor += config.boxHeight
    if (cursor + boxHeight * 4 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Manejo reprodutivo', startX, cursor)
      .setFontSize(fontSize)

    cursor += smallBoxHeight

    if (
      (relatorio.ListaVacasReavaliar != null &&
        relatorio.ListaVacasReavaliar.length != 0) ||
      (relatorio.ListaVacasVaziasIATF != null &&
        relatorio.ListaVacasVaziasIATF.length != 0) ||
      (relatorio.ListaVacasVaziasPGF != null &&
        relatorio.ListaVacasVaziasPGF.length != 0) ||
      (relatorio.ListaVacasVaziasRecuperarCondicaoCorporal != null &&
        relatorio.ListaVacasVaziasRecuperarCondicaoCorporal.length != 0) ||
      (relatorio.ListaVacasVaziasTratamentoClinico != null &&
        relatorio.ListaVacasVaziasTratamentoClinico.length != 0) ||
      relatorio.OutrasRecomendacoesReprodutivoVacas != ''
    ) {
      doc
        .text('Reprodutivo de Vacas:', startX, cursor)
        .setFont('helvetica', 'normal')

      cursor += smallBoxHeight - 5

      doc.rect(startX, cursor, tableLimit, line, 'F')

      if (
        (relatorio.QtdeVacasAvaliadas && relatorio.QtdeVacasAvaliadas != '') ||
        (relatorio.QtdeVacasGestantes && relatorio.QtdeVacasGestantes != '') ||
        (relatorio.QtdeVacasReavaliar && relatorio.QtdeVacasReavaliar != '') ||
        (relatorio.QtdeVacasVazias && relatorio.QtdeVacasVazias != '')
      ) {
        doc.rect(startX, cursor, tableLimit, line, 'F')

        doc.rect(startX, cursor, line, boxHeight, 'F')

        doc.text(
          'Vacas avaliadas:',
          startX + smallGutterX,
          cursor + smallGutterY,
        )

        doc.text(
          `${relatorio.QtdeVacasAvaliadas || '-'}`,
          startX + smallGutterX,
          cursor + gutterY,
        )

        doc.rect(startX + tableLimit / 4, cursor, line, boxHeight, 'F')

        doc.text(
          'Vacas gestantes:',
          startX + tableLimit / 4 + smallGutterX,
          cursor + smallGutterY,
        )

        doc.text(
          `${relatorio.QtdeVacasGestantes || '-'}`,
          startX + tableLimit / 4 + smallGutterX,
          cursor + gutterY,
        )

        doc.rect(startX + tableLimit / 2, cursor, line, boxHeight, 'F')

        doc.text(
          'Vacas a reavaliar:',
          startX + tableLimit / 2 + smallGutterX,
          cursor + smallGutterY,
        )

        doc.text(
          `${relatorio.QtdeVacasReavaliar || '-'}`,
          startX + tableLimit / 2 + smallGutterX,
          cursor + gutterY,
        )

        doc.rect(
          startX + tableLimit - tableLimit / 4,
          cursor,
          line,
          boxHeight,
          'F',
        )

        doc.text(
          'Vacas vazias:',
          startX + tableLimit - tableLimit / 4 + smallGutterX,
          cursor + smallGutterY,
        )

        doc.text(
          `${relatorio.QtdeVacasVazias || '-'}`,
          startX + tableLimit - tableLimit / 4 + smallGutterX,
          cursor + gutterY,
        )

        doc.rect(startX + tableLimit, cursor, line, boxHeight, 'F')

        cursor += boxHeight

        doc.rect(startX, cursor, tableLimit, line, 'F')
      }

      var colInicio = startX
      if (
        relatorio.ListaVacasGestantes &&
        relatorio.ListaVacasGestantes.length != 0
      ) {
        cursor = checkAddPage(doc, cursor, smallBoxHeight * 2, config)
        doc.rect(startX, cursor, line, boxHeight, 'F') // esquerda
        doc.rect(startX + tableLimit, cursor, line, boxHeight, 'F') // direita
        doc.rect(startX, cursor, tableLimit, line, 'F')
        doc.text(
          'Identificação das vacas gestantes:',
          startX + tableLimit / 1000 + smallGutterX,
          cursor + smallGutterY,
        )

        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, boxHeight, config)

        Object.keys(relatorio.ListaVacasGestantes).forEach(item => {
          if (item % 3 === 1) {
            colInicio = startX + tableLimit / 3
          } else if (item % 3 === 2) {
            colInicio = startX + tableLimit - tableLimit / 3
          } else {
            colInicio = startX
          }

          if (cursor + boxHeight > heightLimit) {
            doc.rect(startX, cursor, tableLimit, line, 'F')
            doc.addPage()
            cursor = margin + 5
            doc.rect(startX, cursor, tableLimit, line, 'F')
          }

          doc.text(
            relatorio.ListaVacasGestantes[item].nomeOuBrincoGestante,
            colInicio + smallGutterX,
            cursor + smallGutterY,
          )
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          if (
            item % 3 === 2 ||
            Object.keys(relatorio.ListaVacasGestantes).length - 1 == item
          ) {
            doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
            doc.rect(tableLimit + startX, cursor, line, smallBoxHeight, 'F') // direita
            cursor += smallBoxHeight
            cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          }
        })

        doc.rect(startX, cursor, tableLimit, line, 'F') // superior
      }
      if (
        relatorio.ListaVacasReavaliar &&
        relatorio.ListaVacasReavaliar.length != 0
      ) {
        cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)
        doc.rect(startX, cursor, line, boxHeight, 'F') // esquerda
        doc.rect(startX + tableLimit, cursor, line, boxHeight, 'F') // direita
        doc.rect(startX, cursor, tableLimit, line, 'F')
        doc.text(
          'Identificação das vacas para reavaliar:',
          startX + tableLimit / 1000 + smallGutterX,
          cursor + smallGutterY,
        )

        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, boxHeight, config)

        Object.keys(relatorio.ListaVacasReavaliar).forEach(item => {
          if (item % 3 === 1) {
            colInicio = startX + tableLimit / 3
          } else if (item % 3 === 2) {
            colInicio = startX + tableLimit - tableLimit / 3
          } else {
            colInicio = startX
          }
          if (cursor + boxHeight > heightLimit) {
            doc.rect(startX, cursor, tableLimit, line, 'F')
            doc.addPage()
            cursor = margin + 5
            doc.rect(startX, cursor, tableLimit, line, 'F')
          }
          doc.text(
            relatorio.ListaVacasReavaliar[item].nomeOuBrincoReavaliar,
            colInicio + smallGutterX,
            cursor + smallGutterY,
          )
          if (
            item % 3 === 2 ||
            Object.keys(relatorio.ListaVacasReavaliar).length - 1 == item
          ) {
            doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
            doc.rect(tableLimit + startX, cursor, line, smallBoxHeight, 'F') // direita
            cursor += smallBoxHeight
            cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          }
        })

        doc.rect(startX, cursor, tableLimit, line, 'F') // superior
      }

      if (
        relatorio.ListaVacasVaziasIATF &&
        relatorio.ListaVacasVaziasIATF.length != 0
      ) {
        cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)
        doc.rect(startX, cursor, line, boxHeight, 'F') // esquerda
        doc.rect(startX + tableLimit, cursor, line, boxHeight, 'F') // direita
        doc.rect(startX, cursor, tableLimit, line, 'F')
        cursor = checkAddPage(doc, cursor, boxHeight, config)
        doc.text(
          'Identificação das vacas vazias que entraram no protocolo de IATF:',
          startX + tableLimit / 1000 + smallGutterX,
          cursor + smallGutterY,
        )

        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, boxHeight, config)

        Object.keys(relatorio.ListaVacasVaziasIATF).forEach(item => {
          if (item % 3 === 1) {
            colInicio = startX + tableLimit / 3
          } else if (item % 3 === 2) {
            colInicio = startX + tableLimit - tableLimit / 3
          } else {
            colInicio = startX
          }
          if (cursor + boxHeight > heightLimit) {
            doc.rect(startX, cursor, tableLimit, line, 'F')
            doc.addPage()
            cursor = margin + 5
            doc.rect(startX, cursor, tableLimit, line, 'F')
          }

          doc.text(
            relatorio.ListaVacasVaziasIATF[item].nomeOuBrincoVaziaIATF,
            colInicio + smallGutterX,
            cursor + smallGutterY,
          )
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          if (
            item % 3 === 2 ||
            Object.keys(relatorio.ListaVacasVaziasIATF).length - 1 == item
          ) {
            doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
            doc.rect(tableLimit + startX, cursor, line, smallBoxHeight, 'F') // direita
            cursor += smallBoxHeight
            cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          }
        })

        doc.rect(startX, cursor, tableLimit, line, 'F') // superior
      }

      if (
        relatorio.ListaVacasVaziasPGF &&
        relatorio.ListaVacasVaziasPGF.length != 0
      ) {
        cursor = checkAddPage(doc, cursor, boxHeight * 3, config)
        doc.rect(startX, cursor, line, boxHeight, 'F') // esquerda
        doc.rect(startX + tableLimit, cursor, line, boxHeight, 'F') // direita
        doc.rect(startX, cursor, tableLimit, line, 'F')
        doc.text(
          'Identificação das vacas vazias que foram sincronizadas no PGF:',
          startX + tableLimit / 1000 + smallGutterX,
          cursor + smallGutterY,
        )

        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, boxHeight, config)

        Object.keys(relatorio.ListaVacasVaziasPGF).forEach(item => {
          if (item % 3 === 1) {
            colInicio = startX + tableLimit / 3
          } else if (item % 3 === 2) {
            colInicio = startX + tableLimit - tableLimit / 3
          } else {
            colInicio = startX
          }
          if (cursor + boxHeight > heightLimit) {
            doc.rect(startX, cursor, tableLimit, line, 'F')
            doc.addPage()
            cursor = margin + 5
            doc.rect(startX, cursor, tableLimit, line, 'F')
          }

          doc.text(
            relatorio.ListaVacasVaziasPGF[item].nomeOuBrincoVaziaPGF,
            colInicio + smallGutterX,
            cursor + smallGutterY,
          )
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          if (
            item % 3 === 2 ||
            Object.keys(relatorio.ListaVacasVaziasPGF).length - 1 == item
          ) {
            doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
            doc.rect(tableLimit + startX, cursor, line, smallBoxHeight, 'F') // direita
            cursor += smallBoxHeight
            cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          }
        })
        if (cursor + boxHeight * 4 > heightLimit) {
          doc.addPage()
          cursor = margin
        }
        doc.rect(startX, cursor, tableLimit, line, 'F') // superior
      }

      if (
        relatorio.ListaVacasVaziasRecuperarCondicaoCorporal &&
        relatorio.ListaVacasVaziasRecuperarCondicaoCorporal.length != 0
      ) {
        doc.rect(startX, cursor, line, boxHeight, 'F') // esquerda
        doc.rect(startX + tableLimit, cursor, line, boxHeight, 'F') // direita
        doc.rect(startX, cursor, tableLimit, line, 'F')
        doc.text(
          'Identificação das vacas vazias que precisam Recuperar o Escore de Condição Corporal(ECC):',
          startX + tableLimit / 1000 + smallGutterX,
          cursor + smallGutterY,
        )

        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, boxHeight, config)

        Object.keys(
          relatorio.ListaVacasVaziasRecuperarCondicaoCorporal,
        ).forEach(item => {
          if (item % 3 === 1) {
            colInicio = startX + tableLimit / 3
          } else if (item % 3 === 2) {
            colInicio = startX + tableLimit - tableLimit / 3
          } else {
            colInicio = startX
          }
          if (cursor + boxHeight > heightLimit) {
            doc.rect(startX, cursor, tableLimit, line, 'F')
            doc.addPage()
            cursor = margin + 5
            doc.rect(startX, cursor, tableLimit, line, 'F')
          }

          doc.text(
            relatorio.ListaVacasVaziasRecuperarCondicaoCorporal[item]
              .nomeOuBrincoVaziaRecuperarCondicaoCorporal,
            colInicio + smallGutterX,
            cursor + smallGutterY,
          )
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          if (
            item % 3 === 2 ||
            Object.keys(relatorio.ListaVacasVaziasRecuperarCondicaoCorporal)
              .length -
              1 ==
              item
          ) {
            doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
            doc.rect(tableLimit + startX, cursor, line, smallBoxHeight, 'F') // direita
            cursor += smallBoxHeight
            cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          }
        })

        doc.rect(startX, cursor, tableLimit, line, 'F') // superior
      }

      if (
        relatorio.ListaVacasVaziasTratamentoClinico &&
        relatorio.ListaVacasVaziasTratamentoClinico.length != 0
      ) {
        doc.rect(startX, cursor, line, boxHeight, 'F') // esquerda
        doc.rect(startX + tableLimit, cursor, line, boxHeight, 'F') // direita
        doc.rect(startX, cursor, tableLimit, line, 'F')
        cursor = checkAddPage(doc, cursor, boxHeight, config)
        doc.text(
          'Identificação das vacas vazias que precisam de tratamento clínico:',
          startX + tableLimit / 1000 + smallGutterX,
          cursor + smallGutterY,
        )

        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, boxHeight, config)

        Object.keys(relatorio.ListaVacasVaziasTratamentoClinico).forEach(
          item => {
            if (item % 3 === 1) {
              colInicio = startX + tableLimit / 3
            } else if (item % 3 === 2) {
              colInicio = startX + tableLimit - tableLimit / 3
            } else {
              colInicio = startX
            }
            if (cursor + boxHeight > heightLimit) {
              doc.rect(startX, cursor, tableLimit, line, 'F')
              doc.addPage()
              cursor = margin + 5
              doc.rect(startX, cursor, tableLimit, line, 'F')
            }

            doc.text(
              relatorio.ListaVacasVaziasTratamentoClinico[item]
                .nomeOuBrincoVaziaTratamentoClinico,
              colInicio + smallGutterX,
              cursor + smallGutterY,
            )
            cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
            if (
              item % 3 === 2 ||
              Object.keys(relatorio.ListaVacasVaziasTratamentoClinico).length -
                1 ==
                item
            ) {
              doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
              doc.rect(tableLimit + startX, cursor, line, smallBoxHeight, 'F') // direita
              cursor += smallBoxHeight
              cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
            }
          },
        )

        doc.rect(startX, cursor, tableLimit, line, 'F') // superior
      }

      cursor = checkAddPage(doc, cursor, boxHeight * 2, config)

      if (
        relatorio.ReprodutivoVacasAtividade1 ||
        relatorio.ReprodutivoVacasAtividade2 ||
        relatorio.ReprodutivoVacasAtividade3 ||
        relatorio.ReprodutivoVacasAtividade4 ||
        relatorio.ReprodutivoVacasAtividade5 ||
        relatorio.ReprodutivoVacasOutrasAtividades ||
        relatorio.ReprodutivoVacasOutrasAtividadesText
      ) {
        doc.rect(startX, cursor, tableLimit, line, 'F')
        doc.setFont('helvetica', 'bold')
        renderCheckboxList(doc, ' • Atividades realizadas:', cursor, config)
        doc.setFont('helvetica', 'normal')
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        if (relatorio.ReprodutivoVacasAtividade1) {
          renderCheckboxList(
            doc,
            'Diagnóstico de gestação das vacas',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, boxHeight, config)
        }
        if (relatorio.ReprodutivoVacasAtividade2) {
          renderCheckboxList(
            doc,
            'Treinamento para aumentar a detecção de cio',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, boxHeight, config)
        }
        if (relatorio.ReprodutivoVacasAtividade3) {
          renderCheckboxList(
            doc,
            'Treinamento para realizar inseminação artificial',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, boxHeight, config)
        }
        if (relatorio.ReprodutivoVacasAtividade4) {
          renderCheckboxList(
            doc,
            'Treinamento para realizar IATF',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, boxHeight, config)
        }
        if (relatorio.ReprodutivoVacasAtividade5) {
          renderCheckboxList(
            doc,
            'Discussão dos indicadores reprodutivos',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, boxHeight, config)
        }
        if (relatorio.ReprodutivoVacasOutrasAtividades) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight * 3, config)
          doc.setFont('helvetica', 'bold')
          renderCheckboxList(doc, ' • Outras Atividades:', cursor, config)
          doc.setFont('helvetica', 'normal')
          cursor = renderRecomendacoes(
            doc,
            '',
            relatorio.ReprodutivoVacasOutrasAtividadesText,
            cursor,
            config,
          )
        }
        doc.rect(startX, cursor, tableLimit, line, 'F')
      }

      if (relatorio.SepararTourosVacas) {
        doc.setFont('helvetica', 'bold')
        renderCheckboxList(doc, ' • Recomendações:', cursor, config)
        doc.setFont('helvetica', 'normal')

        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        cursor = checkAddPage(doc, cursor, boxHeight, config)
        renderCheckboxList(doc, 'Separar o touro das vacas', cursor, config)
        cursor += smallBoxHeight
        doc.rect(startX, cursor, tableLimit, line, 'F')
      }
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.OutrasRecomendacoesReprodutivoVacas) {
        cursor = checkAddPage(doc, cursor, smallBoxHeight * 3, config)
        doc.setFont('helvetica', 'bold')
        renderCheckboxList(doc, ' • Outras recomendações:', cursor, config)
        doc.setFont('helvetica', 'normal')
        cursor = renderRecomendacoes(
          doc,
          '',
          relatorio.OutrasRecomendacoesReprodutivoVacas,
          cursor,
          config,
        )
      }

      cursor = checkAddPage(doc, cursor, boxHeight, config)

      if (relatorio.ReprodutivoVacasObs) {
        doc.rect(startX, cursor, tableLimit, line, 'F') // superior
        cursor = renderRecomendacoes(
          doc,
          'Atividade não realizada:',
          relatorio.ReprodutivoVacasObs,
          cursor,
          config,
        )
      }

      cursor = checkAddPage(doc, cursor, boxHeight, config)
    }
    if (
      (relatorio.QtdeNovilhasAvaliadas &&
        relatorio.QtdeNovilhasAvaliadas != '') ||
      (relatorio.QtdeNovilhasGestantes &&
        relatorio.QtdeNovilhasGestantes != '') ||
      (relatorio.QtdeNovilhasReavaliar &&
        relatorio.QtdeNovilhasReavaliar != '') ||
      (relatorio.QtdeNovilhasVazias && relatorio.QtdeNovilhasVazias != '') ||
      (relatorio.ReprodutivoNovilhasObs &&
        relatorio.ReprodutivoNovilhasObs != '') ||
      relatorio.ReprodutivoNovilhasAtividade1 ||
      relatorio.ReprodutivoNovilhasAtividade2 ||
      relatorio.ReprodutivoNovilhasAtividade3 ||
      relatorio.ReprodutivoNovilhasAtividade4 ||
      relatorio.ReprodutivoNovilhasAtividade5 ||
      relatorio.ReprodutivoNovilhasOutrasAtividades ||
      (relatorio.ListaNovilhasGestantes != null &&
        relatorio.ListaNovilhasGestantes.length != 0) ||
      (relatorio.ListaNovilhasReavaliar != null &&
        relatorio.ListaNovilhasReavaliar.length != 0) ||
      (relatorio.ListaNovilhasVaziasIATF != null &&
        relatorio.ListaNovilhasVaziasIATF.length != 0) ||
      (relatorio.ListaNovilhasVaziasPGF != null &&
        relatorio.ListaNovilhasVaziasPGF.length != 0) ||
      (relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal != null &&
        relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal.length != 0) ||
      (relatorio.ListaNovilhasVaziasTratamentoClinico != null &&
        relatorio.ListaNovilhasVaziasTratamentoClinico.length != 0)
    ) {
      doc
        .setFont('helvetica', 'bold')
        .setFontSize(mediumFontSize)
        .setFontSize(fontSize)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, boxHeight, config)
      doc
        .text('Reprodutivo de novilhas:', startX, cursor)
        .setFont('helvetica', 'normal')

      cursor += smallBoxHeight - 5

      doc.rect(startX, cursor, tableLimit, line, 'F')

      if (
        (relatorio.QtdeNovilhasAvaliadas &&
          relatorio.QtdeNovilhasAvaliadas != '') ||
        (relatorio.QtdeNovilhasGestantes &&
          relatorio.QtdeNovilhasGestantes != '') ||
        (relatorio.QtdeNovilhasReavaliar &&
          relatorio.QtdeNovilhasReavaliar != '') ||
        (relatorio.QtdeNovilhasVazias && relatorio.QtdeNovilhasVazias != '')
      ) {
        doc.rect(startX, cursor, tableLimit, line, 'F')

        doc.rect(startX, cursor, line, boxHeight, 'F')
        cursor = checkAddPage(doc, cursor, boxHeight, config)
        doc.text(
          'Novilhas avaliadas:',
          startX + smallGutterX,
          cursor + smallGutterY,
        )
        cursor = checkAddPage(doc, cursor, boxHeight, config)
        doc.text(
          `${relatorio.QtdeNovilhasAvaliadas || '-'}`,
          startX + smallGutterX,
          cursor + gutterY,
        )

        doc.rect(startX + tableLimit / 4, cursor, line, boxHeight, 'F')
        cursor = checkAddPage(doc, cursor, boxHeight, config)
        doc.text(
          'Novilhas gestantes:',
          startX + tableLimit / 4 + smallGutterX,
          cursor + smallGutterY,
        )

        doc.text(
          `${relatorio.QtdeNovilhasGestantes || '-'}`,
          startX + tableLimit / 4 + smallGutterX,
          cursor + gutterY,
        )

        doc.rect(startX + tableLimit / 2, cursor, line, boxHeight, 'F')
        cursor = checkAddPage(doc, cursor, boxHeight, config)
        doc.text(
          'Novilhas a reavaliar:',
          startX + tableLimit / 2 + smallGutterX,
          cursor + smallGutterY,
        )

        doc.text(
          `${relatorio.QtdeNovilhasReavaliar || '-'}`,
          startX + tableLimit / 2 + smallGutterX,
          cursor + gutterY,
        )

        doc.rect(
          startX + tableLimit - tableLimit / 4,
          cursor,
          line,
          boxHeight,
          'F',
        )
        cursor = checkAddPage(doc, cursor, boxHeight, config)
        doc.text(
          'Novilhas vazias:',
          startX + tableLimit - tableLimit / 4 + smallGutterX,
          cursor + smallGutterY,
        )

        doc.text(
          `${relatorio.QtdeNovilhasVazias || '-'}`,
          startX + tableLimit - tableLimit / 4 + smallGutterX,
          cursor + gutterY,
        )

        doc.rect(startX + tableLimit, cursor, line, boxHeight, 'F')

        cursor += boxHeight

        colInicio = startX

        doc.rect(startX, cursor, tableLimit, line, 'F')
        cursor = checkAddPage(doc, cursor, boxHeight, config)
      }
      if (relatorio.ListaNovilhasGestantes) {
        doc.rect(startX, cursor, line, boxHeight, 'F') // esquerda
        doc.rect(startX + tableLimit, cursor, line, boxHeight, 'F') // direita
        doc.rect(startX, cursor, tableLimit, line, 'F')
        doc.text(
          'Identificação das novilhas gestantes:',
          startX + tableLimit / 1000 + smallGutterX,
          cursor + smallGutterY,
        )

        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, boxHeight, config)

        Object.keys(relatorio.ListaNovilhasGestantes).forEach(item => {
          if (item % 3 === 1) {
            colInicio = startX + tableLimit / 3
          } else if (item % 3 === 2) {
            colInicio = startX + tableLimit - tableLimit / 3
          } else {
            colInicio = startX
          }
          if (cursor + boxHeight > heightLimit) {
            doc.rect(startX, cursor, tableLimit, line, 'F')
            doc.addPage()
            cursor = margin + 5
            doc.rect(startX, cursor, tableLimit, line, 'F')
          }

          doc.text(
            relatorio.ListaNovilhasGestantes[item].nomeOuBrincoNovilhasGestante,
            colInicio + smallGutterX,
            cursor + smallGutterY,
          )
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          if (
            item % 3 === 2 ||
            Object.keys(relatorio.ListaNovilhasGestantes).length - 1 == item
          ) {
            doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
            doc.rect(tableLimit + startX, cursor, line, smallBoxHeight, 'F') // direita
            cursor += smallBoxHeight
            cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          }
        })
        cursor = checkAddPage(doc, cursor, boxHeight * 4, config)
        doc.rect(startX, cursor, tableLimit, line, 'F') // superior
      }

      if (
        relatorio.ListaNovilhasReavaliar &&
        relatorio.ListaNovilhasReavaliar.length != 0
      ) {
        doc.rect(startX, cursor, line, boxHeight, 'F') // esquerda
        doc.rect(startX + tableLimit, cursor, line, boxHeight, 'F') // direita
        cursor = checkAddPage(doc, cursor, boxHeight, config)
        doc.text(
          'Identificação das novilhas para reavaliar:',
          startX + tableLimit / 1000 + smallGutterX,
          cursor + smallGutterY,
        )

        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, boxHeight, config)

        Object.keys(relatorio.ListaNovilhasReavaliar).forEach(item => {
          if (item % 3 === 1) {
            colInicio = startX + tableLimit / 3
          } else if (item % 3 === 2) {
            colInicio = startX + tableLimit - tableLimit / 3
          } else {
            colInicio = startX
          }
          if (cursor + boxHeight > heightLimit) {
            doc.rect(startX, cursor, tableLimit, line, 'F')
            doc.addPage()
            cursor = margin + 5
            doc.rect(startX, cursor, tableLimit, line, 'F')
          }

          doc.text(
            relatorio.ListaNovilhasReavaliar[item].nomeOuBrincoNovilhaReavaliar,
            colInicio + smallGutterX,
            cursor + smallGutterY,
          )
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          if (
            item % 3 === 2 ||
            Object.keys(relatorio.ListaNovilhasReavaliar).length - 1 == item
          ) {
            doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
            doc.rect(tableLimit + startX, cursor, line, smallBoxHeight, 'F') // direita
            cursor += smallBoxHeight
            cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          }
        })
        cursor = checkAddPage(doc, cursor, boxHeight * 4, config)
        doc.rect(startX, cursor, tableLimit, line, 'F') // superior
      }

      if (
        relatorio.ListaNovilhasVaziasIATF &&
        relatorio.ListaNovilhasVaziasIATF.length != 0
      ) {
        doc.rect(startX, cursor, line, boxHeight, 'F') // esquerda
        doc.rect(startX + tableLimit, cursor, line, boxHeight, 'F') // direita
        cursor = checkAddPage(doc, cursor, boxHeight, config)
        doc.text(
          'Identificação das novilhas vazias que entraram no protocolo de IATF:',
          startX + tableLimit / 1000 + smallGutterX,
          cursor + smallGutterY,
        )

        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, boxHeight, config)

        Object.keys(relatorio.ListaNovilhasVaziasIATF).forEach(item => {
          if (item % 3 === 1) {
            colInicio = startX + tableLimit / 3
          } else if (item % 3 === 2) {
            colInicio = startX + tableLimit - tableLimit / 3
          } else {
            colInicio = startX
          }
          if (cursor + boxHeight > heightLimit) {
            doc.rect(startX, cursor, tableLimit, line, 'F')
            doc.addPage()
            cursor = margin + 5
            doc.rect(startX, cursor, tableLimit, line, 'F')
          }

          doc.text(
            relatorio.ListaNovilhasVaziasIATF[item]
              .nomeOuBrincoNovilhaVaziaIATF,
            colInicio + smallGutterX,
            cursor + smallGutterY,
          )
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          if (
            item % 3 === 2 ||
            Object.keys(relatorio.ListaNovilhasVaziasIATF).length - 1 == item
          ) {
            doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
            doc.rect(tableLimit + startX, cursor, line, smallBoxHeight, 'F') // direita
            cursor += smallBoxHeight
            cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          }
        })
        cursor = checkAddPage(doc, cursor, boxHeight * 4, config)
        doc.rect(startX, cursor, tableLimit, line, 'F') // superior
      }

      if (
        relatorio.ListaNovilhasVaziasPGF &&
        relatorio.ListaNovilhasVaziasPGF.length != 0
      ) {
        doc.rect(startX, cursor, line, boxHeight, 'F') // esquerda
        doc.rect(startX + tableLimit, cursor, line, boxHeight, 'F') // direita
        cursor = checkAddPage(doc, cursor, boxHeight, config)
        doc.text(
          'Identificação das novilhas vazias que foram sincronizadas no PGF:',
          startX + tableLimit / 1000 + smallGutterX,
          cursor + smallGutterY,
        )

        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, boxHeight, config)

        Object.keys(relatorio.ListaNovilhasVaziasPGF).forEach(item => {
          if (item % 3 === 1) {
            colInicio = startX + tableLimit / 3
          } else if (item % 3 === 2) {
            colInicio = startX + tableLimit - tableLimit / 3
          } else {
            colInicio = startX
          }
          if (cursor + boxHeight > heightLimit) {
            doc.rect(startX, cursor, tableLimit, line, 'F')
            doc.addPage()
            cursor = margin + 5
            doc.rect(startX, cursor, tableLimit, line, 'F')
          }

          doc.text(
            relatorio.ListaNovilhasVaziasPGF[item].nomeOuBrincoNovilhasVaziaPGF,
            colInicio + smallGutterX,
            cursor + smallGutterY,
          )
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          if (
            item % 3 === 2 ||
            Object.keys(relatorio.ListaNovilhasVaziasPGF).length - 1 == item
          ) {
            doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
            doc.rect(tableLimit + startX, cursor, line, smallBoxHeight, 'F') // direita
            cursor += smallBoxHeight
            cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          }
        })
        cursor = checkAddPage(doc, cursor, boxHeight * 4, config)
        doc.rect(startX, cursor, tableLimit, line, 'F') // superior
      }

      if (
        relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal &&
        relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal.length != 0
      ) {
        doc.rect(startX, cursor, line, boxHeight, 'F') // esquerda
        doc.rect(startX + tableLimit, cursor, line, boxHeight, 'F') // direita
        cursor = checkAddPage(doc, cursor, boxHeight, config)
        doc.text(
          'Identificação das novilhas vazias que precisam Recuperar o Escore de Condição Corporal(ECC):',
          startX + tableLimit / 1000 + smallGutterX,
          cursor + smallGutterY,
        )

        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, boxHeight, config)

        Object.keys(
          relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal,
        ).forEach(item => {
          if (item % 3 === 1) {
            colInicio = startX + tableLimit / 3
          } else if (item % 3 === 2) {
            colInicio = startX + tableLimit - tableLimit / 3
          } else {
            colInicio = startX
          }
          if (cursor + boxHeight > heightLimit) {
            doc.rect(startX, cursor, tableLimit, line, 'F')
            doc.addPage()
            cursor = margin + 5
            doc.rect(startX, cursor, tableLimit, line, 'F')
          }

          doc.text(
            relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal[item]
              .nomeOuBrincoNovilhaVaziaRecuperarCondicaoCorporal,
            colInicio + smallGutterX,
            cursor + smallGutterY,
          )
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          if (
            item % 3 === 2 ||
            Object.keys(relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal)
              .length -
              1 ==
              item
          ) {
            doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
            doc.rect(tableLimit + startX, cursor, line, smallBoxHeight, 'F') // direita
            cursor += smallBoxHeight
            cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          }
        })
        doc.rect(startX, cursor, tableLimit, line, 'F') // superior
        cursor = checkAddPage(doc, cursor, boxHeight * 2, config)
      }

      if (
        relatorio.ListaNovilhasVaziasTratamentoClinico &&
        relatorio.ListaNovilhasVaziasTratamentoClinico.length != 0
      ) {
        doc.rect(startX, cursor, line, boxHeight, 'F') // esquerda
        doc.rect(startX + tableLimit, cursor, line, boxHeight, 'F') // direita
        cursor = checkAddPage(doc, cursor, boxHeight, config)
        doc.text(
          'Identificação das novilhas vazias que precisam de tratamento clínico:',
          startX + tableLimit / 1000 + smallGutterX,
          cursor + smallGutterY,
        )

        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, boxHeight, config)

        Object.keys(relatorio.ListaNovilhasVaziasTratamentoClinico).forEach(
          item => {
            if (item % 3 === 1) {
              colInicio = startX + tableLimit / 3
            } else if (item % 3 === 2) {
              colInicio = startX + tableLimit - tableLimit / 3
            } else {
              colInicio = startX
            }
            if (cursor + boxHeight > heightLimit) {
              doc.rect(startX, cursor, tableLimit, line, 'F')
              doc.addPage()
              cursor = margin + 5
              doc.rect(startX, cursor, tableLimit, line, 'F')
            }

            doc.text(
              relatorio.ListaNovilhasVaziasTratamentoClinico[item]
                .nomeOuBrincoNovilhaVaziaTratamentoClinico,
              colInicio + smallGutterX,
              cursor + smallGutterY,
            )
            cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
            if (
              item % 3 === 2 ||
              Object.keys(relatorio.ListaNovilhasVaziasTratamentoClinico)
                .length -
                1 ==
                item
            ) {
              doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
              doc.rect(tableLimit + startX, cursor, line, smallBoxHeight, 'F') // direita
              cursor += smallBoxHeight
              cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
            }
          },
        )
        doc.rect(startX, cursor, tableLimit, line, 'F') // superior
        cursor = checkAddPage(doc, cursor, boxHeight * 4, config)
      }

      if (
        relatorio.ReprodutivoNovilhasAtividade1 ||
        relatorio.ReprodutivoNovilhasAtividade2 ||
        relatorio.ReprodutivoNovilhasAtividade3 ||
        relatorio.ReprodutivoNovilhasAtividade4 ||
        relatorio.ReprodutivoNovilhasAtividade5 ||
        relatorio.ReprodutivoNovilhasOutrasAtividades
      ) {
        doc.rect(startX, cursor, tableLimit, line, 'F')
        doc.setFont('helvetica', 'bold')
        renderCheckboxList(doc, ' • Atividades realizadas:', cursor, config)
        doc.setFont('helvetica', 'normal')
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        if (relatorio.ReprodutivoNovilhasAtividade1) {
          renderCheckboxList(
            doc,
            'Diagnóstico de gestação das novilhas',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.ReprodutivoNovilhasAtividade2) {
          renderCheckboxList(
            doc,
            'Treinamento para aumentar a detecção de cio',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.ReprodutivoNovilhasAtividade3) {
          renderCheckboxList(
            doc,
            'Treinamento para realizar inseminação artificial',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.ReprodutivoNovilhasAtividade4) {
          renderCheckboxList(
            doc,
            'Treinamento para realizar IATF',
            cursor,
            config,
          )
          cursor += smallBoxHeight
        }
        if (relatorio.ReprodutivoNovilhasAtividade5) {
          renderCheckboxList(
            doc,
            'Discussão dos indicadores reprodutivos',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.ReprodutivoNovilhasOutrasAtividades) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight * 3, config)
          doc.setFont('helvetica', 'bold')
          renderCheckboxList(doc, ' • Outras Atividades:', cursor, config)
          doc.setFont('helvetica', 'normal')
          cursor = renderRecomendacoes(
            doc,
            '',
            relatorio.ReprodutivoNovilhasOutrasAtividadesText,
            cursor,
            config,
          )
        }
        doc.rect(startX, cursor, tableLimit, line, 'F')
      }

      if (relatorio.separarTouroNovilhas) {
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        doc.setFont('helvetica', 'bold')
        renderCheckboxList(doc, ' • Recomendações:', cursor, config)
        doc.setFont('helvetica', 'normal')
        cursor += smallBoxHeight
        renderCheckboxList(doc, 'Separar o touro das novilhas', cursor, config)
        cursor += smallBoxHeight
        doc.rect(startX, cursor, tableLimit, line, 'F')
      }

      if (relatorio.OutrasRecomendacoesReprodutivoNovilhas) {
        cursor = checkAddPage(doc, cursor, smallBoxHeight * 3, config)
        doc.setFont('helvetica', 'bold')
        renderCheckboxList(doc, ' • Outras recomendações:', cursor, config)
        doc.setFont('helvetica', 'normal')
        cursor = renderRecomendacoes(
          doc,
          '',
          relatorio.OutrasRecomendacoesReprodutivoNovilhas,
          cursor,
          config,
        )
      }

      if (
        relatorio.ReprodutivoNovilhasObs &&
        relatorio.ReprodutivoNovilhasObs.length != 0
      ) {
        cursor = checkAddPage(doc, cursor, boxHeight, config)
        doc.rect(startX, cursor, tableLimit, line, 'F') // superior
        cursor = renderRecomendacoes(
          doc,
          'Atividade não realizada:',
          relatorio.ReprodutivoNovilhasObs,
          cursor,
          config,
        )
      }
    }
  }

  return cursor
}

function ajustesNutricionais(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config

  if (
    (relatorio.AjustesNutricionais &&
      (relatorio.CotacaoInsumoNutricional ||
        relatorio.PesagemLeiteApp ||
        relatorio.PesagemLeiteCaderno ||
        relatorio.RemanejamentoLotes ||
        relatorio.SeguirArracoamento ||
        relatorio.RecomendacaoNutricional ||
        relatorio.NutricionaisAtividade1 ||
        relatorio.NutricionaisAtividade2 ||
        relatorio.NutricionaisAtividade3 ||
        relatorio.NutricionaisAtividade4 ||
        relatorio.NutricionaisAtividade5 ||
        relatorio.NutricionaisAtividade6 ||
        relatorio.NutricionaisAtividade7 ||
        relatorio.NutricionaisOutrasAtividades)) ||
    relatorio.AjustesNutricionaisObs
  ) {
    cursor += config.boxHeight
    if (cursor + boxHeight * 2 > heightLimit) {
      doc.addPage()
      cursor = margin
    }
    cursor = checkAddPage(doc, cursor, boxHeight, config)
    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Ajustes nutricionais', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 5

    doc.rect(startX, cursor, tableLimit, line, 'F')

    if (relatorio.AjustesNutricionais) {
      if (
        relatorio.NutricionaisAtividade1 ||
        relatorio.NutricionaisAtividade2 ||
        relatorio.NutricionaisAtividade3 ||
        relatorio.NutricionaisAtividade4 ||
        relatorio.NutricionaisAtividade5 ||
        relatorio.NutricionaisAtividade6 ||
        relatorio.NutricionaisAtividade7 ||
        relatorio.NutricionaisOutrasAtividades
      ) {
        doc.setFont('helvetica', 'bold')
        renderCheckboxList(doc, ' • Atividades realizadas:', cursor, config)
        doc.setFont('helvetica', 'normal')
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        if (relatorio.NutricionaisAtividade1) {
          renderCheckboxList(
            doc,
            'Lançamento do controle leiteiro no sistema',
            cursor,
            config,
          )
          cursor += smallBoxHeight
        }
        if (
          relatorio.NutricionaisAtividade2 == '1' &&
          relatorio.NutricionaisAtividade2Text != ''
        ) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          renderCheckboxList(
            doc,
            '• Avaliação do escore de cocho:',
            cursor,
            config,
          )
          cursor += smallBoxHeight - 7
          renderCheckboxList(
            doc,
            '  ' + relatorio.NutricionaisAtividade2Text.substr(0, 115),
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }

        if (
          relatorio.NutricionaisAtividade3 == '1' &&
          relatorio.NutricionaisAtividade3Text != ''
        ) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          renderCheckboxList(
            doc,
            '• Avaliação da estrutura de alimentação (espaço de cocho/animal, condições físicas das estruturas):',
            cursor,
            config,
          )
          cursor += smallBoxHeight - 7
          renderCheckboxList(
            doc,
            '  ' + relatorio.NutricionaisAtividade3Text.substr(0, 115),
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }

        if (
          relatorio.NutricionaisAtividade4 == '1' &&
          relatorio.NutricionaisAtividade4Text != ''
        ) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          renderCheckboxList(doc, '• Análise da dieta atual:', cursor, config)
          cursor += smallBoxHeight - 7
          renderCheckboxList(
            doc,
            '  ' + relatorio.NutricionaisAtividade4Text.substr(0, 115),
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }

        if (
          relatorio.NutricionaisAtividade5 == '1' &&
          relatorio.NutricionaisAtividade5Text != ''
        ) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          renderCheckboxList(
            doc,
            '• Divisão de lotes (vacas em lactação):',
            cursor,
            config,
          )
          cursor += smallBoxHeight - 7
          renderCheckboxList(
            doc,
            '  ' + relatorio.NutricionaisAtividade5Text.substr(0, 115),
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }

        if (
          relatorio.NutricionaisAtividade6 == '1' &&
          relatorio.NutricionaisAtividade6Text != ''
        ) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          renderCheckboxList(
            doc,
            '• Divisão de lotes (recria):',
            cursor,
            config,
          )
          cursor += smallBoxHeight - 7
          renderCheckboxList(
            doc,
            '  ' + relatorio.NutricionaisAtividade6Text.substr(0, 115),
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }

        if (
          relatorio.NutricionaisAtividade7 == '1' &&
          relatorio.NutricionaisAtividade7Text != ''
        ) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          renderCheckboxList(doc, '• Balanceamento da dieta:', cursor, config)
          cursor += smallBoxHeight - 7
          renderCheckboxList(
            doc,
            '  ' + relatorio.NutricionaisAtividade7Text.substr(0, 115),
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.NutricionaisOutrasAtividades) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight * 3, config)
          doc.setFont('helvetica', 'bold')
          renderCheckboxList(doc, ' • Outras Atividades:', cursor, config)
          doc.setFont('helvetica', 'normal')
          cursor = renderRecomendacoes(
            doc,
            '',
            relatorio.NutricionaisOutrasAtividadesText,
            cursor,
            config,
          )
        }
        doc.rect(startX, cursor, tableLimit, line, 'F')
      }

      if (
        relatorio.CotacaoInsumoNutricional ||
        relatorio.PesagemLeiteApp ||
        relatorio.PesagemLeiteCaderno ||
        relatorio.RemanejamentoLotes ||
        relatorio.SeguirArracoamento ||
        relatorio.RecomendacaoNutricional
      ) {
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        doc.setFont('helvetica', 'bold')
        renderCheckboxList(doc, ' • Recomendações:', cursor, config)
        doc.setFont('helvetica', 'normal')
        cursor += smallBoxHeight

        if (relatorio.CotacaoInsumoNutricional) {
          renderCheckboxList(
            doc,
            'Fazer cotação de insumos para custo da dieta',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.PesagemLeiteApp) {
          renderCheckboxList(
            doc,
            'Realizar a pesagem mensal de leite e lançar a pesagem no aplicativo',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.PesagemLeiteCaderno) {
          renderCheckboxList(
            doc,
            'Realizar a pesagem mensal de leite e lançar a pesagem no caderno zootécnico',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.RemanejamentoLotes) {
          renderCheckboxList(
            doc,
            'Realizar o remanejamento dos lotes conforme listagem em anexo',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.SeguirArracoamento) {
          renderCheckboxList(
            doc,
            'Seguir arraçoamento proposto em anexo',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.RecomendacaoNutricional) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight * 3, config)
          doc.setFont('helvetica', 'bold')
          renderCheckboxList(doc, ' • Outras recomendações:', cursor, config)
          doc.setFont('helvetica', 'normal')
          cursor = renderRecomendacoes(
            doc,
            '',
            relatorio.RecomendacaoNutricional,
            cursor,
            config,
          )
        }
        doc.rect(startX, cursor, tableLimit, line, 'F')
      }
    } else {
      cursor = renderRecomendacoes(
        doc,
        'Atividade não realizada',
        relatorio.AjustesNutricionaisObs,
        cursor,
        config,
      )
    }
  }

  return cursor
}

function planejamentoVolumoso(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config

  if (
    relatorio.CotacaoInsumoVolumoso ||
    relatorio.ColetaDoSolo ||
    relatorio.AmostrasAnalise ||
    relatorio.LaudoAnalise ||
    relatorio.RecomendacaoVolumoso ||
    relatorio.PlanejamentoVolumosoObs ||
    relatorio.VolumosoAtividade1 ||
    relatorio.VolumosoAtividade2 ||
    relatorio.VolumosoAtividade3 ||
    relatorio.VolumosoAtividade4 ||
    relatorio.VolumosoAtividade5 ||
    relatorio.VolumosoOutrasAtividades
  ) {
    cursor += config.boxHeight
    if (cursor + boxHeight * 2 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Planejamento de volumoso', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 5

    doc.rect(startX, cursor, tableLimit, line, 'F')

    if (relatorio.PlanejamentoVolumoso) {
      if (
        relatorio.VolumosoAtividade1 ||
        relatorio.VolumosoAtividade2 ||
        relatorio.VolumosoAtividade3 ||
        relatorio.VolumosoAtividade4 ||
        relatorio.VolumosoAtividade5 ||
        relatorio.VolumosoOutrasAtividades
      ) {
        doc.setFont('helvetica', 'bold')
        renderCheckboxList(doc, ' • Atividades realizadas:', cursor, config)
        doc.setFont('helvetica', 'normal')
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        if (
          relatorio.VolumosoAtividade1 == '1' &&
          relatorio.VolumosoAtividade1Text != ''
        ) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          renderCheckboxList(doc, '• Evolução do rebanho:', cursor, config)
          cursor += smallBoxHeight - 7
          renderCheckboxList(
            doc,
            '  ' + relatorio.VolumosoAtividade1Text.substr(0, 115),
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }

        if (
          relatorio.VolumosoAtividade2 == '1' &&
          relatorio.VolumosoAtividade2Text != ''
        ) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          renderCheckboxList(
            doc,
            '• Levantamento do estoque atual de volumoso:',
            cursor,
            config,
          )
          cursor += smallBoxHeight - 7
          renderCheckboxList(
            doc,
            '  ' + relatorio.VolumosoAtividade2Text.substr(0, 115),
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }

        if (
          relatorio.VolumosoAtividade3 == '1' &&
          relatorio.VolumosoAtividade3Text != ''
        ) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          renderCheckboxList(
            doc,
            '• Levantamento do tamanho das áreas para produção de volumoso:',
            cursor,
            config,
          )
          cursor += smallBoxHeight - 7
          renderCheckboxList(
            doc,
            '  ' + relatorio.VolumosoAtividade3Text.substr(0, 115),
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }

        if (
          relatorio.VolumosoAtividade4 == '1' &&
          relatorio.VolumosoAtividade4Text != ''
        ) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          renderCheckboxList(
            doc,
            '• Coleta de solo das áreas de plantio para análise:',
            cursor,
            config,
          )
          cursor += smallBoxHeight - 7
          renderCheckboxList(
            doc,
            '  ' + relatorio.VolumosoAtividade4Text.substr(0, 115),
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }

        if (
          relatorio.VolumosoAtividade5 == '1' &&
          relatorio.VolumosoAtividade5Text != ''
        ) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          renderCheckboxList(doc, '• Plano alimentar:', cursor, config)
          cursor += smallBoxHeight - 7
          renderCheckboxList(
            doc,
            '  ' + relatorio.VolumosoAtividade5Text.substr(0, 115),
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.VolumosoOutrasAtividades) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight * 3, config)
          doc.setFont('helvetica', 'bold')
          renderCheckboxList(doc, ' • Outras Atividades:', cursor, config)
          doc.setFont('helvetica', 'normal')
          cursor = renderRecomendacoes(
            doc,
            '',
            relatorio.VolumosoOutrasAtividadesText,
            cursor,
            config,
          )
        }
        doc.rect(startX, cursor, tableLimit, line, 'F')
      }

      if (
        relatorio.ColetaDoSolo ||
        relatorio.AmostrasAnalise ||
        relatorio.LaudoAnalise ||
        relatorio.CotacaoInsumoVolumoso ||
        relatorio.RecomendacaoVolumoso
      ) {
        doc.setFont('helvetica', 'bold')
        renderCheckboxList(doc, ' • Recomendações:', cursor, config)
        doc.setFont('helvetica', 'normal')
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        if (relatorio.ColetaDoSolo) {
          renderCheckboxList(
            doc,
            'Realizar coleta de solo das áreas de plantio para análise',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.AmostrasAnalise) {
          renderCheckboxList(
            doc,
            'Enviar amostras para análise',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.LaudoAnalise) {
          renderCheckboxList(
            doc,
            'Enviar o laudo da análise assim que estiver pronto',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.CotacaoInsumoVolumoso) {
          renderCheckboxList(
            doc,
            'Fazer cotação de insumos para plantio',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.RecomendacaoVolumoso) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight * 3, config)
          doc.setFont('helvetica', 'bold')
          renderCheckboxList(doc, ' • Outras recomendações:', cursor, config)
          doc.setFont('helvetica', 'normal')
          cursor = renderRecomendacoes(
            doc,
            '',
            relatorio.RecomendacaoVolumoso,
            cursor,
            config,
          )
        }
        doc.rect(startX, cursor, tableLimit, line, 'F')
      }
    } else {
      cursor = renderRecomendacoes(
        doc,
        'Atividade não realizada',
        relatorio.PlanejamentoVolumosoObs,
        cursor,
        config,
      )
    }
  }

  return cursor
}

function manejoSanitario(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
    smallGutterX,
    smallGutterY,
  } = config
  if (
    relatorio?.Sanitario &&
    ((relatorio?.Vacinas && relatorio?.Vacinas?.length != 0) ||
      (relatorio?.Pediluvio && relatorio?.Pediluvio?.length != 0) ||
      (relatorio?.Vermifucacao && relatorio?.Vermifucacao?.length != 0) ||
      (relatorio?.ControleCarrapatos &&
        relatorio?.ControleCarrapatos?.length != 0) ||
      relatorio?.OutrasRecomendacoesManejoSanitario != '')
  ) {
    cursor += config.boxHeight
    if (cursor + boxHeight * 4 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Manejo sanitário', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    var colInicio = startX

    cursor += smallBoxHeight - 5

    doc.rect(startX, cursor, tableLimit, line, 'F')

    if (relatorio.Sanitario) {
      if (relatorio.Vacinas && relatorio.Vacinas.length != 0) {
        doc.rect(startX, cursor, line, boxHeight, 'F') // esquerda
        doc.rect(startX + tableLimit, cursor, line, boxHeight, 'F') // direita

        doc.text(
          'Vacinas: ',
          startX + tableLimit / 1000 + smallGutterX,
          cursor + smallGutterY,
        )

        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, boxHeight, config)

        Object.keys(relatorio.Vacinas).forEach(item => {
          colInicio = startX + tableLimit / 1000 + smallGutterX

          doc.text(
            'Nome: ' +
              relatorio.Vacinas[item].msMomeVacina +
              ' | Dosagem: ' +
              relatorio.Vacinas[item].msDosagemVacina +
              ' | Categoria: ' +
              relatorio.Vacinas[item].msCategoriaVacina,
            colInicio + smallGutterX,
            cursor + smallGutterY,
          )
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

          Object.keys(relatorio.Vacinas).length - 1 == item

          doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
          doc.rect(tableLimit + startX, cursor, line, smallBoxHeight, 'F') // direita
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        })
        cursor = checkAddPage(doc, cursor, boxHeight * 4, config)
        doc.rect(startX, cursor, tableLimit, line, 'F') // superior
      }

      if (relatorio.Pediluvio && relatorio.Pediluvio.length != 0) {
        doc.rect(startX, cursor, line, boxHeight, 'F') // esquerda
        doc.rect(startX + tableLimit, cursor, line, boxHeight, 'F') // direita

        doc.text(
          'Manter utilização do pedilúvio: ',
          startX + tableLimit / 1000 + smallGutterX,
          cursor + smallGutterY,
        )

        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, boxHeight, config)

        Object.keys(relatorio.Pediluvio).forEach(item => {
          colInicio = startX + tableLimit / 1000 + smallGutterX

          doc.text(
            'Produto: ' +
              relatorio.Pediluvio[item].msNomeProdutoPediluvio +
              ' | Frequência: ' +
              relatorio.Pediluvio[item].msFrequenciaPediluvio +
              ' | Concentração: ' +
              relatorio.Pediluvio[item].msConcentracaoPediluvio,
            colInicio + smallGutterX,
            cursor + smallGutterY,
          )

          cursor = checkAddPage(doc, cursor, boxHeight, config)

          Object.keys(relatorio.Pediluvio).length - 1 == item

          doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
          doc.rect(tableLimit + startX, cursor, line, smallBoxHeight, 'F') // direita
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        })
        doc.rect(startX, cursor, tableLimit, line, 'F') // superior
        cursor = checkAddPage(doc, cursor, boxHeight * 4, config)
      }

      if (relatorio.Vermifucacao && relatorio.Vermifucacao.length != 0) {
        doc.rect(startX, cursor, line, boxHeight, 'F') // esquerda
        doc.rect(startX + tableLimit, cursor, line, boxHeight, 'F') // direita

        doc.text(
          'Vermifugação: ',
          startX + tableLimit / 1000 + smallGutterX,
          cursor + smallGutterY,
        )

        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, boxHeight, config)

        Object.keys(relatorio.Vermifucacao).forEach(item => {
          colInicio = startX + tableLimit / 1000 + smallGutterX

          doc.text(
            'Produto: ' +
              relatorio.Vermifucacao[item].msNomeProdutoVermifugacao +
              ' | Dosagem: ' +
              relatorio.Vermifucacao[item].msDosagemVermifugacao +
              ' | Via de aplicação: ' +
              relatorio.Vermifucacao[item].msViaAplicacaoVermifugacao,
            colInicio + smallGutterX,
            cursor + smallGutterY,
          )

          cursor = checkAddPage(doc, cursor, boxHeight, config)

          Object.keys(relatorio.Vermifucacao).length - 1 == item

          doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
          doc.rect(tableLimit + startX, cursor, line, smallBoxHeight, 'F') // direita
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        })
        doc.rect(startX, cursor, tableLimit, line, 'F') // superior
        cursor = checkAddPage(doc, cursor, boxHeight * 4, config)
      }

      if (
        relatorio.ControleCarrapatos &&
        relatorio.ControleCarrapatos.length != 0
      ) {
        doc.rect(startX, cursor, line, boxHeight, 'F') // esquerda
        doc.rect(startX + tableLimit, cursor, line, boxHeight, 'F') // direita

        doc.text(
          'Carrapaticidas: ',
          startX + tableLimit / 1000 + smallGutterX,
          cursor + smallGutterY,
        )

        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, boxHeight, config)

        Object.keys(relatorio.ControleCarrapatos).forEach(item => {
          colInicio = startX + tableLimit / 1000 + smallGutterX

          doc.text(
            'Produto: ' +
              relatorio.ControleCarrapatos[item].msNomeProdutoCarrapaticida +
              ' | Dosagem: ' +
              relatorio.ControleCarrapatos[item].msDosagemCarrapaticida +
              ' | Via de aplicação: ' +
              relatorio.ControleCarrapatos[item].msViaAplicacaoCarrapaticida,
            colInicio + smallGutterX,
            cursor + smallGutterY,
          )
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

          Object.keys(relatorio.ControleCarrapatos).length - 1 == item

          doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
          doc.rect(tableLimit + startX, cursor, line, smallBoxHeight, 'F') // direita
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        })
        doc.rect(startX, cursor, tableLimit, line, 'F') // superior
        cursor = checkAddPage(doc, cursor, boxHeight * 4, config)
      }

      if (
        relatorio.ManejoSanitarioAtividade1 ||
        relatorio.ManejoSanitarioAtividade2 ||
        relatorio.ManejoSanitarioAtividade3 ||
        relatorio.ManejoSanitarioAtividade3Text ||
        relatorio.ManejoSanitarioAtividade4 ||
        relatorio.ManejoSanitarioAtividade4Text ||
        relatorio.ManejoSanitarioOutrasAtividades ||
        relatorio.ManejoSanitarioOutrasAtividadesText
      ) {
        doc.rect(startX, cursor, tableLimit, line, 'F')
        doc.setFont('helvetica', 'bold')
        renderCheckboxList(doc, ' • Atividades realizadas:', cursor, config)
        doc.setFont('helvetica', 'normal')
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        if (
          relatorio.ManejoSanitarioAtividade1 == '1' &&
          relatorio.ManejoSanitarioAtividade1Text != ''
        ) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          renderCheckboxList(
            doc,
            '• Implantação do calendário sanitário:',
            cursor,
            config,
          )
          cursor += smallBoxHeight - 7
          renderCheckboxList(
            doc,
            '  ' + relatorio.ManejoSanitarioAtividade1Text.substr(0, 115),
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }

        if (
          relatorio.ManejoSanitarioAtividade2 == '1' &&
          relatorio.ManejoSanitarioAtividade2Text != ''
        ) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          renderCheckboxList(
            doc,
            '• Acompanhamento do calendário sanitário:',
            cursor,
            config,
          )
          cursor += smallBoxHeight - 7
          renderCheckboxList(
            doc,
            '  ' + relatorio.ManejoSanitarioAtividade2Text.substr(0, 115),
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }

        if (
          relatorio.ManejoSanitarioAtividade3 == '1' &&
          relatorio.ManejoSanitarioAtividade3Text != ''
        ) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          renderCheckboxList(doc, '• Realização da vacinação:', cursor, config)
          cursor += smallBoxHeight - 7
          renderCheckboxList(
            doc,
            '  ' + relatorio.ManejoSanitarioAtividade3Text.substr(0, 115),
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (
          relatorio.ManejoSanitarioAtividade4 == '1' &&
          relatorio.ManejoSanitarioAtividade4Text != ''
        ) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)
          renderCheckboxList(
            doc,
            '• Planos de ação para controle de doenças:',
            cursor,
            config,
          )
          cursor = renderRecomendacoes(
            doc,
            '',
            relatorio.ManejoSanitarioAtividade4Text,
            cursor,
            config,
          )
        }
        if (relatorio.ManejoSanitarioOutrasAtividades) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight * 3, config)
          doc.setFont('helvetica', 'bold')
          renderCheckboxList(doc, ' • Outras Atividades:', cursor, config)
          doc.setFont('helvetica', 'normal')
          cursor = renderRecomendacoes(
            doc,
            '',
            relatorio.ManejoSanitarioOutrasAtividadesText,
            cursor,
            config,
          )
        }
        doc.rect(startX, cursor, tableLimit, line, 'F')
      }
      if (relatorio.OutrasRecomendacoesManejoSanitario) {
        cursor = checkAddPage(doc, cursor, smallBoxHeight * 3, config)
        doc.setFont('helvetica', 'bold')
        renderCheckboxList(doc, ' • Outras recomendações:', cursor, config)
        doc.setFont('helvetica', 'normal')
        cursor = renderRecomendacoes(
          doc,
          '',
          relatorio.OutrasRecomendacoesManejoSanitario,
          cursor,
          config,
        )
      }
    } else {
      cursor = renderRecomendacoes(
        doc,
        'Atividade não realizada',
        relatorio.SanitarioObs,
        cursor,
        config,
      )
    }
    cursor = checkAddPage(doc, cursor, boxHeight, config)
  }
  return cursor
}

function acompanhamentoOrdenha(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config

  if (
    (relatorio.Ordenha &&
      (relatorio.RealizarLinhaOrdenha ||
        relatorio.TesteCaneca ||
        relatorio.PreDippingOrdenha ||
        (relatorio.PapelToalha && relatorio.PapelToalha == 1) ||
        relatorio.PosDipping ||
        relatorio.ComprarTermometro ||
        relatorio.ProcedimentoOrdenha ||
        relatorio.ColetaLeiteCCS ||
        relatorio.ColetaLeiteMicrobiologica ||
        relatorio.UtilizarAntibioticoSecas ||
        relatorio.UtilizarSelanteIntramamario ||
        relatorio.ManterAreaDescanso ||
        relatorio.CercarAreaDescanso ||
        relatorio.RecomendacaoOrdenha ||
        relatorio.AcompanhamentoOrdenhaAtividade1 ||
        relatorio.AcompanhamentoOrdenhaAtividade2 ||
        relatorio.AcompanhamentoOrdenhaAtividade3 ||
        relatorio.AcompanhamentoOrdenhaAtividade4 ||
        relatorio.AcompanhamentoOrdenhaAtividade5 ||
        relatorio.AcompanhamentoOrdenhaOutrasAtividades)) ||
    relatorio.OrdenhaObs
  ) {
    cursor += config.boxHeight
    if (cursor + boxHeight * 2 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    if (relatorio.Ordenha) {
      doc
        .setFont('helvetica', 'bold')
        .setFontSize(mediumFontSize)
        .text('Recomendações - Rotina de ordenha', startX, cursor)
        .setFont('helvetica', 'normal')
        .setFontSize(fontSize)

      cursor += smallBoxHeight - 5

      doc.rect(startX, cursor, tableLimit, line, 'F')

      if (
        relatorio.AcompanhamentoOrdenhaAtividade1 ||
        relatorio.AcompanhamentoOrdenhaAtividade2 ||
        relatorio.AcompanhamentoOrdenhaAtividade3 ||
        relatorio.AcompanhamentoOrdenhaAtividade4 ||
        relatorio.AcompanhamentoOrdenhaAtividade5 ||
        relatorio.AcompanhamentoOrdenhaOutrasAtividades
      ) {
        doc.rect(startX, cursor, tableLimit, line, 'F')
        doc.setFont('helvetica', 'bold')
        renderCheckboxList(doc, ' • Atividades realizadas:', cursor, config)
        doc.setFont('helvetica', 'normal')
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        if (
          relatorio.AcompanhamentoOrdenhaAtividade1 == '1' &&
          relatorio.AcompanhamentoOrdenhaAtividade1Text != ''
        ) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          renderCheckboxList(
            doc,
            '• Vistoria das áreas de descanso dos animais:',
            cursor,
            config,
          )
          cursor += smallBoxHeight - 7
          renderCheckboxList(
            doc,
            '  ' + relatorio.AcompanhamentoOrdenhaAtividade1Text.substr(0, 115),
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }

        if (
          relatorio.AcompanhamentoOrdenhaAtividade2 == '1' &&
          relatorio.AcompanhamentoOrdenhaAtividade2Text != ''
        ) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          renderCheckboxList(
            doc,
            '• Acompanhamento dos procedimentos de ordenha:',
            cursor,
            config,
          )
          cursor += smallBoxHeight - 7
          renderCheckboxList(
            doc,
            '  ' + relatorio.AcompanhamentoOrdenhaAtividade2Text.substr(0, 115),
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }

        if (
          relatorio.AcompanhamentoOrdenhaAtividade3 == '1' &&
          relatorio.AcompanhamentoOrdenhaAtividade3Text != ''
        ) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          renderCheckboxList(
            doc,
            '• Realização do teste de CMT:',
            cursor,
            config,
          )
          cursor += smallBoxHeight - 7
          renderCheckboxList(
            doc,
            '  ' + relatorio.AcompanhamentoOrdenhaAtividade3Text.substr(0, 115),
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }

        if (
          relatorio.AcompanhamentoOrdenhaAtividade4 == '1' &&
          relatorio.AcompanhamentoOrdenhaAtividade4Text != ''
        ) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          renderCheckboxList(
            doc,
            '• Acompanhamento da limpeza dos equipamentos:',
            cursor,
            config,
          )
          cursor += smallBoxHeight - 7
          renderCheckboxList(
            doc,
            '  ' + relatorio.AcompanhamentoOrdenhaAtividade4Text.substr(0, 115),
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }

        if (
          relatorio.AcompanhamentoOrdenhaAtividade5 == '1' &&
          relatorio.AcompanhamentoOrdenhaAtividade5Text != ''
        ) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          renderCheckboxList(
            doc,
            '• Aferição da temperatura do tanque:',
            cursor,
            config,
          )
          cursor += smallBoxHeight - 7
          renderCheckboxList(
            doc,
            '  ' + relatorio.AcompanhamentoOrdenhaAtividade5Text.substr(0, 115),
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }

        if (
          relatorio.AcompanhamentoOrdenhaOutrasAtividades &&
          relatorio.AcompanhamentoOrdenhaOutrasAtividadesText != ''
        ) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight * 3, config)
          doc.setFont('helvetica', 'bold')
          renderCheckboxList(doc, ' • Outras Atividades:', cursor, config)
          doc.setFont('helvetica', 'normal')
          cursor = renderRecomendacoes(
            doc,
            '',
            relatorio.AcompanhamentoOrdenhaOutrasAtividadesText,
            cursor,
            config,
          )
        }
        doc.rect(startX, cursor, tableLimit, line, 'F')
      }

      if (
        relatorio.RealizarLinhaOrdenha ||
        relatorio.PreDippingOrdenha ||
        relatorio.PapelToalha ||
        relatorio.PosDipping ||
        relatorio.ComprarTermometro ||
        relatorio.ProcedimentoOrdenha ||
        relatorio.ColetaLeiteCCS ||
        relatorio.ColetaLeiteMicrobiologica ||
        relatorio.UtilizarAntibioticoSecas ||
        relatorio.UtilizarSelanteIntramamario ||
        relatorio.ManterAreaDescanso ||
        relatorio.CercarAreaDescanso ||
        relatorio.RecomendacaoOrdenha
      ) {
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        doc.setFont('helvetica', 'bold')
        renderCheckboxList(doc, ' • Recomendações:', cursor, config)
        doc.setFont('helvetica', 'normal')
        cursor += smallBoxHeight
        if (relatorio.RealizarLinhaOrdenha) {
          renderCheckboxList(
            doc,
            'Realizar linha de ordenha proposta em anexo',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.TesteCaneca) {
          renderCheckboxList(
            doc,
            'Fazer o teste da caneca em todas as ordenhas',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.PreDippingOrdenha && relatorio.PreDippingOrdenha == 1) {
          renderCheckboxList(
            doc,
            'Realizar a aplicação do pré dipping em todas as vacas',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.PapelToalha) {
          renderCheckboxList(
            doc,
            'Utilizar papel toalha para secagem dos tetos',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.PosDipping) {
          renderCheckboxList(
            doc,
            'Realizar a aplicação do pós dipping em todas as vacas',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.ComprarTermometro) {
          renderCheckboxList(
            doc,
            'Comprar termômetro para aferição da temperatura da água (70°C)',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.ProcedimentoOrdenha) {
          renderCheckboxList(
            doc,
            'Seguir procedimentos de ordenha conforme procedimento operacional padrão em anexo',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.ColetaLeiteCCS) {
          renderCheckboxList(
            doc,
            'Realizar coleta de leite para análise individual da CCS',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.ColetaLeiteMicrobiologica) {
          renderCheckboxList(
            doc,
            'Realizar coleta de leite para análise microbiológica do leite',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.UtilizarAntibioticoSecas) {
          renderCheckboxList(
            doc,
            'Utilizar antibiótico vaca seca para secagem das vacas (indicação do produto)',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.UtilizarSelanteIntramamario) {
          renderCheckboxList(
            doc,
            'Utilizar selante intramamário para secagem das vacas (indicação do produto)',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.ManterAreaDescanso) {
          renderCheckboxList(
            doc,
            'Manter limpas as áreas de descanso das vacas',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.CercarAreaDescanso) {
          renderCheckboxList(
            doc,
            'Cercar as áreas de descanso com acúmulo de barro',
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.RecomendacaoOrdenha) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight * 3, config)
          doc.setFont('helvetica', 'bold')
          renderCheckboxList(doc, ' • Outras recomendações:', cursor, config)
          doc.setFont('helvetica', 'normal')
          cursor = renderRecomendacoes(
            doc,
            '',
            relatorio.RecomendacaoOrdenha,
            cursor,
            config,
          )
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        doc.rect(startX, cursor, tableLimit, line, 'F')
      }
      if (relatorio.OrdenhaObs) {
        doc.rect(startX, cursor, tableLimit, line, 'F')
        cursor = renderRecomendacoes(
          doc,
          'Atividade não realizada',
          relatorio.OrdenhaObs,
          cursor,
          config,
        )
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        cursor += smallBoxHeight
      }
    }
  }

  return cursor
}

function criaRecria(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    smallGutterX,
    smallGutterY,
    heightLimit,
    margin,
  } = config

  if (
    relatorio?.CriaRecria &&
    (relatorio?.NovilhasComPeso?.length != 0 ||
      relatorio?.BezerrasDesmamar?.length != 0 ||
      relatorio?.PesagemRecria ||
      relatorio?.OutrasRecomendacoesCriaRecria != '' ||
      relatorio.CriaRecriaAtividade1 ||
      relatorio.CriaRecriaAtividade2 ||
      relatorio.CriaRecriaAtividade3 ||
      relatorio.CriaRecriaAtividade4 ||
      relatorio.CriaRecriaAtividade5 ||
      relatorio.CriaRecriaOutrasAtividades)
  ) {
    cursor += config.boxHeight
    if (cursor + boxHeight * 2 > heightLimit) {
      doc.addPage()
      cursor = margin
    }
    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Acompanhamento da cria/recria', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight
    cursor = checkAddPage(doc, cursor, boxHeight * 2, config)

    var colInicio = startX

    doc.rect(startX, cursor, tableLimit, line, 'F')

    if (relatorio.CriaRecria == '1') {
      if (relatorio.NovilhasComPeso && relatorio.NovilhasComPeso.length != 0) {
        doc.rect(startX, cursor, line, boxHeight, 'F') // esquerda
        doc.rect(startX + tableLimit, cursor, line, boxHeight, 'F') // direita

        doc.text(
          'Novilhas aptas à inseminação:',
          startX + tableLimit / 1000 + smallGutterX,
          cursor + smallGutterY,
        )

        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, boxHeight, config)

        Object.keys(relatorio.NovilhasComPeso).forEach(item => {
          if (item % 3 === 1) {
            colInicio = startX + tableLimit / 3
          } else if (item % 3 === 2) {
            colInicio = startX + tableLimit - tableLimit / 3
          } else {
            colInicio = startX
          }

          doc.text(
            relatorio.NovilhasComPeso[item].acrNomeOuBrinco.substr(0, 35),
            colInicio + smallGutterX,
            cursor + smallGutterY,
          )
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          if (
            item % 3 === 2 ||
            Object.keys(relatorio.NovilhasComPeso).length - 1 == item
          ) {
            doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
            doc.rect(tableLimit + startX, cursor, line, smallBoxHeight, 'F') // direita
            cursor += smallBoxHeight
            cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          }
        })
        doc.rect(startX, cursor, tableLimit, line, 'F') // superior
      }

      if (
        relatorio.BezerrasDesmamar &&
        relatorio.BezerrasDesmamar.length != 0
      ) {
        cursor = checkAddPage(doc, cursor, smallBoxHeight * 2, config)
        doc.rect(startX, cursor, line, boxHeight, 'F') // esquerda
        doc.rect(startX + tableLimit, cursor, line, boxHeight, 'F') // direita

        doc.text(
          'Bezerras a desmamar:',
          startX + tableLimit / 1000 + smallGutterX,
          cursor + smallGutterY,
        )

        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, boxHeight, config)

        Object.keys(relatorio.BezerrasDesmamar).forEach(item => {
          if (item % 3 === 1) {
            colInicio = startX + tableLimit / 3
          } else if (item % 3 === 2) {
            colInicio = startX + tableLimit - tableLimit / 3
          } else {
            colInicio = startX
          }

          if (cursor + boxHeight > heightLimit) {
            doc.rect(startX, cursor, tableLimit, line, 'F')
            doc.addPage()
            cursor = margin + 5
            doc.rect(startX, cursor, tableLimit, line, 'F')
          }

          doc.text(
            relatorio.BezerrasDesmamar[item].acrBezerrasDesmamarNomeOuBrinco,
            colInicio + smallGutterX,
            cursor + smallGutterY,
          )
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          if (
            item % 3 === 2 ||
            Object.keys(relatorio.BezerrasDesmamar).length - 1 == item
          ) {
            doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
            doc.rect(tableLimit + startX, cursor, line, smallBoxHeight, 'F') // direita
            cursor += smallBoxHeight
            cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          }
        })

        doc.rect(startX, cursor, tableLimit, line, 'F') // superior
      }

      if (
        relatorio.CriaRecriaAtividade1 ||
        relatorio.CriaRecriaAtividade2 ||
        relatorio.CriaRecriaAtividade3 ||
        relatorio.CriaRecriaAtividade4 ||
        relatorio.CriaRecriaAtividade5 ||
        relatorio.CriaRecriaOutrasAtividades
      ) {
        doc.rect(startX, cursor, tableLimit, line, 'F')
        doc.setFont('helvetica', 'bold')
        renderCheckboxList(doc, ' • Atividades realizadas:', cursor, config)
        doc.setFont('helvetica', 'normal')
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        if (
          relatorio.CriaRecriaAtividade1 == '1' &&
          relatorio.CriaRecriaAtividade1Text != ''
        ) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          renderCheckboxList(
            doc,
            '• Acompanhamento dos procedimentos de cria das bezerras (rotina do bezerreiro):',
            cursor,
            config,
          )
          cursor += smallBoxHeight - 7
          renderCheckboxList(
            doc,
            '  ' + relatorio.CriaRecriaAtividade1Text.substr(0, 115),
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }

        if (
          relatorio.CriaRecriaAtividade2 == '1' &&
          relatorio.CriaRecriaAtividade2Text != ''
        ) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          renderCheckboxList(
            doc,
            '• Vistoria dos piquetes de recria:',
            cursor,
            config,
          )
          cursor += smallBoxHeight - 7
          renderCheckboxList(
            doc,
            '  ' + relatorio.CriaRecriaAtividade2Text.substr(0, 115),
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }

        if (
          relatorio.CriaRecriaAtividade3 == '1' &&
          relatorio.CriaRecriaAtividade3Text != ''
        ) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          renderCheckboxList(doc, '• Pesagem dos animais:', cursor, config)
          cursor += smallBoxHeight - 7
          renderCheckboxList(
            doc,
            '  ' + relatorio.CriaRecriaAtividade3Text.substr(0, 115),
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }

        if (
          relatorio.CriaRecriaAtividade4 == '1' &&
          relatorio.CriaRecriaAtividade4Text != ''
        ) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          renderCheckboxList(
            doc,
            '• Discussão dos indicadores de cria e recria de bezerras:',
            cursor,
            config,
          )
          cursor += smallBoxHeight - 7
          renderCheckboxList(
            doc,
            '  ' + relatorio.CriaRecriaAtividade4Text.substr(0, 115),
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (
          relatorio.CriaRecriaAtividade5 == '1' &&
          relatorio.CriaRecriaAtividade5Text != ''
        ) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          renderCheckboxList(
            doc,
            '• Planos de ação para controle de doenças específicas de bezerras:',
            cursor,
            config,
          )
          cursor += smallBoxHeight - 7
          renderCheckboxList(
            doc,
            '  ' + relatorio.CriaRecriaAtividade5Text.substr(0, 115),
            cursor,
            config,
          )
          cursor += smallBoxHeight
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        }
        if (relatorio.CriaRecriaOutrasAtividades) {
          cursor = checkAddPage(doc, cursor, smallBoxHeight * 3, config)
          doc.setFont('helvetica', 'bold')
          renderCheckboxList(doc, ' • Outras Atividades:', cursor, config)
          doc.setFont('helvetica', 'normal')
          cursor = renderRecomendacoes(
            doc,
            '',
            relatorio.CriaRecriaOutrasAtividadesText,
            cursor,
            config,
          )
        }
        doc.rect(startX, cursor, tableLimit, line, 'F')
      }

      if (relatorio.PesagemRecria) {
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        doc.setFont('helvetica', 'bold')
        renderCheckboxList(doc, ' • Recomendações:', cursor, config)
        doc.setFont('helvetica', 'normal')
        cursor += smallBoxHeight
        renderCheckboxList(
          doc,
          'Realizar pesagem mensal dos animais em recria para acompanhar ganho de peso',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      if (relatorio.OutrasRecomendacoesCriaRecria) {
        cursor = checkAddPage(doc, cursor, smallBoxHeight * 3, config)
        doc.setFont('helvetica', 'bold')
        renderCheckboxList(doc, ' • Outras recomendações:', cursor, config)
        doc.setFont('helvetica', 'normal')
        cursor = renderRecomendacoes(
          doc,
          '',
          relatorio.OutrasRecomendacoesCriaRecria,
          cursor,
          config,
        )
      }

      doc.rect(startX, cursor, tableLimit, line, 'F')
    } else {
      cursor = renderRecomendacoes(
        doc,
        'Atividade não realizada',
        relatorio.CriaRecriaObs,
        cursor,
        config,
      )
    }
  }

  return cursor
}

function clinico(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
    smallGutterX,
    smallGutterY,
  } = config

  if (
    (relatorio.Clinico &&
      ((relatorio.ListaAnimaisTratados != null &&
        relatorio.ListaAnimaisTratados.length != 0) ||
        (relatorio.Tratamento != null && relatorio.Tratamento.length != 0) ||
        relatorio.OutrasRecomendacoesAtendimentoClinico != '')) ||
    relatorio.ClinicoObs != ''
  ) {
    cursor += config.boxHeight
    if (cursor + boxHeight * 4 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Atendimento clínico', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 5

    var colInicio = startX
    var data

    doc.rect(startX, cursor, tableLimit, line, 'F')

    if (relatorio.Clinico) {
      if (
        relatorio.ListaAnimaisTratados != null &&
        relatorio.ListaAnimaisTratados.length != 0
      ) {
        doc.rect(startX, cursor, line, boxHeight, 'F') // esquerda
        doc.rect(startX + tableLimit, cursor, line, boxHeight, 'F') // direita

        doc.text(
          'Animais tratados:',
          startX + tableLimit / 1000 + smallGutterX,
          cursor + smallGutterY,
        )

        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, boxHeight, config)

        Object.keys(relatorio.ListaAnimaisTratados).forEach(item => {
          if (item % 3 === 1) {
            colInicio = startX + tableLimit / 3
          } else if (item % 3 === 2) {
            colInicio = startX + tableLimit - tableLimit / 3
          } else {
            colInicio = startX
          }

          doc.text(
            relatorio.ListaAnimaisTratados[item].acAnimaisTratadosNomeOuBrinco,
            colInicio + smallGutterX,
            cursor + smallGutterY,
          )
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          if (
            item % 3 === 2 ||
            Object.keys(relatorio.ListaAnimaisTratados).length - 1 == item
          ) {
            doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
            doc.rect(tableLimit + startX, cursor, line, smallBoxHeight, 'F') // direita
            cursor += smallBoxHeight
            cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
          }
        })
        cursor = checkAddPage(doc, cursor, boxHeight * 4, config)
        doc.rect(startX, cursor, tableLimit, line, 'F') // superior
      }

      if (
        relatorio.ListaAnimaisTratados != null &&
        relatorio.Tratamento != null &&
        relatorio.Tratamento.length != 0
      ) {
        doc.rect(startX, cursor, line, boxHeight, 'F') // esquerda
        doc.rect(startX + tableLimit, cursor, line, boxHeight, 'F') // direita

        doc.text(
          'Tratamento:',
          startX + tableLimit / 1000 + smallGutterX,
          cursor + smallGutterY,
        )

        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, boxHeight, config)

        Object.keys(relatorio.Tratamento).forEach(item => {
          colInicio = startX + tableLimit / 1000
          data =
            'Medicação: ' +
            relatorio.Tratamento[item].acTratamentoMedicacao +
            ' | Dosagem: ' +
            relatorio.Tratamento[item].acTratamentoDosagem +
            ' | Via de aplicação: ' +
            relatorio.Tratamento[item].acTratamentoViaAplicacao +
            ' | Duração: ' +
            relatorio.Tratamento[item].acTratamentoDuracao

          const width = doc.getTextWidth(data)
          const numberLines = parseInt(
            width / (tableLimit - smallGutterX * 2) + 2,
          )

          cursor = checkAddPage(
            doc,
            cursor,
            smallBoxHeight * numberLines,
            config,
          )

          doc.rect(startX, cursor, line, smallBoxHeight * numberLines, 'F')

          doc.text(data, colInicio + smallGutterX, cursor + smallGutterY, {
            maxWidth: tableLimit - smallGutterX * 2,
          })

          doc.rect(
            startX + tableLimit,
            cursor,
            line,
            smallBoxHeight * numberLines,
            'F',
          )

          doc.rect(
            startX,
            cursor + smallBoxHeight * numberLines,
            tableLimit,
            line,
            'F',
          )
          Object.keys(relatorio.Tratamento).length - 1 == item
          doc.rect(startX, cursor, line, smallBoxHeight, 'F') // esquerda
          doc.rect(tableLimit + startX, cursor, line, smallBoxHeight, 'F') // direita
          cursor += smallBoxHeight * numberLines
          cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
        })
        cursor = checkAddPage(doc, cursor, boxHeight * 4, config)
        doc.rect(startX, cursor, tableLimit, line, 'F') // superior
      }

      if (relatorio.OutrasRecomendacoesAtendimentoClinico) {
        cursor = checkAddPage(doc, cursor, smallBoxHeight * 3, config)
        doc.setFont('helvetica', 'bold')
        renderCheckboxList(doc, ' • Outras recomendações:', cursor, config)
        doc.setFont('helvetica', 'normal')
        cursor = renderRecomendacoes(
          doc,
          '',
          relatorio.OutrasRecomendacoesAtendimentoClinico,
          cursor,
          config,
        )
      }
    } else {
      cursor = renderRecomendacoes(
        doc,
        'Atividade não realizada',
        relatorio.ClinicoObs,
        cursor,
        config,
      )
    }
  }
  return cursor
}

function discussaoZootecnico(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    margin,
    heightLimit,
  } = config

  if (
    relatorio.DiscussaoZootecnicoText &&
    relatorio.DiscussaoZootecnicoText != ''
  ) {
    cursor += config.boxHeight
    if (cursor + boxHeight * 8 > heightLimit) {
      doc.addPage()
      cursor = margin
    }
    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Discussões de indicadores zootécnicos', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 5

    doc.rect(startX, cursor, tableLimit, line, 'F')

    cursor = renderDiscussao(
      doc,
      relatorio.DiscussaoZootecnicoText,
      cursor,
      config,
    )
  }

  return cursor
}

function discussaoEconomico(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config

  if (
    relatorio.DiscussaoEconomicoText &&
    relatorio.DiscussaoEconomicoText != ''
  ) {
    cursor += config.boxHeight
    if (cursor + boxHeight * 8 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Discussões de indicadores econômicos', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 5

    doc.rect(startX, cursor, tableLimit, line, 'F')

    cursor = renderDiscussao(
      doc,
      relatorio.DiscussaoEconomicoText,
      cursor,
      config,
    )
  }

  return cursor
}

function discussaoMercado(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config

  if (relatorio.DiscussaoMercadoText && relatorio.DiscussaoMercadoText != '') {
    cursor += config.boxHeight
    if (cursor + boxHeight * 2 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text(
        'Discussões de indicadores de mercado (preço do leite, insumos, venda/compra de animais)',
        startX,
        cursor,
      )
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 5

    doc.rect(startX, cursor, tableLimit, line, 'F')

    cursor = renderDiscussao(
      doc,
      relatorio.DiscussaoMercadoText,
      cursor,
      config,
    )
  }

  return cursor
}

function planejamentoEstrategico(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config

  if (
    relatorio.PlanejamentoEstrategicoText &&
    relatorio.PlanejamentoEstrategicoText != ''
  ) {
    cursor += config.boxHeight
    if (cursor + boxHeight * 2 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Planejamento estratégico', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 5

    doc.rect(startX, cursor, tableLimit, line, 'F')

    cursor = renderDiscussao(
      doc,
      relatorio.PlanejamentoEstrategicoText,
      cursor,
      config,
    )
  }

  return cursor
}

// eslint-disable-next-line no-unused-vars
function relatoriosAnexo(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config

  if (
    relatorio.AcompanhamentoRebanho ||
    relatorio.AnaliseReprodutiva ||
    relatorio.PrevisaoPartos ||
    relatorio.VacasSecar ||
    relatorio.DivisaoLotes ||
    relatorio.AnaliseQualidade ||
    relatorio.CcsIndividual ||
    relatorio.LinhaOrdenha ||
    relatorio.FluxoCaixa ||
    relatorio.CustoProducaoMensal ||
    relatorio.CustoProducaoAnual
  ) {
    cursor += config.boxHeight
    if (cursor + boxHeight * 2 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Relatórios em anexo', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 5

    doc.rect(startX, cursor, tableLimit, line, 'F')

    if (relatorio.AcompanhamentoRebanho) {
      renderCheckbox(doc, 'Acompanhamento de rebanho', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.AnaliseReprodutiva) {
      renderCheckbox(doc, 'Análise reprodutiva', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.PrevisaoPartos) {
      renderCheckbox(doc, 'Previsão de partos', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.VacasSecar) {
      renderCheckbox(doc, 'Vacas a secar', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.DivisaoLotes) {
      renderCheckbox(doc, 'Divisão de lotes e arraçoamento', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.AnaliseQualidade) {
      renderCheckbox(doc, 'Análise de qualidade', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.CcsIndividual) {
      renderCheckbox(doc, 'CCS individual', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.LinhaOrdenha) {
      renderCheckbox(doc, 'Linha de ordenha', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.FluxoCaixa) {
      renderCheckbox(doc, 'Fluxo de caixa', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.CustoProducaoMensal) {
      renderCheckbox(doc, 'Custo de produção mensal', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
    if (relatorio.CustoProducaoAnual) {
      renderCheckbox(doc, 'Custo de produção anual', cursor, config)
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    }
  }

  return cursor
}

function declaracao(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    line,
    mediumFontSize,
    fontSize,
    smallGutterX,
    smallGutterY,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config

  let colInicio = startX
  let data
  let numberLines
  const produtor = new Image()

  cursor += config.boxHeight
  if (cursor + boxHeight * 10 > heightLimit) {
    doc.addPage()
    cursor = margin + 10
  }
  if (relatorio.AssinaturaProdutorOuResponsavel) {
    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Declaração', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 5

    doc.rect(startX, cursor, tableLimit, line, 'F')

    doc.text(
      'Valor da visita técnica:',
      colInicio + smallGutterX,
      cursor + smallBoxHeight - 5,
      {
        maxWidth: tableLimit - smallGutterX * 2,
      },
    )

    doc.setFont('helvetica', 'bold')
    doc.text(
      'R$ ' + showMoney(relatorio.CustoFixo),
      colInicio + smallGutterX + boxHeight * 3.8,
      cursor + smallBoxHeight - 5,
      {
        maxWidth: tableLimit - smallGutterX * 2,
      },
    )
    doc.setFont('helvetica', 'normal')

    cursor += smallBoxHeight

    doc.text(
      'O valor destinado ao',
      colInicio + smallGutterX,
      cursor + smallBoxHeight - 5,
      {
        maxWidth: tableLimit - smallGutterX * 2,
      },
    )

    doc.setFont('helvetica', 'bold')
    doc.text(
      'produtor',
      colInicio + smallGutterX + boxHeight * 3.5,
      cursor + smallBoxHeight - 5,
      {
        maxWidth: tableLimit - smallGutterX * 2,
      },
    )
    doc.setFont('helvetica', 'normal')

    doc.text(
      '(' + showPercentage(relatorio.PorcentagemcustoProdutor) + '%)',
      colInicio + smallGutterX + boxHeight * 5.2,
      cursor + smallBoxHeight - 5,
      {
        maxWidth: tableLimit - smallGutterX * 2,
      },
    )

    doc.setFont('helvetica', 'bold')
    doc.text(
      'R$ ' + showMoney(relatorio.CustoVisitaProdutor),
      colInicio + smallGutterX + boxHeight * 6.8,
      cursor + smallBoxHeight - 5,
      {
        maxWidth: tableLimit - smallGutterX * 2,
      },
    )
    doc.setFont('helvetica', 'normal')

    doc.text(
      '(descontado em convênio Vigor na NF do leite).',
      colInicio +
        smallGutterX +
        boxHeight * 8.6 +
        String(relatorio.ValorTotalDaVisita).length,
      cursor + smallBoxHeight - 5,
      {
        maxWidth: tableLimit - smallGutterX * 2,
      },
    )
    cursor += smallBoxHeight

    doc.text(
      'O valor destinado a',
      colInicio + smallGutterX,
      cursor + smallBoxHeight - 5,
      {
        maxWidth: tableLimit - smallGutterX * 2,
      },
    )

    doc.setFont('helvetica', 'bold')
    doc.text(
      'Vigor',
      colInicio + smallGutterX + boxHeight * 3.3,
      cursor + smallBoxHeight - 5,
      {
        maxWidth: tableLimit - smallGutterX * 2,
      },
    )
    doc.setFont('helvetica', 'normal')

    doc.text(
      '(' + showPercentage(relatorio.PorcentagemcustoVisitaVigor) + '%)',
      colInicio + smallGutterX + boxHeight * 4.4,
      cursor + smallBoxHeight - 5,
      {
        maxWidth: tableLimit - smallGutterX * 2,
      },
    )

    doc.setFont('helvetica', 'bold')
    doc.text(
      'R$ ' + showMoney(relatorio.CustoVisitaVigor),
      colInicio + smallGutterX + boxHeight * 6,
      cursor + smallBoxHeight - 5,
      {
        maxWidth: tableLimit - smallGutterX * 2,
      },
    )
    doc.setFont('helvetica', 'normal')

    doc.text(
      '(pago pela vigor ao consultor).',
      colInicio +
        smallGutterX +
        boxHeight * 7.7 +
        String(relatorio.CustoVisitaVigor).length,
      cursor + smallBoxHeight - 5,
      {
        maxWidth: tableLimit - smallGutterX * 2,
      },
    )
    cursor += smallBoxHeight

    doc.setFont('helvetica', 'normal')

    if (relatorio.outrosValores.length > 0) {
      doc.text(
        'Outros Valores: ',
        colInicio + smallGutterX,
        cursor + smallBoxHeight - 5,
        {
          maxWidth: tableLimit - smallGutterX * 2,
        },
      )

      doc.setFont('helvetica', 'bold')
      doc.text(
        'R$ ' + relatorio.outrosValores,
        colInicio + smallGutterX + boxHeight * 3,
        cursor + smallBoxHeight - 5,
        {
          maxWidth: tableLimit - smallGutterX * 2,
        },
      )

      doc.setFont('helvetica', 'normal')

      cursor += smallBoxHeight

      doc.text(
        'Descrição dos demais pagamentos realizados: ',
        colInicio + smallGutterX,
        cursor + smallBoxHeight - 5,
        {
          maxWidth: tableLimit - smallGutterX * 2,
        },
      )

      doc.setFont('helvetica', 'bold')
      doc.text(
        relatorio?.descricaoOutrosValoes ?? '',
        colInicio + smallGutterX + boxHeight * 8,
        cursor + smallBoxHeight - 5,
        {
          maxWidth: tableLimit - smallGutterX * 2,
        },
      )

      doc.setFont('helvetica', 'normal')

      cursor += smallBoxHeight
    }

    doc.text(
      'Assinatura produtor(a):',
      colInicio + smallGutterX,
      cursor + smallBoxHeight - 5,
      {
        maxWidth: tableLimit - smallGutterX * 2,
      },
    )
    cursor += smallBoxHeight

    if (relatorio.AssinaturaProdutorOuResponsavel === '1') {
      produtor.src = relatorio.AssinaturaProdutor
      doc.addImage(
        produtor,
        'PNG',
        startX + 10,
        cursor - 150,
        100,
        145,
        '',
        'FAST',
        270,
      )
    } else {
      produtor.src = relatorio.AssinaturaResponsavel
      doc.addImage(
        produtor,
        'PNG',
        startX + 10,
        cursor - 150,
        100,
        145,
        '',
        'FAST',
        270,
      )
    }

    cursor += smallBoxHeight * 5

    colInicio = startX + tableLimit / 1000
    data =
      'Declaro para os devidos fins ter recebido a visita técnica do Projeto Evolui Leite, executado pelo técnico responsável:\n' +
      relatorio.consultor.pessoafisica.Nome +
      ', na data de ' +
      relatorio.DataAtendimento +
      ', e autorizo a DanVigor a realizar o pagamento da fatura em meu nome e efetuar\no desconto do valor na nota de pagamento do leite fornecido no mês da visita, sendo o valor citado de R$ ' +
      showMoney(relatorio.CustoVisitaProdutor) +
      '.'

    const width = doc.getTextWidth(data + smallBoxHeight)
    numberLines = parseInt(width / (tableLimit - smallGutterX * 2) + 11.5)

    doc.rect(
      startX,
      cursor - smallBoxHeight * 11,
      line,
      smallBoxHeight + smallBoxHeight * numberLines,
      'F',
    )

    doc.text(data, colInicio + smallGutterX, cursor + smallGutterY, {
      maxWidth: tableLimit - smallGutterX * 2,
    })

    doc.rect(
      startX + tableLimit,
      cursor - smallBoxHeight * 11,
      line,
      smallBoxHeight + smallBoxHeight * numberLines,
      'F',
    )

    doc.rect(
      startX,
      cursor + smallBoxHeight * numberLines - smallBoxHeight * 10,
      tableLimit,
      line,
      'F',
    )
    return cursor + smallBoxHeight * 5
  }
}

function fotosAnexo(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    mediumFontSize,
    fontSize,
    startX,
    tableLimit,
    heightLimit,
    margin,
  } = config

  if (
    (relatorio.ImagensManejoReprodutivoVacas &&
      relatorio.ImagensManejoReprodutivoVacas.length > 0) ||
    (relatorio.ImagensManejoReprodutivoNovilhas &&
      relatorio.ImagensManejoReprodutivoNovilhas.length > 0) ||
    (relatorio.ImagensAjustesNutricionais &&
      relatorio.ImagensAjustesNutricionais.length > 0) ||
    (relatorio.ImagensPlanejamentoVolumoso &&
      relatorio.ImagensPlanejamentoVolumoso.length > 0) ||
    (relatorio.ImagensAcompanhamentoDeOrdenha &&
      relatorio.ImagensAcompanhamentoDeOrdenha.length > 0) ||
    (relatorio.ImagensManejoSanitario &&
      relatorio.ImagensManejoSanitario.length > 0) ||
    (relatorio.ImagensAcompanhamentoCriaRecria &&
      relatorio.ImagensAcompanhamentoCriaRecria.length > 0) ||
    (relatorio.ImagensAtendimentoClinico &&
      relatorio.ImagensAtendimentoClinico.length > 0)
  ) {
    if (cursor + boxHeight * 8 > heightLimit) {
      doc.addPage()
      cursor = margin + 10
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Imagens em anexo', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight - 5

    var colInicio = startX

    if (
      relatorio.ImagensManejoReprodutivoVacas &&
      relatorio.ImagensManejoReprodutivoVacas.length > 0
    ) {
      if (cursor + boxHeight * 7 > heightLimit) {
        doc.addPage()
        cursor = margin + 10
      }

      doc
        .setFont('helvetica', 'bold')
        .text('Imagens do manejo reprodutivo de vacas', startX, cursor)
        .setFont('helvetica', 'normal')

      cursor += smallBoxHeight

      let imagem = new Image()
      Object.keys(relatorio.ImagensManejoReprodutivoVacas).forEach(item => {
        try {
          if (item % 2 === 1) {
            colInicio = startX + 10 + tableLimit / 2
          } else {
            colInicio = startX + 40
          }

          if (cursor + boxHeight * 8 > heightLimit) {
            doc.addPage()
            cursor = margin + 10
          }
          imagem.src = `${ENV_APP.apiUri}/download-image/${relatorio.ImagensManejoReprodutivoVacas[item].id}`

          doc.addImage(imagem, 'PNG', colInicio, cursor, 180, 180, '', 'FAST')

          if (
            item % 2 === 1 ||
            Object.keys(relatorio.ImagensManejoReprodutivoVacas).length - 1 ==
              item
          ) {
            cursor += 200
          }
          // eslint-disable-next-line no-empty
        } catch (error) {}
      })
      cursor += boxHeight //cursor += 380
    }

    colInicio = startX

    if (
      relatorio.ImagensManejoReprodutivoNovilhas &&
      relatorio.ImagensManejoReprodutivoNovilhas.length > 0
    ) {
      if (cursor + boxHeight * 8 > heightLimit) {
        doc.addPage()
        cursor = margin
      }

      doc
        .setFont('helvetica', 'bold')
        .text('Imagens do manejo reprodutivo de novilhas', startX, cursor)
        .setFont('helvetica', 'normal')

      cursor += smallBoxHeight

      let imagem = new Image()
      Object.keys(relatorio.ImagensManejoReprodutivoNovilhas).forEach(item => {
        try {
          if (item % 2 === 1) {
            colInicio = startX + 10 + tableLimit / 2
          } else {
            colInicio = startX + 40
          }

          if (cursor + boxHeight * 7 > heightLimit) {
            doc.addPage()
            cursor = margin
          }
          imagem.src = `${ENV_APP.apiUri}/download-image/${relatorio.ImagensManejoReprodutivoNovilhas[item].id}`

          doc.addImage(imagem, 'PNG', colInicio, cursor, 180, 180, '', 'FAST')

          if (
            item % 2 === 1 ||
            Object.keys(relatorio.ImagensManejoReprodutivoNovilhas).length -
              1 ==
              item
          ) {
            cursor += 200
          }
          // eslint-disable-next-line no-empty
        } catch (error) {}
      })
      cursor += boxHeight //cursor += 380
    }

    colInicio = startX

    if (
      relatorio.ImagensAjustesNutricionais &&
      relatorio.ImagensAjustesNutricionais.length > 0
    ) {
      if (cursor + boxHeight * 8 > heightLimit) {
        doc.addPage()
        cursor = margin
      }

      doc
        .setFont('helvetica', 'bold')
        .text('Imagens do ajustes nutricionais', startX, cursor)
        .setFont('helvetica', 'normal')

      cursor += smallBoxHeight

      let imagem = new Image()
      Object.keys(relatorio.ImagensAjustesNutricionais).forEach(item => {
        try {
          if (item % 2 === 1) {
            colInicio = startX + 10 + tableLimit / 2
          } else {
            colInicio = startX + 40
          }

          if (cursor + boxHeight * 7 > heightLimit) {
            doc.addPage()
            cursor = margin
          }
          imagem.src = `${ENV_APP.apiUri}/download-image/${relatorio.ImagensAjustesNutricionais[item].id}`

          doc.addImage(imagem, 'PNG', colInicio, cursor, 180, 180, '', 'FAST')

          if (
            item % 2 === 1 ||
            Object.keys(relatorio.ImagensAjustesNutricionais).length - 1 == item
          ) {
            cursor += 200
          }
          // eslint-disable-next-line no-empty
        } catch (error) {}
      })
      cursor += boxHeight //cursor += 380
    }

    colInicio = startX

    if (
      relatorio.ImagensPlanejamentoVolumoso &&
      relatorio.ImagensPlanejamentoVolumoso.length > 0
    ) {
      if (cursor + boxHeight * 8 > heightLimit) {
        doc.addPage()
        cursor = margin
      }

      doc
        .setFont('helvetica', 'bold')
        .text('Imagens do planejamento volumoso', startX, cursor)
        .setFont('helvetica', 'normal')

      cursor += smallBoxHeight

      let imagem = new Image()
      Object.keys(relatorio.ImagensPlanejamentoVolumoso).forEach(item => {
        try {
          if (item % 2 === 1) {
            colInicio = startX + 10 + tableLimit / 2
          } else {
            colInicio = startX + 40
          }

          if (cursor + boxHeight * 7 > heightLimit) {
            doc.addPage()
            cursor = margin
          }
          imagem.src = `${ENV_APP.apiUri}/download-image/${relatorio.ImagensPlanejamentoVolumoso[item].id}`

          doc.addImage(imagem, 'PNG', colInicio, cursor, 180, 180, '', 'FAST')

          if (
            item % 2 === 1 ||
            Object.keys(relatorio.ImagensPlanejamentoVolumoso).length - 1 ==
              item
          ) {
            cursor += 200
          }
          // eslint-disable-next-line no-empty
        } catch (error) {}
      })
      cursor += boxHeight //cursor += 380
    }

    colInicio = startX

    if (
      relatorio.ImagensAcompanhamentoDeOrdenha &&
      relatorio.ImagensAcompanhamentoDeOrdenha.length > 0
    ) {
      if (cursor + boxHeight * 8 > heightLimit) {
        doc.addPage()
        cursor = margin
      }

      doc
        .setFont('helvetica', 'bold')
        .text('Imagens do acompanhamento de ordenha', startX, cursor)
        .setFont('helvetica', 'normal')

      cursor += smallBoxHeight

      let imagem = new Image()
      Object.keys(relatorio.ImagensAcompanhamentoDeOrdenha).forEach(item => {
        try {
          if (item % 2 === 1) {
            colInicio = startX + 10 + tableLimit / 2
          } else {
            colInicio = startX + 40
          }

          if (cursor + boxHeight * 7 > heightLimit) {
            doc.addPage()
            cursor = margin
          }
          imagem.src = `${ENV_APP.apiUri}/download-image/${relatorio.ImagensAcompanhamentoDeOrdenha[item].id}`

          doc.addImage(imagem, 'PNG', colInicio, cursor, 180, 180, '', 'FAST')

          if (
            item % 2 === 1 ||
            Object.keys(relatorio.ImagensAcompanhamentoDeOrdenha).length - 1 ==
              item
          ) {
            cursor += 200
          }
          // eslint-disable-next-line no-empty
        } catch (error) {}
      })
      cursor += boxHeight * 2 //cursor += 380
    }

    colInicio = startX

    if (
      relatorio.ImagensManejoSanitario &&
      relatorio.ImagensManejoSanitario.length > 0
    ) {
      if (cursor + boxHeight * 8 > heightLimit) {
        doc.addPage()
        cursor = margin
      }

      doc
        .setFont('helvetica', 'bold')
        .text('Imagens do manejo sanitário', startX, cursor)
        .setFont('helvetica', 'normal')

      cursor += smallBoxHeight

      let imagem = new Image()
      Object.keys(relatorio.ImagensManejoSanitario).forEach(item => {
        try {
          if (item % 2 === 1) {
            colInicio = startX + 10 + tableLimit / 2
          } else {
            colInicio = startX + 40
          }

          if (cursor + boxHeight * 7 > heightLimit) {
            doc.addPage()
            cursor = margin
          }
          imagem.src = `${ENV_APP.apiUri}/download-image/${relatorio.ImagensManejoSanitario[item].id}`

          doc.addImage(imagem, 'PNG', colInicio, cursor, 180, 180, '', 'FAST')

          if (
            item % 2 === 1 ||
            Object.keys(relatorio.ImagensManejoSanitario).length - 1 == item
          ) {
            cursor += 200
          }
          // eslint-disable-next-line no-empty
        } catch (error) {}
      })
      cursor += boxHeight //cursor += 380
    }

    colInicio = startX

    if (
      relatorio.ImagensAcompanhamentoCriaRecria &&
      relatorio.ImagensAcompanhamentoCriaRecria.length > 0
    ) {
      if (cursor + boxHeight * 8 > heightLimit) {
        doc.addPage()
        cursor = margin
      }

      doc
        .setFont('helvetica', 'bold')
        .text('Imagens do acompanhamento cria/recria', startX, cursor)
        .setFont('helvetica', 'normal')

      cursor += smallBoxHeight

      let imagem = new Image()
      Object.keys(relatorio.ImagensAcompanhamentoCriaRecria).forEach(item => {
        try {
          if (item % 2 === 1) {
            colInicio = startX + 10 + tableLimit / 2
          } else {
            colInicio = startX + 40
          }

          if (cursor + boxHeight * 7 > heightLimit) {
            doc.addPage()
            cursor = margin
          }
          imagem.src = `${ENV_APP.apiUri}/download-image/${relatorio.ImagensAcompanhamentoCriaRecria[item].id}`

          doc.addImage(imagem, 'PNG', colInicio, cursor, 180, 180, '', 'FAST')

          if (
            item % 2 === 1 ||
            Object.keys(relatorio.ImagensAcompanhamentoCriaRecria).length - 1 ==
              item
          ) {
            cursor += 200
          }
          // eslint-disable-next-line no-empty
        } catch (error) {}
      })
      cursor += boxHeight //cursor += 380
    }

    colInicio = startX

    if (
      relatorio.ImagensAtendimentoClinico &&
      relatorio.ImagensAtendimentoClinico.length > 0
    ) {
      if (cursor + boxHeight * 8 > heightLimit) {
        doc.addPage()
        cursor = margin
      }

      doc
        .setFont('helvetica', 'bold')
        .text('Imagens do atendimento clínico', startX, cursor)
        .setFont('helvetica', 'normal')

      cursor += smallBoxHeight

      let imagem = new Image()
      Object.keys(relatorio.ImagensAtendimentoClinico).forEach(item => {
        try {
          if (item % 2 === 1) {
            colInicio = startX + 10 + tableLimit / 2
          } else {
            colInicio = startX + 40
          }

          if (cursor + boxHeight * 7 > heightLimit) {
            doc.addPage()
            cursor = margin
          }
          imagem.src = `${ENV_APP.apiUri}/download-image/${relatorio.ImagensAtendimentoClinico[item].id}`

          doc.addImage(imagem, 'PNG', colInicio, cursor, 180, 180, '', 'FAST')
          if (
            item % 2 === 1 ||
            Object.keys(relatorio.ImagensAtendimentoClinico).length - 1 == item
          ) {
            cursor += 200
          }
          // eslint-disable-next-line no-empty
        } catch (error) {}
      })
      cursor += boxHeight //cursor += 380
    }
  }

  return cursor
}

function proximaVisitaAssinaturas(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    tableLimit,
    heightLimit,
    margin,
    startX,
    smallGutterY,
    line,
  } = config

  cursor += config.boxHeight
  if (cursor + boxHeight * 7 > heightLimit) {
    doc.addPage()
    cursor = margin
  }
  const proximaVisita = relatorio.AssinaturaProdutorOuResponsavel
    ? relatorio.DataProximaVisita
    : moment(relatorio.DataProximaVisita).format('DD/MM/YYYY')

  doc.text('Data da próxima visita', tableLimit / 2 - 30, cursor + boxHeight)
  doc.text(
    proximaVisita,
    tableLimit / 2 - 5,
    cursor + boxHeight + smallBoxHeight,
  )

  cursor += boxHeight * 2

  const produtor = new Image()

  if (relatorio.AssinaturaProdutorOuResponsavel) {
    if (relatorio.AssinaturaProdutorOuResponsavel === '1') {
      produtor.src = relatorio.AssinaturaProdutor
      doc.addImage(
        produtor,
        'PNG',
        startX + 50,
        cursor - 140,
        100,
        145,
        '',
        'FAST',
        270,
      )
    } else {
      produtor.src = relatorio.AssinaturaResponsavel
      doc.addImage(
        produtor,
        'PNG',
        startX + 50,
        cursor - 140,
        100,
        145,
        '',
        'FAST',
        270,
      )
    }

    const consultor = new Image()
    consultor.src = relatorio.AssinaturaConsultor

    if (relatorio.AssinaturaConsultor) {
      doc.addImage(
        consultor,
        'PNG',
        tableLimit - 180,
        cursor - 140,
        100,
        145,
        '',
        'FAST',
        270,
      )
    }

    doc.rect(startX, cursor + 100, tableLimit / 2.1, line, 'F')

    if (relatorio.AssinaturaProdutorOuResponsavel === '1') {
      doc.text(
        relatorio.fazenda.produtor.Nome,
        tableLimit / 3.5,
        cursor + 100 + smallGutterY,
        null,
        null,
        'center',
      )
      doc.text(
        'Assinatura produtor(a)',
        tableLimit / 3.5,
        cursor + 115 + smallGutterY,
        null,
        null,
        'center',
      )
    } else {
      doc.text(
        relatorio.NomeResponsavel,
        tableLimit / 3.5,
        cursor + 100 + smallGutterY,
        null,
        null,
        'center',
      )
      doc.text(
        'Assinatura do responsável',
        tableLimit / 3.5,
        cursor + 115 + smallGutterY,
        null,
        null,
        'center',
      )
    }

    doc.rect(startX + 280, cursor + 100, tableLimit / 2.1, line, 'F')
    doc.text(
      relatorio.consultor.pessoafisica.Nome,
      startX + 420,
      cursor + 100 + smallGutterY,
      null,
      null,
      'center',
    )
    doc.text(
      'Assinatura consultor(a)',
      startX + 420,
      cursor + 115 + smallGutterY,
      null,
      null,
      'center',
    )
  } else {
    produtor.src = relatorio.assinaturas.AssinaturaProdutor
    doc.addImage(
      produtor,
      'PNG',
      startX + 50,
      cursor - 140,
      100,
      145,
      '',
      'FAST',
      270,
    )

    const consultor = new Image()
    consultor.src = relatorio.assinaturas.AssinaturaConsultor
    if (relatorio.assinaturas.AssinaturaConsultor) {
      doc.addImage(
        consultor,
        'PNG',
        tableLimit - 180,
        cursor - 140,
        100,
        145,
        '',
        'FAST',
        270,
      )
    }

    doc.rect(startX + 25, cursor + 100, tableLimit / 3, line, 'F')
    doc.text(
      relatorio.assinaturas.NomeResponsavel
        ? relatorio.assinaturas.NomeResponsavel
        : relatorio.fazenda.produtor.Nome,
      startX + 65,
      cursor + 100 + smallGutterY,
    )
    doc.text(
      'Assinatura ' +
        (relatorio.assinaturas.NomeResponsavel
          ? 'do responsável'
          : 'do(a) produtor(a)'),
      startX + 65,
      cursor + 115 + smallGutterY,
    )

    doc.rect(startX + 320, cursor + 100, tableLimit / 3, line, 'F')
    doc.text(
      relatorio.consultor.pessoafisica.Nome,
      startX + 360 + relatorio.consultor.pessoafisica.Nome.length,
      cursor + 100 + smallGutterY,
    )
    doc.text(
      'Assinatura consultor(a)',
      startX + 360,
      cursor + 115 + smallGutterY,
    )
  }

  cursor += boxHeight * 2

  return cursor
}

function rodapeImpressao(doc, config, cursor, text) {
  const { boxHeight, heightLimit, margin } = config
  const docWidth = doc.internal.pageSize.width
  const texto =
    utilsRelatorio.getDataAtual() +
    '       ' +
    `${ENV_APP.relUri}` +
    '        Pag. ' +
    text

  if (cursor + boxHeight * 2 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  doc.text(texto, docWidth / 2, heightLimit + 10, 'center')
}

export default data => {
  return new Promise(function (resolve, reject) {
    const MARGIN = 25
    const realStartY = 95
    var doc = new JsPDF({
      format: 'a4',
      compress: true,
      unit: 'pt',
    })
    var config = {
      startY: realStartY,
      startX: MARGIN,
      smallGutterY: 12,
      smallGutterX: 6,
      line: 0.3,
      tableLimit: doc.internal.pageSize.width - MARGIN - MARGIN,
      heightLimit: doc.internal.pageSize.height - MARGIN,
      margin: MARGIN,
      lineColor: '#000',
      boxHeight: 27,
      smallBoxHeight: 18,
      gutterX: 14,
      gutterY: 23,
      smallFontSize: 6.5,
      fontSize: 10,
      largefontSize: 16,
      mediumFontSize: 12,
      logo: 'img/' + `${ENV_APP.imgFileLogin}`,
      bodyStarY: realStartY + 25 * 8.5,
    }

    try {
      HtmlToCanvas(document.querySelector('#chart-rebanho')).then(canvas => {
        let dataURL = canvas.toDataURL()
        doc.addImage(
          dataURL,
          'PNG',
          config.startX * 2.8,
          config.startY + config.boxHeight * 10 + 120,
          456,
          200,
        )
        let cursor = 0
        header(doc, config, data)
        subHeader(doc, data, config)
        config.startY = config.startY + config.boxHeight * 5 + 20
        percentualEfetivacao(doc, data, config)
        config.startY = config.startY + config.boxHeight * 2 + 20
        indicadoresQualidade(doc, data, config)
        config.startY = config.startY + config.boxHeight * 5 + 40

        cursor = composicaoRebanho(doc, data, config)
        config.startY = cursor + config.boxHeight

        cursor = coletaZootecnico(doc, data, config, cursor)
        cursor = coletaFinanceiro(doc, data, config, cursor)
        cursor = manejoReprodutivo(doc, data, config, cursor)
        cursor = ajustesNutricionais(doc, data, config, cursor)
        cursor = planejamentoVolumoso(doc, data, config, cursor)
        cursor = acompanhamentoOrdenha(doc, data, config, cursor)
        cursor = manejoSanitario(doc, data, config, cursor)
        cursor = criaRecria(doc, data, config, cursor)
        cursor = clinico(doc, data, config, cursor)
        cursor = discussaoZootecnico(doc, data, config, cursor)
        cursor = discussaoEconomico(doc, data, config, cursor)
        cursor = discussaoMercado(doc, data, config, cursor)
        cursor = planejamentoEstrategico(doc, data, config, cursor)
        cursor = declaracao(doc, data, config, cursor)
        cursor = fotosAnexo(doc, data, config, cursor)
        cursor = proximaVisitaAssinaturas(doc, data, config, cursor)
        const pageCount = doc.internal.getNumberOfPages()

        for (let i = 1; i <= pageCount; i++) {
          doc.setPage(i)
          rodapeImpressao(
            doc,
            config,
            cursor + config.boxHeight,
            String(i) + ' de ' + String(pageCount),
          )
        }
        doc
          .save(
            `relatorio-evoluiLeite-${data.NumeroVisita}-${data.fazenda.pessoajuridica.NomeFantasia}.pdf`,
            { returnPromise: true },
          )
          .then(() => {
            return resolve()
          })
      })
    } catch (err) {
      reject(err)
    }
  })
}
