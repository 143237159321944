var render = function render(){var _vm=this,_c=_vm._self._c;return (
    (_vm.relatorio.QtdeVacasAvaliadas != '' &&
      _vm.relatorio.QtdeVacasAvaliadas != '0') ||
    (_vm.relatorio.QtdeVacasGestantes != '' &&
      _vm.relatorio.QtdeVacasGestantes != '0') ||
    (_vm.relatorio.QtdeVacasReavaliar != '' &&
      _vm.relatorio.QtdeVacasReavaliar != '0') ||
    (_vm.relatorio.QtdeVacasVazias != '' && _vm.relatorio.QtdeVacasVazias != '0') ||
    (_vm.relatorio.ListaVacasGestantes != null &&
      _vm.relatorio.ListaVacasGestantes.length != 0) ||
    (_vm.relatorio.ListaVacasReavaliar != null &&
      _vm.relatorio.ListaVacasReavaliar.length != 0) ||
    (_vm.relatorio.ListaVacasVaziasIATF != null &&
      _vm.relatorio.ListaVacasVaziasIATF.length != 0) ||
    (_vm.relatorio.ListaVacasVaziasPGF != null &&
      _vm.relatorio.ListaVacasVaziasPGF.length != 0) ||
    (_vm.relatorio.ListaVacasVaziasRecuperarCondicaoCorporal != null &&
      _vm.relatorio.ListaVacasVaziasRecuperarCondicaoCorporal.length != 0) ||
    (_vm.relatorio.ListaVacasVaziasTratamentoClinico != null &&
      _vm.relatorio.ListaVacasVaziasTratamentoClinico.length != 0) ||
    _vm.relatorio.SepararTourosVacas != '' ||
    _vm.relatorio.OutrasRecomendacoesReprodutivoVacas != '' ||
    _vm.relatorio.ReprodutivoVacasObs != '' ||
    (_vm.relatorio.QtdeNovilhasAvaliadas != '' &&
      _vm.relatorio.QtdeNovilhasAvaliadas != '0') ||
    (_vm.relatorio.QtdeNovilhasGestantes != '' &&
      _vm.relatorio.QtdeNovilhasGestantes != '0') ||
    (_vm.relatorio.QtdeNovilhasReavaliar != '' &&
      _vm.relatorio.QtdeNovilhasReavaliar != '0') ||
    (_vm.relatorio.QtdeNovilhasVazias != '' &&
      _vm.relatorio.QtdeNovilhasVazias != '0') ||
    (_vm.relatorio.ListaNovilhasGestantes != null &&
      _vm.relatorio.ListaNovilhasGestantes.length != 0) ||
    (_vm.relatorio.ListaNovilhasReavaliar != null &&
      _vm.relatorio.ListaNovilhasReavaliar.length != 0) ||
    (_vm.relatorio.ListaNovilhasVaziasIATF != null &&
      _vm.relatorio.ListaNovilhasVaziasIATF.length != 0) ||
    (_vm.relatorio.ListaNovilhasVaziasPGF != null &&
      _vm.relatorio.ListaNovilhasVaziasPGF.length != 0) ||
    (_vm.relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal != null &&
      _vm.relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal.length != 0) ||
    (_vm.relatorio.ListaNovilhasVaziasTratamentoClinico != null &&
      _vm.relatorio.ListaNovilhasVaziasTratamentoClinico.length != 0) ||
    _vm.relatorio.separarTouroNovilhas != '' ||
    _vm.relatorio.OutrasRecomendacoesReprodutivoNovilhas != '' ||
    _vm.relatorio.ReprodutivoNovilhasObs != ''
  )?_c('v-card',{staticClass:"ma-4"},[_c('v-card-title',{staticStyle:{"text-align":"left"}},[_vm._v("Manejo reprodutivo")]),_c('v-card-text',[(
        _vm.relatorio.QtdeVacasAvaliadas ||
        _vm.relatorio.QtdeVacasGestantes ||
        _vm.relatorio.QtdeVacasReavaliar ||
        _vm.relatorio.QtdeVacasVazias ||
        _vm.relatorio.ListaVacasGestantes ||
        _vm.relatorio.ListaVacasReavaliar ||
        _vm.relatorio.ListaVacasVaziasIATF ||
        _vm.relatorio.ListaVacasVaziasPGF ||
        _vm.relatorio.ListaVacasVaziasRecuperarCondicaoCorporal ||
        _vm.relatorio.ListaVacasVaziasTratamentoClinico ||
        _vm.relatorio.SepararTourosVacas ||
        _vm.relatorio.OutrasRecomendacoesReprodutivoVacas ||
        _vm.relatorio.ReprodutivoVacasObs ||
        _vm.relatorio.QtdeNovilhasAvaliadas ||
        _vm.relatorio.QtdeNovilhasGestantes ||
        _vm.relatorio.QtdeNovilhasReavaliar ||
        _vm.relatorio.QtdeNovilhasVazias ||
        _vm.relatorio.ListaNovilhasGestantes ||
        _vm.relatorio.ListaNovilhasReavaliar ||
        _vm.relatorio.ListaNovilhasVaziasIATF ||
        _vm.relatorio.ListaNovilhasVaziasPGF ||
        _vm.relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal ||
        _vm.relatorio.ListaNovilhasVaziasTratamentoClinico ||
        _vm.relatorio.separarTouroNovilhas ||
        _vm.relatorio.OutrasRecomendacoesReprodutivoNovilhas ||
        _vm.relatorio.ReprodutivoNovilhasObs ||
        _vm.relatorio.ReprodutivoVacasAtividade1 ||
        _vm.relatorio.ReprodutivoVacasAtividade2 ||
        _vm.relatorio.ReprodutivoVacasAtividade3 ||
        _vm.relatorio.ReprodutivoVacasAtividade4 ||
        _vm.relatorio.ReprodutivoVacasAtividade5 ||
        _vm.relatorio.ReprodutivoVacasOutrasAtividades ||
        _vm.relatorio.ReprodutivoVacasOutrasAtividadesText ||
        _vm.relatorio.ReprodutivoNovilhasAtividade1 ||
        _vm.relatorio.ReprodutivoNovilhasAtividade2 ||
        _vm.relatorio.ReprodutivoNovilhasAtividade3 ||
        _vm.relatorio.ReprodutivoNovilhasAtividade4 ||
        _vm.relatorio.ReprodutivoNovilhasAtividade5 ||
        _vm.relatorio.ReprodutivoNovilhasOutrasAtividades ||
        _vm.relatorio.ReprodutivoNovilhasOutrasAtividadesText
      )?_c('v-row',{staticClass:"pa-3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Reprodutivo de Vacas ")])]),(_vm.relatorio.QtdeVacasAvaliadas)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"key-title"},[_vm._v("Número de vacas avaliadas: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.QtdeVacasAvaliadas || '-'))])]):_vm._e(),(_vm.relatorio.QtdeVacasGestantes)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"key-title"},[_vm._v("Número de vacas gestantes: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.QtdeVacasGestantes || '-'))])]):_vm._e(),(_vm.relatorio.QtdeVacasReavaliar)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"key-title"},[_vm._v("Número de vacas a reavaliar: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.QtdeVacasReavaliar || '-'))])]):_vm._e(),(_vm.relatorio.QtdeVacasVazias)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"key-title"},[_vm._v("Número de vacas vazias: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.QtdeVacasVazias || '-'))])]):_vm._e(),(
          _vm.relatorio.ListaVacasGestantes != null &&
          _vm.relatorio.ListaVacasGestantes.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Identificação das vacas gestantes ")]),_c('br'),_vm._l((_vm.relatorio.ListaVacasGestantes),function(animal,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[_vm._v(" "+_vm._s(animal.nomeOuBrincoGestante)+" ")])})],2):_vm._e(),(
          _vm.relatorio.ListaVacasReavaliar != null &&
          _vm.relatorio.ListaVacasReavaliar.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Identificação das vacas para reavaliar ")]),_c('br'),_vm._l((_vm.relatorio.ListaVacasReavaliar),function(animal,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[_vm._v(" "+_vm._s(animal.nomeOuBrincoReavaliar)+" ")])})],2):_vm._e(),(
          _vm.relatorio.ListaVacasVaziasIATF != null &&
          _vm.relatorio.ListaVacasVaziasIATF.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Identificação das vacas vazias que entraram no protocolo de IATF ")]),_c('br'),_vm._l((_vm.relatorio.ListaVacasVaziasIATF),function(animal,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[_vm._v(" "+_vm._s(animal.nomeOuBrincoVaziaIATF)+" ")])})],2):_vm._e(),(
          _vm.relatorio.ListaVacasVaziasPGF != null &&
          _vm.relatorio.ListaVacasVaziasPGF.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Identificação das vacas vazias que foram sincronizadas no PGF ")]),_c('br'),_vm._l((_vm.relatorio.ListaVacasVaziasPGF),function(animal,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[_vm._v(" "+_vm._s(animal.nomeOuBrincoVaziaPGF)+" ")])})],2):_vm._e(),(
          _vm.relatorio.ListaVacasVaziasRecuperarCondicaoCorporal != null &&
          _vm.relatorio.ListaVacasVaziasRecuperarCondicaoCorporal.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Identificação das vacas vazias que precisam recuperar o escore de condição corporal ")]),_c('br'),_vm._l((_vm.relatorio.ListaVacasVaziasRecuperarCondicaoCorporal),function(animal,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[_vm._v(" "+_vm._s(animal.nomeOuBrincoVaziaRecuperarCondicaoCorporal)+" ")])})],2):_vm._e(),(
          _vm.relatorio.ListaVacasVaziasTratamentoClinico != null &&
          _vm.relatorio.ListaVacasVaziasTratamentoClinico.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Identificação das vacas vazias que precisam de tratamento clínico ")]),_c('br'),_vm._l((_vm.relatorio.ListaVacasVaziasTratamentoClinico),function(animal,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[_vm._v(" "+_vm._s(animal.nomeOuBrincoVaziaTratamentoClinico)+" ")])})],2):_vm._e(),(
          _vm.relatorio.ReprodutivoVacasAtividade1 ||
          _vm.relatorio.ReprodutivoVacasAtividade2 ||
          _vm.relatorio.ReprodutivoVacasAtividade3 ||
          _vm.relatorio.ReprodutivoVacasAtividade4 ||
          _vm.relatorio.ReprodutivoVacasAtividade5 ||
          _vm.relatorio.ReprodutivoVacasOutrasAtividades
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Atividades realizadas")]),(_vm.relatorio.ReprodutivoVacasAtividade1)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Diagnóstico de gestação das vacas")])]):_vm._e(),(_vm.relatorio.ReprodutivoVacasAtividade2)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Treinamento para aumentar a detecção de cio ")])]):_vm._e(),(_vm.relatorio.ReprodutivoVacasAtividade3)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Treinamento para realizar inseminação artificial")])]):_vm._e(),(_vm.relatorio.ReprodutivoVacasAtividade4)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Treinamento para realizar IATF")])]):_vm._e(),(_vm.relatorio.ReprodutivoVacasAtividade5)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Discussão dos indicadores reprodutivos")])]):_vm._e(),(_vm.relatorio.ReprodutivoVacasOutrasAtividades)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Outras Atividades: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.ReprodutivoVacasOutrasAtividadesText || '-'))])]):_vm._e()],1):_vm._e(),(
          _vm.relatorio.SepararTourosVacas ||
          _vm.relatorio.OutrasRecomendacoesReprodutivoVacas != ''
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Recomendações")]),(_vm.relatorio.SepararTourosVacas != '')?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Separar o touro das vacas ")])]):_vm._e(),(_vm.relatorio.OutrasRecomendacoesReprodutivoVacas != '')?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Outras recomendações: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.OutrasRecomendacoesReprodutivoVacas || '-'))])]):_vm._e()],1):_vm._e(),(_vm.relatorio.ReprodutivoVacasObs != '')?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Motivo de não realização dessa atividade: ")]),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.ReprodutivoVacasObs || '-'))])]):_vm._e(),(
          _vm.relatorio.QtdeNovilhasAvaliadas != '' ||
          _vm.relatorio.QtdeNovilhasGestantes != '' ||
          _vm.relatorio.QtdeNovilhasReavaliar != '' ||
          _vm.relatorio.QtdeNovilhasVazias != '' ||
          (_vm.relatorio.ListaNovilhasGestantes != null &&
            _vm.relatorio.ListaNovilhasGestantes.length != 0) ||
          (_vm.relatorio.ListaNovilhasReavaliar != null &&
            _vm.relatorio.ListaNovilhasReavaliar.length != 0) ||
          (_vm.relatorio.ListaNovilhasVaziasIATF != null &&
            _vm.relatorio.ListaNovilhasVaziasIATF.length != 0) ||
          (_vm.relatorio.ListaNovilhasVaziasPGF != null &&
            _vm.relatorio.ListaNovilhasVaziasPGF.length != 0) ||
          (_vm.relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal != null &&
            _vm.relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal.length !=
              0) ||
          (_vm.relatorio.ListaNovilhasVaziasTratamentoClinico != null &&
            _vm.relatorio.ListaNovilhasVaziasTratamentoClinico.length != 0) ||
          _vm.relatorio.separarTouroNovilhas != '' ||
          _vm.relatorio.OutrasRecomendacoesReprodutivoNovilhas != '' ||
          _vm.relatorio.ReprodutivoNovilhasObs != '' ||
          _vm.relatorio.ReprodutivoNovilhasAtividade1 ||
          _vm.relatorio.ReprodutivoNovilhasAtividade2 ||
          _vm.relatorio.ReprodutivoNovilhasAtividade3 ||
          _vm.relatorio.ReprodutivoNovilhasAtividade4 ||
          _vm.relatorio.ReprodutivoNovilhasAtividade5 ||
          _vm.relatorio.ReprodutivoNovilhasOutrasAtividades
        )?_c('v-col',{staticClass:"mt-4",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Reprodutivo de novilhas ")])]):_vm._e(),(_vm.relatorio.QtdeNovilhasAvaliadas)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"key-title"},[_vm._v("Novilhas avaliadas: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.QtdeNovilhasAvaliadas || '-'))])]):_vm._e(),(_vm.relatorio.QtdeNovilhasGestantes)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"key-title"},[_vm._v("Número de novilhas gestantes: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.QtdeNovilhasGestantes || '-'))])]):_vm._e(),(_vm.relatorio.QtdeNovilhasReavaliar)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"key-title"},[_vm._v("Número de novilhas a reavaliar: ")]),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.QtdeNovilhasReavaliar || '-'))])]):_vm._e(),(_vm.relatorio.QtdeNovilhasVazias)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"key-title"},[_vm._v("Número de novilhas vazias: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.QtdeNovilhasVazias || '-'))])]):_vm._e(),(
          _vm.relatorio.ListaNovilhasGestantes != null &&
          _vm.relatorio.ListaNovilhasGestantes.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Identificação das novilhas gestantes ")]),_c('br'),_vm._l((_vm.relatorio.ListaNovilhasGestantes),function(animal,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[_vm._v(" "+_vm._s(animal.nomeOuBrincoNovilhasGestante)+" ")])})],2):_vm._e(),(
          _vm.relatorio.ListaNovilhasReavaliar != null &&
          _vm.relatorio.ListaNovilhasReavaliar.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Identificação das novilhas para reavaliar ")]),_c('br'),_vm._l((_vm.relatorio.ListaNovilhasReavaliar),function(animal,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[_vm._v(" "+_vm._s(animal.nomeOuBrincoNovilhaReavaliar)+" ")])})],2):_vm._e(),(
          _vm.relatorio.ListaNovilhasVaziasIATF != null &&
          _vm.relatorio.ListaNovilhasVaziasIATF.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Identificação das novilhas vazias que entraram no protocolo de IATF ")]),_c('br'),_vm._l((_vm.relatorio.ListaNovilhasVaziasIATF),function(animal,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[_vm._v(" "+_vm._s(animal.nomeOuBrincoNovilhaVaziaIATF)+" ")])})],2):_vm._e(),(
          _vm.relatorio.ListaNovilhasVaziasPGF != null &&
          _vm.relatorio.ListaNovilhasVaziasPGF.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Identificação das novilhas vazias que foram sincronizadas no PGF ")]),_c('br'),_vm._l((_vm.relatorio.ListaNovilhasVaziasPGF),function(animal,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[_vm._v(" "+_vm._s(animal.nomeOuBrincoNovilhasVaziaPGF)+" ")])})],2):_vm._e(),(
          _vm.relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal != null &&
          _vm.relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Identificação das novilhas vazias que precisam recuperar o escore de condição corporal ")]),_c('br'),_vm._l((_vm.relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal),function(animal,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[_vm._v(" "+_vm._s(animal.nomeOuBrincoNovilhaVaziaRecuperarCondicaoCorporal)+" ")])})],2):_vm._e(),(
          _vm.relatorio.ListaNovilhasVaziasTratamentoClinico != null &&
          _vm.relatorio.ListaNovilhasVaziasTratamentoClinico.length
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Identificação das novilhas vazias que precisam de tratamento clínico ")]),_c('br'),_vm._l((_vm.relatorio.ListaNovilhasVaziasTratamentoClinico),function(animal,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[_vm._v(" "+_vm._s(animal.nomeOuBrincoNovilhaVaziaTratamentoClinico)+" ")])})],2):_vm._e(),(
          _vm.relatorio.ReprodutivoNovilhasAtividade1 ||
          _vm.relatorio.ReprodutivoNovilhasAtividade2 ||
          _vm.relatorio.ReprodutivoNovilhasAtividade3 ||
          _vm.relatorio.ReprodutivoNovilhasAtividade4 ||
          _vm.relatorio.ReprodutivoNovilhasAtividade5 ||
          _vm.relatorio.ReprodutivoNovilhasOutrasAtividades
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Atividades realizadas")]),(_vm.relatorio.ReprodutivoNovilhasAtividade1)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Diagnóstico de gestação das novilhas")])]):_vm._e(),(_vm.relatorio.ReprodutivoNovilhasAtividade2)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Treinamento para aumentar a detecção de cio ")])]):_vm._e(),(_vm.relatorio.ReprodutivoNovilhasAtividade3)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Treinamento para realizar inseminação artificial")])]):_vm._e(),(_vm.relatorio.ReprodutivoNovilhasAtividade4)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Treinamento para realizar IATF")])]):_vm._e(),(_vm.relatorio.ReprodutivoNovilhasAtividade5)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Discussão dos indicadores reprodutivos")])]):_vm._e(),(_vm.relatorio.ReprodutivoNovilhasOutrasAtividades)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Outras Atividades: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.ReprodutivoNovilhasOutrasAtividadesText || '-'))])]):_vm._e()],1):_vm._e(),(
          _vm.relatorio.separarTouroNovilhas ||
          _vm.relatorio.OutrasRecomendacoesReprodutivoNovilhas
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Recomendações")]),(_vm.relatorio.separarTouroNovilhas != '')?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Separar o touro das novilhas ")])]):_vm._e(),(_vm.relatorio.OutrasRecomendacoesReprodutivoNovilhas)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Outras recomendações: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.OutrasRecomendacoesReprodutivoNovilhas || '-'))])]):_vm._e()],1):_vm._e(),(_vm.relatorio.ReprodutivoNovilhasObs)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Motivo de não realização dessa atividade: ")]),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.ReprodutivoNovilhasObs || '-'))])]):_vm._e()],1):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }