<template>
  <v-card
    v-if="
      (relatorio.QtdeVacasAvaliadas != '' &&
        relatorio.QtdeVacasAvaliadas != '0') ||
      (relatorio.QtdeVacasGestantes != '' &&
        relatorio.QtdeVacasGestantes != '0') ||
      (relatorio.QtdeVacasReavaliar != '' &&
        relatorio.QtdeVacasReavaliar != '0') ||
      (relatorio.QtdeVacasVazias != '' && relatorio.QtdeVacasVazias != '0') ||
      (relatorio.ListaVacasGestantes != null &&
        relatorio.ListaVacasGestantes.length != 0) ||
      (relatorio.ListaVacasReavaliar != null &&
        relatorio.ListaVacasReavaliar.length != 0) ||
      (relatorio.ListaVacasVaziasIATF != null &&
        relatorio.ListaVacasVaziasIATF.length != 0) ||
      (relatorio.ListaVacasVaziasPGF != null &&
        relatorio.ListaVacasVaziasPGF.length != 0) ||
      (relatorio.ListaVacasVaziasRecuperarCondicaoCorporal != null &&
        relatorio.ListaVacasVaziasRecuperarCondicaoCorporal.length != 0) ||
      (relatorio.ListaVacasVaziasTratamentoClinico != null &&
        relatorio.ListaVacasVaziasTratamentoClinico.length != 0) ||
      relatorio.SepararTourosVacas != '' ||
      relatorio.OutrasRecomendacoesReprodutivoVacas != '' ||
      relatorio.ReprodutivoVacasObs != '' ||
      (relatorio.QtdeNovilhasAvaliadas != '' &&
        relatorio.QtdeNovilhasAvaliadas != '0') ||
      (relatorio.QtdeNovilhasGestantes != '' &&
        relatorio.QtdeNovilhasGestantes != '0') ||
      (relatorio.QtdeNovilhasReavaliar != '' &&
        relatorio.QtdeNovilhasReavaliar != '0') ||
      (relatorio.QtdeNovilhasVazias != '' &&
        relatorio.QtdeNovilhasVazias != '0') ||
      (relatorio.ListaNovilhasGestantes != null &&
        relatorio.ListaNovilhasGestantes.length != 0) ||
      (relatorio.ListaNovilhasReavaliar != null &&
        relatorio.ListaNovilhasReavaliar.length != 0) ||
      (relatorio.ListaNovilhasVaziasIATF != null &&
        relatorio.ListaNovilhasVaziasIATF.length != 0) ||
      (relatorio.ListaNovilhasVaziasPGF != null &&
        relatorio.ListaNovilhasVaziasPGF.length != 0) ||
      (relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal != null &&
        relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal.length != 0) ||
      (relatorio.ListaNovilhasVaziasTratamentoClinico != null &&
        relatorio.ListaNovilhasVaziasTratamentoClinico.length != 0) ||
      relatorio.separarTouroNovilhas != '' ||
      relatorio.OutrasRecomendacoesReprodutivoNovilhas != '' ||
      relatorio.ReprodutivoNovilhasObs != ''
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left">Manejo reprodutivo</v-card-title>
    <v-card-text>
      <v-row
        v-if="
          relatorio.QtdeVacasAvaliadas ||
          relatorio.QtdeVacasGestantes ||
          relatorio.QtdeVacasReavaliar ||
          relatorio.QtdeVacasVazias ||
          relatorio.ListaVacasGestantes ||
          relatorio.ListaVacasReavaliar ||
          relatorio.ListaVacasVaziasIATF ||
          relatorio.ListaVacasVaziasPGF ||
          relatorio.ListaVacasVaziasRecuperarCondicaoCorporal ||
          relatorio.ListaVacasVaziasTratamentoClinico ||
          relatorio.SepararTourosVacas ||
          relatorio.OutrasRecomendacoesReprodutivoVacas ||
          relatorio.ReprodutivoVacasObs ||
          relatorio.QtdeNovilhasAvaliadas ||
          relatorio.QtdeNovilhasGestantes ||
          relatorio.QtdeNovilhasReavaliar ||
          relatorio.QtdeNovilhasVazias ||
          relatorio.ListaNovilhasGestantes ||
          relatorio.ListaNovilhasReavaliar ||
          relatorio.ListaNovilhasVaziasIATF ||
          relatorio.ListaNovilhasVaziasPGF ||
          relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal ||
          relatorio.ListaNovilhasVaziasTratamentoClinico ||
          relatorio.separarTouroNovilhas ||
          relatorio.OutrasRecomendacoesReprodutivoNovilhas ||
          relatorio.ReprodutivoNovilhasObs ||
          relatorio.ReprodutivoVacasAtividade1 ||
          relatorio.ReprodutivoVacasAtividade2 ||
          relatorio.ReprodutivoVacasAtividade3 ||
          relatorio.ReprodutivoVacasAtividade4 ||
          relatorio.ReprodutivoVacasAtividade5 ||
          relatorio.ReprodutivoVacasOutrasAtividades ||
          relatorio.ReprodutivoVacasOutrasAtividadesText ||
          relatorio.ReprodutivoNovilhasAtividade1 ||
          relatorio.ReprodutivoNovilhasAtividade2 ||
          relatorio.ReprodutivoNovilhasAtividade3 ||
          relatorio.ReprodutivoNovilhasAtividade4 ||
          relatorio.ReprodutivoNovilhasAtividade5 ||
          relatorio.ReprodutivoNovilhasOutrasAtividades ||
          relatorio.ReprodutivoNovilhasOutrasAtividadesText
        "
        class="pa-3"
      >
        <v-col cols="12">
          <span class="key-title">Reprodutivo de Vacas </span>
        </v-col>
        <v-col
          v-if="relatorio.QtdeVacasAvaliadas"
          cols="12"
          md="6"
          class="col-bordered"
        >
          <span class="key-title">Número de vacas avaliadas: </span> <br />
          <span class="value-title">{{
            relatorio.QtdeVacasAvaliadas || '-'
          }}</span>
        </v-col>
        <v-col
          v-if="relatorio.QtdeVacasGestantes"
          cols="12"
          md="6"
          class="col-bordered"
        >
          <span class="key-title">Número de vacas gestantes: </span> <br />
          <span class="value-title">{{
            relatorio.QtdeVacasGestantes || '-'
          }}</span>
        </v-col>
        <v-col
          v-if="relatorio.QtdeVacasReavaliar"
          cols="12"
          md="6"
          class="col-bordered"
        >
          <span class="key-title">Número de vacas a reavaliar: </span> <br />
          <span class="value-title">{{
            relatorio.QtdeVacasReavaliar || '-'
          }}</span>
        </v-col>
        <v-col
          v-if="relatorio.QtdeVacasVazias"
          cols="12"
          md="6"
          class="col-bordered"
        >
          <span class="key-title">Número de vacas vazias: </span> <br />
          <span class="value-title">{{
            relatorio.QtdeVacasVazias || '-'
          }}</span>
        </v-col>
        <v-col
          v-if="
            relatorio.ListaVacasGestantes != null &&
            relatorio.ListaVacasGestantes.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Identificação das vacas gestantes </span>
          <br />
          <v-chip
            v-for="(animal, index) in relatorio.ListaVacasGestantes"
            :key="index"
            class="ma-1"
          >
            {{ animal.nomeOuBrincoGestante }}
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ListaVacasReavaliar != null &&
            relatorio.ListaVacasReavaliar.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Identificação das vacas para reavaliar </span>
          <br />
          <v-chip
            v-for="(animal, index) in relatorio.ListaVacasReavaliar"
            :key="index"
            class="ma-1"
          >
            {{ animal.nomeOuBrincoReavaliar }}
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ListaVacasVaziasIATF != null &&
            relatorio.ListaVacasVaziasIATF.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Identificação das vacas vazias que entraram no protocolo de IATF
          </span>
          <br />
          <v-chip
            v-for="(animal, index) in relatorio.ListaVacasVaziasIATF"
            :key="index"
            class="ma-1"
          >
            {{ animal.nomeOuBrincoVaziaIATF }}
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ListaVacasVaziasPGF != null &&
            relatorio.ListaVacasVaziasPGF.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Identificação das vacas vazias que foram sincronizadas no PGF
          </span>
          <br />
          <v-chip
            v-for="(animal, index) in relatorio.ListaVacasVaziasPGF"
            :key="index"
            class="ma-1"
          >
            {{ animal.nomeOuBrincoVaziaPGF }}
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ListaVacasVaziasRecuperarCondicaoCorporal != null &&
            relatorio.ListaVacasVaziasRecuperarCondicaoCorporal.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Identificação das vacas vazias que precisam recuperar o escore de
            condição corporal
          </span>
          <br />
          <v-chip
            v-for="(
              animal, index
            ) in relatorio.ListaVacasVaziasRecuperarCondicaoCorporal"
            :key="index"
            class="ma-1"
          >
            {{ animal.nomeOuBrincoVaziaRecuperarCondicaoCorporal }}
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ListaVacasVaziasTratamentoClinico != null &&
            relatorio.ListaVacasVaziasTratamentoClinico.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Identificação das vacas vazias que precisam de tratamento clínico
          </span>
          <br />
          <v-chip
            v-for="(
              animal, index
            ) in relatorio.ListaVacasVaziasTratamentoClinico"
            :key="index"
            class="ma-1"
          >
            {{ animal.nomeOuBrincoVaziaTratamentoClinico }}
          </v-chip>
        </v-col>

        <v-col
          v-if="
            relatorio.ReprodutivoVacasAtividade1 ||
            relatorio.ReprodutivoVacasAtividade2 ||
            relatorio.ReprodutivoVacasAtividade3 ||
            relatorio.ReprodutivoVacasAtividade4 ||
            relatorio.ReprodutivoVacasAtividade5 ||
            relatorio.ReprodutivoVacasOutrasAtividades
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Atividades realizadas</span>
          <v-col v-if="relatorio.ReprodutivoVacasAtividade1" cols="12">
            <span class="value-title">Diagnóstico de gestação das vacas</span>
          </v-col>
          <v-col v-if="relatorio.ReprodutivoVacasAtividade2" cols="12">
            <span class="value-title"
              >Treinamento para aumentar a detecção de cio
            </span>
          </v-col>
          <v-col v-if="relatorio.ReprodutivoVacasAtividade3" cols="12">
            <span class="value-title"
              >Treinamento para realizar inseminação artificial</span
            >
          </v-col>
          <v-col v-if="relatorio.ReprodutivoVacasAtividade4" cols="12">
            <span class="value-title">Treinamento para realizar IATF</span>
          </v-col>
          <v-col v-if="relatorio.ReprodutivoVacasAtividade5" cols="12">
            <span class="value-title"
              >Discussão dos indicadores reprodutivos</span
            >
          </v-col>
          <v-col v-if="relatorio.ReprodutivoVacasOutrasAtividades" cols="12">
            <span class="key-title">Outras Atividades: </span> <br />
            <span class="value-title">{{
              relatorio.ReprodutivoVacasOutrasAtividadesText || '-'
            }}</span>
          </v-col>
        </v-col>

        <v-col
          v-if="
            relatorio.SepararTourosVacas ||
            relatorio.OutrasRecomendacoesReprodutivoVacas != ''
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Recomendações</span>
          <v-col v-if="relatorio.SepararTourosVacas != ''" cols="12">
            <span class="value-title">Separar o touro das vacas </span>
          </v-col>
          <v-col
            v-if="relatorio.OutrasRecomendacoesReprodutivoVacas != ''"
            cols="12"
          >
            <span class="key-title">Outras recomendações: </span> <br />
            <span class="value-title">{{
              relatorio.OutrasRecomendacoesReprodutivoVacas || '-'
            }}</span>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.ReprodutivoVacasObs != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Motivo de não realização dessa atividade:
          </span>
          <br />
          <span class="value-title">{{
            relatorio.ReprodutivoVacasObs || '-'
          }}</span>
        </v-col>
        <v-col
          v-if="
            relatorio.QtdeNovilhasAvaliadas != '' ||
            relatorio.QtdeNovilhasGestantes != '' ||
            relatorio.QtdeNovilhasReavaliar != '' ||
            relatorio.QtdeNovilhasVazias != '' ||
            (relatorio.ListaNovilhasGestantes != null &&
              relatorio.ListaNovilhasGestantes.length != 0) ||
            (relatorio.ListaNovilhasReavaliar != null &&
              relatorio.ListaNovilhasReavaliar.length != 0) ||
            (relatorio.ListaNovilhasVaziasIATF != null &&
              relatorio.ListaNovilhasVaziasIATF.length != 0) ||
            (relatorio.ListaNovilhasVaziasPGF != null &&
              relatorio.ListaNovilhasVaziasPGF.length != 0) ||
            (relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal != null &&
              relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal.length !=
                0) ||
            (relatorio.ListaNovilhasVaziasTratamentoClinico != null &&
              relatorio.ListaNovilhasVaziasTratamentoClinico.length != 0) ||
            relatorio.separarTouroNovilhas != '' ||
            relatorio.OutrasRecomendacoesReprodutivoNovilhas != '' ||
            relatorio.ReprodutivoNovilhasObs != '' ||
            relatorio.ReprodutivoNovilhasAtividade1 ||
            relatorio.ReprodutivoNovilhasAtividade2 ||
            relatorio.ReprodutivoNovilhasAtividade3 ||
            relatorio.ReprodutivoNovilhasAtividade4 ||
            relatorio.ReprodutivoNovilhasAtividade5 ||
            relatorio.ReprodutivoNovilhasOutrasAtividades
          "
          cols="12"
          class="mt-4"
        >
          <span class="key-title">Reprodutivo de novilhas </span>
        </v-col>
        <v-col
          v-if="relatorio.QtdeNovilhasAvaliadas"
          cols="12"
          md="6"
          class="col-bordered"
        >
          <span class="key-title">Novilhas avaliadas: </span> <br />
          <span class="value-title">{{
            relatorio.QtdeNovilhasAvaliadas || '-'
          }}</span>
        </v-col>
        <v-col
          v-if="relatorio.QtdeNovilhasGestantes"
          cols="12"
          md="6"
          class="col-bordered"
        >
          <span class="key-title">Número de novilhas gestantes: </span> <br />
          <span class="value-title">{{
            relatorio.QtdeNovilhasGestantes || '-'
          }}</span>
        </v-col>
        <v-col
          v-if="relatorio.QtdeNovilhasReavaliar"
          cols="12"
          md="6"
          class="col-bordered"
        >
          <span class="key-title">Número de novilhas a reavaliar: </span>
          <br />
          <span class="value-title">{{
            relatorio.QtdeNovilhasReavaliar || '-'
          }}</span>
        </v-col>
        <v-col
          v-if="relatorio.QtdeNovilhasVazias"
          cols="12"
          md="6"
          class="col-bordered"
        >
          <span class="key-title">Número de novilhas vazias: </span> <br />
          <span class="value-title">{{
            relatorio.QtdeNovilhasVazias || '-'
          }}</span>
        </v-col>
        <v-col
          v-if="
            relatorio.ListaNovilhasGestantes != null &&
            relatorio.ListaNovilhasGestantes.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Identificação das novilhas gestantes </span>
          <br />
          <v-chip
            v-for="(animal, index) in relatorio.ListaNovilhasGestantes"
            :key="index"
            class="ma-1"
          >
            {{ animal.nomeOuBrincoNovilhasGestante }}
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ListaNovilhasReavaliar != null &&
            relatorio.ListaNovilhasReavaliar.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Identificação das novilhas para reavaliar
          </span>
          <br />
          <v-chip
            v-for="(animal, index) in relatorio.ListaNovilhasReavaliar"
            :key="index"
            class="ma-1"
          >
            {{ animal.nomeOuBrincoNovilhaReavaliar }}
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ListaNovilhasVaziasIATF != null &&
            relatorio.ListaNovilhasVaziasIATF.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Identificação das novilhas vazias que entraram no protocolo de IATF
          </span>
          <br />
          <v-chip
            v-for="(animal, index) in relatorio.ListaNovilhasVaziasIATF"
            :key="index"
            class="ma-1"
          >
            {{ animal.nomeOuBrincoNovilhaVaziaIATF }}
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ListaNovilhasVaziasPGF != null &&
            relatorio.ListaNovilhasVaziasPGF.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Identificação das novilhas vazias que foram sincronizadas no PGF
          </span>
          <br />
          <v-chip
            v-for="(animal, index) in relatorio.ListaNovilhasVaziasPGF"
            :key="index"
            class="ma-1"
          >
            {{ animal.nomeOuBrincoNovilhasVaziaPGF }}
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal != null &&
            relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Identificação das novilhas vazias que precisam recuperar o escore
            de condição corporal
          </span>
          <br />
          <v-chip
            v-for="(
              animal, index
            ) in relatorio.ListaNovilhasVaziasRecuperarCondicaoCorporal"
            :key="index"
            class="ma-1"
          >
            {{ animal.nomeOuBrincoNovilhaVaziaRecuperarCondicaoCorporal }}
          </v-chip>
        </v-col>
        <v-col
          v-if="
            relatorio.ListaNovilhasVaziasTratamentoClinico != null &&
            relatorio.ListaNovilhasVaziasTratamentoClinico.length
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Identificação das novilhas vazias que precisam de tratamento
            clínico
          </span>
          <br />
          <v-chip
            v-for="(
              animal, index
            ) in relatorio.ListaNovilhasVaziasTratamentoClinico"
            :key="index"
            class="ma-1"
          >
            {{ animal.nomeOuBrincoNovilhaVaziaTratamentoClinico }}
          </v-chip>
        </v-col>

        <v-col
          v-if="
            relatorio.ReprodutivoNovilhasAtividade1 ||
            relatorio.ReprodutivoNovilhasAtividade2 ||
            relatorio.ReprodutivoNovilhasAtividade3 ||
            relatorio.ReprodutivoNovilhasAtividade4 ||
            relatorio.ReprodutivoNovilhasAtividade5 ||
            relatorio.ReprodutivoNovilhasOutrasAtividades
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Atividades realizadas</span>
          <v-col v-if="relatorio.ReprodutivoNovilhasAtividade1" cols="12">
            <span class="value-title"
              >Diagnóstico de gestação das novilhas</span
            >
          </v-col>
          <v-col v-if="relatorio.ReprodutivoNovilhasAtividade2" cols="12">
            <span class="value-title"
              >Treinamento para aumentar a detecção de cio
            </span>
          </v-col>
          <v-col v-if="relatorio.ReprodutivoNovilhasAtividade3" cols="12">
            <span class="value-title"
              >Treinamento para realizar inseminação artificial</span
            >
          </v-col>
          <v-col v-if="relatorio.ReprodutivoNovilhasAtividade4" cols="12">
            <span class="value-title">Treinamento para realizar IATF</span>
          </v-col>
          <v-col v-if="relatorio.ReprodutivoNovilhasAtividade5" cols="12">
            <span class="value-title"
              >Discussão dos indicadores reprodutivos</span
            >
          </v-col>
          <v-col v-if="relatorio.ReprodutivoNovilhasOutrasAtividades" cols="12">
            <span class="key-title">Outras Atividades: </span> <br />
            <span class="value-title">{{
              relatorio.ReprodutivoNovilhasOutrasAtividadesText || '-'
            }}</span>
          </v-col>
        </v-col>

        <v-col
          v-if="
            relatorio.separarTouroNovilhas ||
            relatorio.OutrasRecomendacoesReprodutivoNovilhas
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Recomendações</span>
          <v-col v-if="relatorio.separarTouroNovilhas != ''" cols="12">
            <span class="value-title">Separar o touro das novilhas </span>
          </v-col>
          <v-col
            v-if="relatorio.OutrasRecomendacoesReprodutivoNovilhas"
            cols="12"
          >
            <span class="key-title">Outras recomendações: </span> <br />
            <span class="value-title">{{
              relatorio.OutrasRecomendacoesReprodutivoNovilhas || '-'
            }}</span>
          </v-col>
        </v-col>
        <v-col
          v-if="relatorio.ReprodutivoNovilhasObs"
          cols="12"
          class="col-bordered"
        >
          <span class="key-title"
            >Motivo de não realização dessa atividade:
          </span>
          <br />
          <span class="value-title">{{
            relatorio.ReprodutivoNovilhasObs || '-'
          }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ManejoReprodutivo',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    maskIdentify(raw) {
      return raw ? raw.split('|') : ['Nenhum animal identificado']
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
  line-height: 40px;
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
