var render = function render(){var _vm=this,_c=_vm._self._c;return (
    (_vm.relatorio.Sanitario &&
      ((_vm.relatorio.Vacinas != null && _vm.relatorio.Vacinas.length != 0) ||
        (_vm.relatorio.Pediluvio != null && _vm.relatorio.Pediluvio.length != 0) ||
        (_vm.relatorio.Vermifucacao != null &&
          _vm.relatorio.Vermifucacao.length != 0) ||
        (_vm.relatorio.ControleCarrapatos != null &&
          _vm.relatorio.ControleCarrapatos.length != 0) ||
        _vm.relatorio.OutrasRecomendacoesManejoSanitario != '' ||
        _vm.relatorio.ManejoSanitarioAtividade1 ||
        _vm.relatorio.ManejoSanitarioAtividade2 ||
        _vm.relatorio.ManejoSanitarioAtividade3 ||
        _vm.relatorio.ManejoSanitarioAtividade4 ||
        _vm.relatorio.ManejoSanitarioOutrasAtividades ||
        _vm.relatorio.ManejoSanitarioOutrasAtividadesText)) ||
    _vm.relatorio.SanitarioObs
  )?_c('v-card',{staticClass:"ma-4"},[_c('v-card-title',{staticStyle:{"text-align":"left"}},[_vm._v("Manejo sanitário")]),_c('v-card-text',[(_vm.relatorio.Sanitario)?_c('v-row',{staticClass:"pa-3"},[(_vm.relatorio.Vacinas != null && _vm.relatorio.Vacinas.length != 0)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Vacinas ")]),_vm._v(" "),_c('br'),_vm._l((_vm.relatorio.Vacinas),function(vacina,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(_vm.relatorio.Vacinas)?_c('span',[_c('strong',[_vm._v("Nome: ")]),_vm._v(_vm._s(vacina.msMomeVacina + ' ')),_c('br'),_c('strong',[_vm._v("Dosagem: ")]),_vm._v(_vm._s(vacina.msDosagemVacina + ' ')),_c('br'),_c('strong',[_vm._v("Categoria: ")]),_vm._v(_vm._s(vacina.msCategoriaVacina + ' '))]):_c('span',[_vm._v(" "+_vm._s(vacina)+" ")])])})],2):_vm._e(),(_vm.relatorio.Pediluvio != null && _vm.relatorio.Pediluvio.length != 0)?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Manter utilização do pedilúvio ")]),_vm._v(" "),_c('br'),_vm._l((_vm.relatorio.Pediluvio),function(pediluvio,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(_vm.relatorio.Pediluvio)?_c('span',[_c('strong',[_vm._v("Nome do produto: ")]),_vm._v(_vm._s(pediluvio.msNomeProdutoPediluvio + ' ')),_c('br'),_c('strong',[_vm._v("Frequência: ")]),_vm._v(_vm._s(pediluvio.msFrequenciaPediluvio + ' ')),_c('br'),_c('strong',[_vm._v("Concentração: ")]),_vm._v(_vm._s(pediluvio.msConcentracaoPediluvio + ' '))]):_c('span',[_vm._v(" "+_vm._s(pediluvio)+" ")])])})],2):_vm._e(),(
          _vm.relatorio.Vermifucacao != null && _vm.relatorio.Vermifucacao.length != 0
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Vermifugação ")]),_vm._v(" "),_c('br'),_vm._l((_vm.relatorio.Vermifucacao),function(vermifugo,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(_vm.relatorio.Vermifucacao)?_c('span',[_c('strong',[_vm._v("Nome do produto: ")]),_vm._v(_vm._s(vermifugo.msNomeProdutoVermifugacao + ' ')),_c('br'),_c('strong',[_vm._v("Dosagem: ")]),_vm._v(_vm._s(vermifugo.msDosagemVermifugacao + ' ')),_c('br'),_c('strong',[_vm._v("Via de aplicação: ")]),_vm._v(_vm._s(vermifugo.msViaAplicacaoVermifugacao + ' '))]):_c('span',[_vm._v(" "+_vm._s(vermifugo)+" ")])])})],2):_vm._e(),(
          _vm.relatorio.ControleCarrapatos != null &&
          _vm.relatorio.ControleCarrapatos.length != 0
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Carrapaticidas ")]),_vm._v(" "),_c('br'),_vm._l((_vm.relatorio.ControleCarrapatos),function(carrapaticida,index){return _c('v-chip',{key:index,staticClass:"ma-1"},[(_vm.relatorio.ControleCarrapatos)?_c('span',[_c('strong',[_vm._v("Nome do produto: ")]),_vm._v(_vm._s(carrapaticida.msNomeProdutoCarrapaticida + ' ')),_c('br'),_c('strong',[_vm._v("Dosagem: ")]),_vm._v(_vm._s(carrapaticida.msDosagemCarrapaticida + ' ')),_c('br'),_c('strong',[_vm._v("Via de aplicação: ")]),_vm._v(_vm._s(carrapaticida.msViaAplicacaoCarrapaticida + ' '))]):_c('span',[_vm._v(" "+_vm._s(carrapaticida)+" ")])])})],2):_vm._e(),(
          _vm.relatorio.ManejoSanitarioAtividade1 ||
          _vm.relatorio.ManejoSanitarioAtividade2 ||
          _vm.relatorio.ManejoSanitarioAtividade3 ||
          _vm.relatorio.ManejoSanitarioAtividade4 ||
          _vm.relatorio.ManejoSanitarioOutrasAtividades
        )?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Atividades realizadas")]),(
            _vm.relatorio.ManejoSanitarioAtividade1 == '1' &&
            _vm.relatorio.ManejoSanitarioAtividade1Text != ''
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Implantação do calendário sanitário: ")]),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.ManejoSanitarioAtividade1Text || '-'))])]):_vm._e(),(
            _vm.relatorio.ManejoSanitarioAtividade2 == '1' &&
            _vm.relatorio.ManejoSanitarioAtividade2Text != ''
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Acompanhamento do calendário sanitário: ")]),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.ManejoSanitarioAtividade2Text || '-'))])]):_vm._e(),(
            _vm.relatorio.ManejoSanitarioAtividade3 == '1' &&
            _vm.relatorio.ManejoSanitarioAtividade3Text != ''
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Realização da vacinação:")]),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.ManejoSanitarioAtividade3Text || '-'))])]):_vm._e(),(
            _vm.relatorio.ManejoSanitarioAtividade4 == '1' &&
            _vm.relatorio.ManejoSanitarioAtividade4Text != ''
          )?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"value-title"},[_vm._v("Planos de ação para controle de doenças: ")]),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.ManejoSanitarioAtividade4Text || '-'))])]):_vm._e(),(_vm.relatorio.ManejoSanitarioOutrasAtividades)?_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Outras Atividades: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.ManejoSanitarioOutrasAtividadesText || '-'))])]):_vm._e()],1):_vm._e(),(_vm.relatorio.OutrasRecomendacoesManejoSanitario != '')?_c('v-col',{staticClass:"col-bordered",attrs:{"cols":"12"}},[_c('span',{staticClass:"key-title"},[_vm._v("Outras recomendações: ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.OutrasRecomendacoesManejoSanitario || '-'))])]):_vm._e()],1):_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('span',{staticClass:"key-title"},[_vm._v("Atividade não realizada ")]),_vm._v(" "),_c('br'),_c('span',{staticClass:"value-title"},[_vm._v(_vm._s(_vm.relatorio.SanitarioObs || '-'))])])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }