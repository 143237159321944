/* eslint-disable vue/valid-template-root */
<template>
  <v-card
    v-if="
      relatorio.RealizarLinhaOrdenha ||
      relatorio.TesteCaneca ||
      relatorio.PreDippingOrdenha ||
      (relatorio.PapelToalha && relatorio.PapelToalha == 1) ||
      relatorio.PosDipping ||
      relatorio.ComprarTermometro ||
      relatorio.ProcedimentoOrdenha ||
      relatorio.ColetaLeiteCCS ||
      relatorio.ColetaLeiteMicrobiologica ||
      relatorio.UtilizarAntibioticoSecas ||
      relatorio.UtilizarSelanteIntramamario ||
      relatorio.ManterAreaDescanso ||
      relatorio.CercarAreaDescanso ||
      relatorio.AcompanhamentoOrdenhaAtividade1 ||
      relatorio.AcompanhamentoOrdenhaAtividade2 ||
      relatorio.AcompanhamentoOrdenhaAtividade3 ||
      relatorio.AcompanhamentoOrdenhaAtividade4 ||
      relatorio.AcompanhamentoOrdenhaAtividade5 ||
      relatorio.AcompanhamentoOrdenhaOutrasAtividades
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left"
      >Recomendações - Rotina de ordenha</v-card-title
    >
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="
            relatorio.AcompanhamentoOrdenhaAtividade1 ||
            relatorio.AcompanhamentoOrdenhaAtividade2 ||
            relatorio.AcompanhamentoOrdenhaAtividade3 ||
            relatorio.AcompanhamentoOrdenhaAtividade4 ||
            relatorio.AcompanhamentoOrdenhaAtividade5 ||
            relatorio.AcompanhamentoOrdenhaOutrasAtividades
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Atividades realizadas</span>
          <v-col
            v-if="
              relatorio.AcompanhamentoOrdenhaAtividade1 == '1' &&
              relatorio.AcompanhamentoOrdenhaAtividade1Text != ''
            "
            cols="12"
          >
            <span class="value-title"
              >Vistoria das áreas de descanso dos animais:
            </span>
            <br />
            <span class="value-title">{{
              relatorio.AcompanhamentoOrdenhaAtividade1Text || '-'
            }}</span>
          </v-col>

          <v-col
            v-if="
              relatorio.AcompanhamentoOrdenhaAtividade2 == '1' &&
              relatorio.AcompanhamentoOrdenhaAtividade2Text != ''
            "
            cols="12"
          >
            <span class="value-title"
              >Acompanhamento dos procedimentos de ordenha:
            </span>
            <br />
            <span class="value-title">{{
              relatorio.AcompanhamentoOrdenhaAtividade2Text || '-'
            }}</span>
          </v-col>

          <v-col
            v-if="
              relatorio.AcompanhamentoOrdenhaAtividade3 == '1' &&
              relatorio.AcompanhamentoOrdenhaAtividade3Text != ''
            "
            cols="12"
          >
            <span class="value-title">Realização do teste de CMT: </span>
            <br />
            <span class="value-title">{{
              relatorio.AcompanhamentoOrdenhaAtividade3Text || '-'
            }}</span>
          </v-col>
          <v-col
            v-if="
              relatorio.AcompanhamentoOrdenhaAtividade4 == '1' &&
              relatorio.AcompanhamentoOrdenhaAtividade4Text != ''
            "
            cols="12"
          >
            <span class="value-title"
              >Acompanhamento da limpeza dos equipamentos:
            </span>
            <br />
            <span class="value-title">{{
              relatorio.AcompanhamentoOrdenhaAtividade4Text || '-'
            }}</span>
          </v-col>
          <v-col
            v-if="
              relatorio.AcompanhamentoOrdenhaAtividade5 == '1' &&
              relatorio.AcompanhamentoOrdenhaAtividade5Text != ''
            "
            cols="12"
          >
            <span class="value-title">Aferição da temperatura do tanque: </span>
            <br />
            <span class="value-title">{{
              relatorio.AcompanhamentoOrdenhaAtividade5Text || '-'
            }}</span>
          </v-col>
          <v-col
            v-if="relatorio.AcompanhamentoOrdenhaOutrasAtividades"
            cols="12"
          >
            <span class="key-title">Outras Atividades: </span> <br />
            <span class="value-title">{{
              relatorio.AcompanhamentoOrdenhaOutrasAtividadesText || '-'
            }}</span>
          </v-col>
        </v-col>

        <v-col
          v-if="
            relatorio.RealizarLinhaOrdenha ||
            relatorio.TesteCaneca ||
            relatorio.PreDippingOrdenha ||
            (relatorio.PapelToalha && relatorio.PapelToalha == 1) ||
            relatorio.PosDipping ||
            relatorio.ComprarTermometro ||
            relatorio.ProcedimentoOrdenha ||
            relatorio.ColetaLeiteCCS ||
            relatorio.ColetaLeiteMicrobiologica ||
            relatorio.UtilizarAntibioticoSecas ||
            relatorio.UtilizarSelanteIntramamario ||
            relatorio.ManterAreaDescanso ||
            relatorio.CercarAreaDescanso ||
            relatorio.RecomendacaoOrdenha
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Recomendações</span>
          <v-col v-if="relatorio.RealizarLinhaOrdenha" cols="12">
            <span class="value-title"
              >Realizar linha de ordenha proposta em anexo
            </span>
          </v-col>
          <v-col v-if="relatorio.TesteCaneca" cols="12">
            <span class="value-title"
              >Fazer o teste da caneca em todas as ordenhas
            </span>
          </v-col>
          <v-col
            v-if="
              relatorio.PreDippingOrdenha && relatorio.PreDippingOrdenha == 1
            "
            cols="12"
          >
            <span class="value-title"
              >Realizar a aplicação do pré dipping em todas as vacas
            </span>
          </v-col>
          <v-col
            v-if="relatorio.PapelToalha && relatorio.PapelToalha == 1"
            cols="12"
          >
            <span class="value-title"
              >Utilizar papel toalha para secagem dos tetos
            </span>
          </v-col>
          <v-col v-if="relatorio.PosDipping" cols="12">
            <span class="value-title"
              >Realizar a aplicação do pós dipping em todas as vacas
            </span>
          </v-col>
          <v-col v-if="relatorio.ComprarTermometro" cols="12">
            <span class="value-title"
              >Comprar termômetro para aferição da temperatura da água (70°C)
            </span>
          </v-col>
          <v-col v-if="relatorio.ProcedimentoOrdenha" cols="12">
            <span class="value-title"
              >Seguir procedimentos de ordenha conforme procedimento operacional
              padrão em anexo
            </span>
          </v-col>
          <v-col v-if="relatorio.ColetaLeiteCCS" cols="12">
            <span class="value-title"
              >Realizar coleta de leite para análise individual da CCS
            </span>
          </v-col>
          <v-col v-if="relatorio.ColetaLeiteMicrobiologica" cols="12">
            <span class="value-title"
              >Realizar coleta de leite para análise microbiológica do leite
            </span>
          </v-col>
          <v-col v-if="relatorio.UtilizarAntibioticoSecas" cols="12">
            <span class="value-title"
              >Utilizar antibiótico vaca seca para secagem das vacas (indicação
              do produto)
            </span>
          </v-col>
          <v-col v-if="relatorio.UtilizarSelanteIntramamario" cols="12">
            <span class="value-title"
              >Utilizar selante intramamário para secagem das vacas (indicação
              do produto)
            </span>
          </v-col>
          <v-col v-if="relatorio.ManterAreaDescanso" cols="12">
            <span class="value-title"
              >Manter limpas as áreas de descanso das vacas
            </span>
          </v-col>
          <v-col v-if="relatorio.CercarAreaDescanso" cols="12">
            <span class="value-title"
              >Cercar as áreas de descanso com acúmulo de barro
            </span>
          </v-col>
          <v-col v-if="relatorio.RecomendacaoOrdenha" cols="12">
            <span class="key-title">Outras recomendações: </span> <br />
            <span class="value-title">
              {{ relatorio.RecomendacaoOrdenha }}
            </span>
          </v-col>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'RecomendacaoOrdenhaCCS',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {},
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
  line-height: 40px;
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
