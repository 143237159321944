<template>
  <v-card
    v-if="
      (relatorio.AjustesNutricionais &&
        (relatorio.CotacaoInsumoNutricional ||
          relatorio.PesagemLeiteApp ||
          relatorio.PesagemLeiteCaderno ||
          relatorio.RemanejamentoLotes ||
          relatorio.SeguirArracoamento ||
          relatorio.RecomendacaoNutricional ||
          relatorio.NutricionaisAtividade1 ||
          relatorio.NutricionaisAtividade2 ||
          relatorio.NutricionaisAtividade3 ||
          relatorio.NutricionaisAtividade4 ||
          relatorio.NutricionaisAtividade5 ||
          relatorio.NutricionaisAtividade6 ||
          relatorio.NutricionaisAtividade7 ||
          relatorio.NutricionaisOutrasAtividades)) ||
      relatorio.AjustesNutricionaisObs
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left">Ajustes nutricionais</v-card-title>
    <v-card-text>
      <v-row v-if="relatorio.AjustesNutricionais" class="pa-3">
        <v-col
          v-if="
            relatorio.NutricionaisAtividade1 ||
            relatorio.NutricionaisAtividade2 ||
            relatorio.NutricionaisAtividade3 ||
            relatorio.NutricionaisAtividade4 ||
            relatorio.NutricionaisAtividade5 ||
            relatorio.NutricionaisAtividade6 ||
            relatorio.NutricionaisAtividade7 ||
            relatorio.NutricionaisOutrasAtividades
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Atividades realizadas</span>
          <v-col v-if="relatorio.NutricionaisAtividade1" cols="12">
            <span class="value-title"
              >Lançamento do controle leiteiro no sistema</span
            >
          </v-col>
          <v-col
            v-if="
              relatorio.NutricionaisAtividade2 == '1' &&
              relatorio.NutricionaisAtividade2Text != ''
            "
            cols="12"
          >
            <span class="value-title">Avaliação do escore de cocho: </span>
            <br />
            <span class="value-title">{{
              relatorio.NutricionaisAtividade2Text || '-'
            }}</span>
          </v-col>
          <v-col
            v-if="
              relatorio.NutricionaisAtividade3 == '1' &&
              relatorio.NutricionaisAtividade3Text != ''
            "
            cols="12"
          >
            <span class="value-title"
              >Avaliação da estrutura de alimentação (espaço de cocho/animal,
              condições físicas das estruturas):
            </span>
            <br />
            <span class="value-title">{{
              relatorio.NutricionaisAtividade3Text || '-'
            }}</span>
          </v-col>
          <v-col
            v-if="
              relatorio.NutricionaisAtividade4 == '1' &&
              relatorio.NutricionaisAtividade4Text != ''
            "
            cols="12"
          >
            <span class="value-title">Análise da dieta atual: </span>
            <br />
            <span class="value-title">{{
              relatorio.NutricionaisAtividade4Text || '-'
            }}</span>
          </v-col>
          <v-col
            v-if="
              relatorio.NutricionaisAtividade5 == '1' &&
              relatorio.NutricionaisAtividade5Text != ''
            "
            cols="12"
          >
            <span class="value-title"
              >Divisão de lotes (Vacas em lactação):
            </span>
            <br />
            <span class="value-title">{{
              relatorio.NutricionaisAtividade5Text || '-'
            }}</span>
          </v-col>
          <v-col
            v-if="
              relatorio.NutricionaisAtividade6 == '1' &&
              relatorio.NutricionaisAtividade6Text != ''
            "
            cols="12"
          >
            <span class="value-title">Divisão de lotes (Recria): </span>
            <br />
            <span class="value-title">{{
              relatorio.NutricionaisAtividade6Text || '-'
            }}</span>
          </v-col>
          <v-col
            v-if="
              relatorio.NutricionaisAtividade7 == '1' &&
              relatorio.NutricionaisAtividade7Text != ''
            "
            cols="12"
          >
            <span class="value-title">Balanceamento da dieta: </span>
            <br />
            <span class="value-title">{{
              relatorio.NutricionaisAtividade7Text || '-'
            }}</span>
          </v-col>
          <v-col v-if="relatorio.NutricionaisOutrasAtividades" cols="12">
            <span class="key-title">Outras Atividades: </span> <br />
            <span class="value-title">{{
              relatorio.NutricionaisOutrasAtividadesText || '-'
            }}</span>
          </v-col>
        </v-col>

        <v-col
          v-if="
            relatorio.CotacaoInsumoNutricional ||
            relatorio.PesagemLeiteApp ||
            relatorio.PesagemLeiteCaderno ||
            relatorio.RemanejamentoLotes ||
            relatorio.SeguirArracoamento ||
            relatorio.RecomendacaoNutricional
          "
          cols="12"
          class="col-bordered"
        >
          <span class="key-title">Recomendações</span>
          <v-col v-if="relatorio.CotacaoInsumoNutricional" cols="12">
            <span class="value-title"
              >Fazer cotação de insumos para custo da dieta
            </span>
            <br />
          </v-col>
          <v-col v-if="relatorio.PesagemLeiteApp" cols="12">
            <span class="value-title"
              >Realizar a pesagem mensal de leite e lançar a pesagem no
              aplicativo
            </span>
          </v-col>
          <v-col v-if="relatorio.PesagemLeiteCaderno" cols="12">
            <span class="value-title"
              >Realizar a pesagem mensal de leite e lançar a pesagem no caderno
              zootécnico
            </span>
          </v-col>
          <v-col v-if="relatorio.RemanejamentoLotes" cols="12">
            <span class="value-title"
              >Realizar o remanejamento dos lotes conforme listagem em anexo
            </span>
          </v-col>
          <v-col v-if="relatorio.SeguirArracoamento" cols="12">
            <span class="value-title"
              >Seguir arraçoamento proposto em anexo
            </span>
          </v-col>
          <v-col v-if="relatorio.RecomendacaoNutricional" cols="12">
            <span class="key-title">Outras recomendações: </span> <br />
            <span class="value-title">{{
              relatorio.RecomendacaoNutricional || '-'
            }}</span>
          </v-col>
        </v-col>
      </v-row>
      <v-row v-else justify="center">
        <v-col>
          <span class="key-title">Atividade não realizada </span> <br />
          <span class="value-title">{{
            relatorio.AjustesNutricionaisObs || '-'
          }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'AjustesNutricionais',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    maskIdentify(raw) {
      return raw ? raw.split('|') : ['Nenhum insumo identificado']
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
  line-height: 40px;
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
